/* eslint-disable @typescript-eslint/indent */
export const de = {
         login_sign_in: 'Einloggen',
         login_email_address: 'E-Mail-Adresse',
         login_form_password_label: 'Passwort',
         login_forget_password: 'Passwort vergessen',
         login_credentials_error:
           'Kontrollieren Sie Ihr Passwort und Ihre E-Mail-Adresse',
         login_api_error: 'Interner Anmelde-API-Fehler',
         internal_server_error: 'Interner Serverfehler',
         /* Forget Password */
         forget_password_title: 'Passwort vergessen',
         forget_password_email_address: 'E-Mail-Adresse',
         forget_password_button:
           'Senden Sie einen Link, um Ihr Passwort zurückzusetzen.',
         forget_password_login: 'Zurück',
         forgot_password_form_success:
           'Die E-Mail zur Wiederherstellung des Passworts wird weitergeleitet. Prüfen Sie Ihren Posteingang. Es ist möglich, dass die E-Mail in ihren Spamordner gelangt ist.',
         forgot_password_subject: 'Fortes Data Management – Passwort vergessen',
         temporary_password_subject:
           'Fortes Data Management – Neues Benutzerkonto erstellt',
         forgot_password_required_form_error: 'E-Mail-Adresse ist erforderlich',
         forgot_password_valid_form_error: 'Keine gültige E-Mail-Adresse',
         /* END Forget Password */
         /** Reset Password */
         reset_password_title: 'Passwort zurücksetzen',
         reset_password_new_password: 'Neues Passwort',
         reset_password_confirm_password: 'Passwort bestätigen',
         reset_password_new_password_required_form_error:
           'Sie müssen ein neues Passwort eingeben',
         reset_password_confirm_password_required_form_error:
           'Sie müssen das Passwort bestätigen',
         reset_password_passwords_not_matching:
           'Die Passwörter stimmen nicht überein',
         reset_password_new_password_form_error:
           'Das Passwort muss mindestens 8 Zeichen enthalten, davon mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen.',
         reset_password_button: 'Passwort zurücksetzen',
         reset_password_form_success:
           'Das Passwort wurde erfolgreich geändert.',
         /** End Reset Password */
         /* DASHBOARD */
         dashboard_title: 'Dashboard',
         dashboard_amount_of_devices: 'ANZAHL DER GERÄTE',
         dashboard_amount_of_devices_week:
           'ANZAHL DER GERÄTE IN DER LETZTEN WOCHE',
         dashboard_amount_of_devices_error: 'ANZAHL DER GERÄTE MIT STÖRUNGEN',
         dashboard_temperature: 'RÜCKLAUFTEMPERATUR IN GRAD CELSIUS',
         dashboard_device_status: 'Devicesübersicht',
         dashboard_routing_temperature: 'Temperaturen aller Devices',
         dashboard_routing_temperature_hr: 'Zeit',
         dashboard_error_devices:
           'Devices, wovon Steuerung sich im Fehlermodus befindet:',
         dashboard_error: 'ALARM',
         dashboard_online: 'ONLINE',
         dashboard_offline: 'OFFLINE',
         'dashboard organic': 'Organisch',
         'dashboard referal': 'Verweis',
         dashboard_see_all: 'ALLE ANZEIGEN',
         /* END DASHBOARD */
         /* Sidebar */
         date_range: 'Datum',
         sidebar_dashboard: 'Dashboard',
         sidebar_clients_overview: 'Kundenverwaltung',
         sidebar_clients: 'Kunden',
         sidebar_service_clients: 'Service Kunden',
         sidebar_devices_overview: 'Devices Verwaltung',
         sidebar_devices: 'Device Übersicht',
         sidebar_projects: 'Projekte',
         sidebar_presets: 'Voreinstellungen',
         sidebar_fortes_users: 'Administratoren',
         sidebar_fortes_settings: 'Einstellungen',
         sidebar_audit: 'Audit',
         sidebar_reports: 'Bericht',
         sidebar_total_devices: 'Devices – Insgesamt',
         sidebar_online_devices: 'Devices – Online',
         sidebar_offline_devices: 'Devices – Offline',
         sidebar_error_devices: 'Devices – Alarm',
         sidebar_change_logs: 'Änderungsprotokolle',
         sidebar_version_title: 'Version',
         image_upload_success: 'Bild wurde erfolgreich geändert!',
         image_upload_file_type_error:
           'Es sind nur JPEG- und PNG-Dateien erlaubt.',
         image_upload_file_size_error: 'Die Datei muss kleiner als 5 MB sein.',
         image_uploading_text: 'Hochladen...',
         /* END Sidebar */
         /* Navbar */
         navbar_search: 'Suchen',
         navbar_notification: 'Meldungen',
         navbar_no_notification: 'Es liegen keine Meldungen vor',
         navbar_profile: 'Benutzerkonto',
         navbar_setting: 'Kontoeinstellungen',
         navbar_logout: 'Abmelden',
         /* END Navbar */
         active_label: 'AKTIV',
         deactive_label: 'INAKTIV',
         please_fix_form_errors: 'Korrigieren Sie bitte die Formularfehler',
         /* Clients */
         clients: 'Kunden',
         clients_all: 'ALLE',
         clients_active: 'AKTIV',
         clients_inactive: 'INAKTIV',
         clients_add_new: 'Kunde hinzufügen',
         clients_filter_search: 'Kunden suchen',
         clients_sort_by_filter_label: 'Sortieren nach',
         clients_sort_by_value_1: 'Datum (aktuelles)',
         clients_sort_by_value_2: 'Datum (ältestes)',
         clients_sort_by_value_3: 'Alphabetisch',
         clients_country_filter_label: 'Ort',
         clients_filter_reset_button: 'Reset',
         clients_filter_delete_button: 'Inaktiv',
         clients_table_name: 'Name',
         clients_table_country: 'Ort',
         clients_table_devices: 'Devices',
         clients_table_added: 'Hinzugefügt am',
         clients_table_status: 'Status',
         clients_table_row_per_page: 'Ergebnisse pro Seite',
         clients_bread_crumb_main_title: 'Kunden',
         add_clients_bread_crumb_first_link: 'Kunde hinzufügen',
         add_clients_form_header: 'Kunde hinzufügen',
         edit_clients_bread_crumb_first_link: 'Kunde ändern',
         edit_clients_form_header: 'Kunde ändern',
         clients_form_client_name_field: 'Name',
         clients_form_email_field: 'E-Mail-Adresse',
         clients_form_user_field: 'Benutzer',
         clients_form_city_field: 'Stadt',
         clients_form_country_field: 'Land',
         clients_form_client_3_scale_role_field: '3Scale-Rolle',
         clients_form_client_3_scale_role_member_label: 'Benutzer',
         clients_form_client_3_scale_role_admin_label: 'Admin',
         clients_form_allow_customization_field: 'White-Labelling zulassen',
         clients_form_add_client_button: 'Kunde hinzufügen',
         clients_form_edit_client_button: 'Änderungen speichern',
         clients_form_cancel_button: 'Abbrechen',
         clients_form_submitting_client_button: 'Verarbeiten...',
         clients_name_required_form_error: 'Name ist erforderlich',
         clients_email_required_form_error: 'E-Mail-Adresse ist erforderlich',
         clients_api_3scale_role_required_form_error:
           '3Scale-Rolle ist erforderlich',
         clients_email_valid_form_error: 'Ungültige E-Mail-Adresse',
         clients_city_required_form_error: 'Stadt ist erforderlich',
         clients_country_required_form_error: 'Land ist erforderlich',
         clients_add_form_success: 'Kunde wurde erfolgreich angelegt!',
         clients_edit_form_success: 'Kunde wurde erfolgreich geändert!',
         clients_form_api_error: 'Interner Client – API-Serverfehler',
         clients_form_email_error: 'Die E-Mail-Adresse ist bereits vorhanden',
         clients_no_record_found: 'Kein Kunde gefunden',
         client_deactivated_message: 'Der Kunde wurde erfolgreich deaktiviert',
         client_activated_message: 'Der Kunde wurde erfolgreich aktiviert',
         clients_bulk_no_user_is_selected_error_message:
           'Mehrere Zeilen zum Deaktivieren auswählen',
         clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} Kunde(n) wurde(n) erfolgreich deaktiviert',
         client_single_stats_table_title: 'Status',
         /* END Clients */
         client_single_first_bread_crumb: 'Kunden',
         client_single_second_bread_crumb: 'Kunde anzeigen',
         client_single_country_label: 'Land',
         client_single_devices_label: 'Devices',
         client_single_edit_button: 'Ändern',
         client_single_users_tab: 'Benutzer',
         client_single_apis_tab: 'API-Keys',
         client_single_customization_tab: 'White-Label',
         client_single_customization_tab_logo: 'Logo',
         client_single_customization_tab_primary_color: 'Hauptfarbe',
         client_single_customization_tab_secondary_color: 'Sekundärfarbe',
         client_single_customization_tab_submit_button: 'Änderungen speichern',
         client_single_customization_color_schema_success:
           'White-Labelling war erfolgreich.',
         client_single_filter_search: 'Suchen',
         client_single_add_new_user_button: 'Benutzer hinzufügen',
         client_single_add_new_api_key_button: 'API-Key hinzufügen',
         client_single_role_id_filter: 'Rolle',
         client_single_users_search: 'Suchen',
         client_single_filter_reset_button: 'Zurücksetzen',
         client_single_users_all: 'ALLE',
         client_single_users_active: 'AKTIV',
         client_single_users_inactive: 'INAKTIV',
         client_single_filter_delete_button: 'Entfernen',
         client_single_users_table_name: 'Name',
         client_single_users_table_address: 'Adresse',
         client_single_users_table_role_id: 'Rolle',
         client_single_users_table_status: 'Status',
         client_single_users_table_added: 'Hinzugefügt',
         client_single_users_table_no_record_found: 'Kein Gerät gefunden',
         client_single_table_row_per_page: 'Ergebnisse pro Seite',
         client_single_stats_online_devices_label: 'Online-Devices',
         client_single_stats_error_devices_label: 'Alarm-Devices',
         client_single_no_user_selected_for_de_activation_message:
           'Wählen Sie ein Gerät aus, um es zu deaktivieren',
         /* END Clients Single */
         /* Service Clients */
         service_clients: 'Servicekunde',
         service_clients_all: 'ALLE',
         service_clients_active: 'AKTIV',
         service_clients_inactive: 'INAKTIV',
         service_clients_add_new: 'Neu hinzufügen',
         service_clients_filter_search: 'Suchen',
         service_clients_sort_by_filter_label: 'Sortieren nach',
         service_clients_sort_by_value_1: 'Datum (aktuelles)',
         service_clients_sort_by_value_2: 'Datum (ältestes)',
         service_clients_sort_by_value_3: 'Alphabetisch',
         service_clients_country_filter_label: 'Land',
         service_clients_filter_reset_button: 'Zurücksetzen',
         service_clients_filter_delete_button: 'Entfernen',
         service_clients_table_name: 'Name',
         service_clients_table_country: 'Land',
         service_clients_table_devices: 'Devices',
         service_clients_table_added: 'Hinzugefügt',
         service_clients_table_status: 'Status',
         service_clients_table_row_per_page: 'Ergebnisse pro Seite',
         service_clients_bread_crumb_main_title: 'Servicekunden',
         add_service_clients_bread_crumb_first_link: 'Servicekunde hinzufügen',
         add_service_clients_form_header: 'Servicekunde hinzufügen',
         edit_service_clients_bread_crumb_first_link: 'Servicekunde ändern',
         edit_service_clients_form_header: 'Servicekunde ändern',
         service_clients_form_service_client_name_field: 'Name',
         service_clients_form_email_field: 'E-Mail-Adresse',
         service_clients_form_client_field: 'Kunde',
         service_clients_form_user_field: 'Benutzer',
         service_clients_form_city_field: 'Stadt',
         service_clients_form_country_field: 'Land',
         service_clients_form_add_service_client_button: 'Änderungen speichern',
         service_clients_form_edit_service_client_button:
           'Änderungen speichern',
         service_clients_form_cancel_button: 'Abbrechen',
         service_clients_form_submitting_service_client_button:
           'Verarbeiten...',
         service_clients_name_required_form_error: 'Name ist erforderlich',
         service_clients_email_required_form_error: 'E-Mail- ist erforderlich',
         service_clients_focal_user_id_required_form_error:
           'Focal-Benutzer ist erforderlich',
         service_clients_email_valid_form_error:
           'Ihre E-Mail-Adresse ist ungültig',
         service_clients_city_required_form_error: 'Stadt ist erforderlich',
         service_clients_client_required_form_error: 'Kunde ist erforderlich',
         service_clients_country_required_form_error: 'Land ist erforderlich',
         service_clients_add_form_success:
           'Servicekunde wurde erfolgreich angelegt!',
         service_clients_edit_form_success:
           'Servicekunde wurde erfolgreich geändert!',
         service_clients_form_api_error:
           'Interner Servicekunde – API-Serverfehler',
         service_clients_form_email_error:
           'Diese E-Mail-Adresse wird bereits benutzt',
         service_clients_no_record_found: 'Keinen Unterkunden gefunden',
         service_client_deactivated_message:
           'Servicekunde wurde erfolgreich deaktiviert',
         service_client_activated_message:
           'Servicekunde wurde erfolgreich aktiviert',
         service_clients_bulk_no_user_is_selected_error_message:
           'Wählen Sie ein Gerät aus, um es zu deaktivieren',
         service_clients_bulk_deactivated_message:
           '{{ USERS_COUNT }} Servicekunde(n) wurde(n) erfolgreich deaktiviert',
         service_client_single_stats_table_title: 'Status',
         /* END Service Clients */
         service_client_single_first_bread_crumb: 'Servicekunden',
         service_client_single_second_bread_crumb: 'Servicekunde anzeigen',
         service_client_single_country_label: 'Land',
         service_client_single_devices_label: 'Devices',
         service_client_single_edit_button: 'Ändern',
         service_client_single_users_tab: 'Benutzer',
         service_client_single_apis_tab: 'API-Keys',
         service_client_single_filter_search: 'Suchen',
         service_client_single_add_new_user_button: 'Benutzer hinzufügen',
         service_client_single_add_new_api_key_button: 'API-Key hinzufügen',
         service_client_single_role_id_filter: 'Rolle',
         service_client_single_users_search: 'Suchen',
         service_client_single_filter_reset_button: 'Zurücksetzen',
         service_client_single_users_all: 'ALLE',
         service_client_single_users_active: 'AKTIV',
         service_client_single_users_inactive: 'INAKTIV',
         service_client_single_filter_delete_button: 'Entfernen',
         service_client_single_users_table_name: 'Name',
         service_client_single_users_table_address: 'Adresse',
         service_client_single_users_table_role_id: 'Rolle',
         service_client_single_users_table_status: 'Status',
         service_client_single_users_table_added: 'Hinzugefügt',
         service_client_single_users_table_no_record_found:
           'Kein Gerät gefunden',
         service_client_single_table_row_per_page: 'Ergebnisse pro Seite',
         service_client_single_stats_active_devices_label: 'Online-Devices',
         service_client_single_stats_error_devices_label: 'Alarm-Devices',
         service_client_single_no_user_selected_for_de_activation_message:
           'Bitte wählen Sie die zu deaktivierenden Datensätze aus',
         /* END Service Clients Single */
         /* Gebruikers */
         users_bread_crumb_main_title: 'Kunden',
         add_users_bread_crumb_first_link: 'Benutzer hinzufügen',
         add_users_form_header: 'Benutzer hinzufügen',
         edit_users_bread_crumb_first_link: 'Benutzer ändern',
         edit_users_form_header: 'Ändern Benutzer',
         users_form_first_name_field: 'Vorname',
         users_form_last_name_field: 'Nachname',
         users_form_email_field: 'E-Mail-Adresse',
         users_form_official_email_field: 'E-Mail-Adresse',
         users_form_phone_field: 'Telefonnummer',
         users_form_official_phone_number_field: 'Telefonnummer',
         users_form_password_field: 'Passwort',
         users_form_role_id_field: 'Rolle',
         users_form_select_role_id_field: 'Rolle auswählen',
         users_form_street_number_field: 'Straße',
         users_form_house_number_field: 'Hausnummer',
         users_form_neighbourhood_field: 'Bezirk',
         users_form_postal_code_field: 'Postleitzahl',
         users_form_city_field: 'Stadt',
         users_form_country_field: 'Land',
         users_form_email_verification_field: 'E-Mail-Bestätigung',
         users_form_email_verification_sub_title_field:
           'Wenn Sie diese Schaltfläche deaktivieren, wird automatisch eine Bestätigungsmail an den Benutzer gesendet',
         users_form_add_user_button: 'Benutzer hinzufügen',
         users_form_edit_user_button: 'Änderungen speichern',
         users_form_cancel_button: 'Abbrechen',
         users_form_submitting_user_button: 'Verarbeiten...',
         users_first_name_required_form_error: 'Vorname ist erforderlich',
         users_last_name_required_form_error: 'Nachname ist erforderlich',
         users_email_required_form_error: 'E-Mail- ist erforderlich',
         users_email_valid_form_error: 'Ihre E-Mail-Adresse ist ungültig',
         users_password_required_form_error: 'Passwort ist erforderlich',
         users_country_required_form_error: 'Land ist erforderlich',
         users_role_id_required_form_error: 'Rolle ist erforderlich',
         users_password_form_error:
           'Das Passwort muss mindestens 8 Zeichen enthalten, davon mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen',
         users_add_form_success: 'Benutzer wurde erfolgreich angelegt!',
         users_edit_form_success: 'Benutzer wurde erfolgreich geändert!',
         users_form_api_error: 'Interne Benutzer – API-Serverfehler',
         users_form_email_error: 'Die E-Mail-Adresse ist bereits vorhanden',
         users_no_record_found: 'Keine Benutzer gefunden',
         user_deactivated_message: 'Der Benutzer wurde erfolgreich deaktiviert',
         user_activated_message: 'Der Benutzer wurde erfolgreich aktiviert',
         user_email_verified_title: 'E-Mail-Adresse bestätigt',
         user_email_verified_sub_title:
           'Wenn Sie diese Schaltfläche deaktivieren, wird automatisch eine Bestätigungsmail an den Benutzer gesendet',
         users_bulk_no_user_is_selected_error_message:
           'Wählen Sie einen oder mehrere Benutzer aus, um diese(n) zu deaktivieren',
         users_bulk_deactivated_message:
           '{{ USERS_COUNT }} Benutzer wurden erfolgreich deaktiviert',
         /** Projects */
         projects: 'Projekte',
         projects_all: 'ALLE',
         projects_active: 'AKTIV',
         projects_inactive: 'INAKTIV',
         projects_unassigned: 'NICHT ZUGEWIESEN',
         projects_add_new: 'Neues Projekt',
         projects_filter_search: 'Projekt suchen',
         projects_sort_by_filter_label: 'Sortieren nach',
         projects_sort_by_value_1: 'Datum (aktuelles)',
         projects_sort_by_value_2: 'Datum (ältestes)',
         projects_sort_by_value_3: 'Alphabetisch',
         projects_country_filter_label: 'Land',
         projects_filter_reset_button: 'Zurücksetzen',
         projects_filter_delete_button: 'Entfernen',
         projects_table_name: 'Projektname',
         projects_table_batch_number: 'Batchnummer',
         projects_table_client: 'Zugewiesener Kunde',
         projects_table_devices: 'Devices',
         projects_table_added: 'Hinzugefügt am',
         projects_table_status: 'Status',
         projects_table_row_per_page: 'Ergebnisse pro Seite',
         projects_bread_crumb_main_title: 'Projekte',
         add_projects_bread_crumb_first_link: 'Projekt hinzufügen',
         add_projects_form_header: 'Projekt hinzufügen',
         edit_projects_bread_crumb_first_link: 'Projekt ändern',
         edit_projects_form_header: 'Projekt ändern',
         projects_form_project_name_field: 'Projektname',
         projects_form_email_field: 'E-Mail-Adresse',
         projects_form_client_field: 'Kunde',
         projects_form_service_client_field: 'Servicekunde',
         projects_form_user_field: 'Benutzer',
         projects_form_description_field: 'Beschreibung (optional)',
         projects_form_add_project_button: 'Projekt speichern',
         projects_form_edit_project_button: 'Änderungen speichern',
         projects_form_cancel_button: 'Abbrechen',
         projects_form_submitting_service_client_button: 'Verarbeiten...',
         projects_name_required_form_error: 'Name ist erforderlich',
         projects_form_unqiue_project_order_number_error:
           'Projektauftragsnummer muss eindeutig sein',
         projects_batch_number_required_form_error:
           'Batchnummer ist erforderlich',
         projects_client_required_form_error: 'Kunde ist erforderlich',
         projects_description_required_form_error:
           'Beschreibung ist erforderlich',
         projects_add_form_success: 'Projekt wurde erfolgreich angelegt!',
         projects_edit_form_success: 'Projekt wurde erfolgreich geändert!',
         projects_form_api_error: 'Interne Projekte – API-Serverfehler',
         projects_no_record_found: 'Keine Projekte gefunden',
         projects_deactivated_message: 'Projekt wurde erfolgreich deaktiviert',
         projects_activated_message: 'Projekt wurde erfolgreich aktiviert',
         projects_bulk_no_user_is_selected_error_message:
           'Wählen Sie ein oder mehrere Projekte aus, um diese(s) zu deaktivieren',
         projects_bulk_deactivated_message:
           '{{ USERS_COUNT }} Projekte wurden erfolgreich deaktiviert',
         projects_single_stats_table_title: 'Status',
         /** Devices */
         devices_form_header: 'Devicesübersicht',
         devices_all: 'ALLE',
         devices_active: 'Online',
         devices_inactive: 'Offline',
         devices_error: 'ALARM',
         devices_register_enabled: 'Eingeschaltet',
         devices_register_disabled: 'Ausgeschaltet',
         devices_add_new: 'Neues Gerät',
         devices_name: 'Name',
         devices_address: 'Installationsadresse',
         devices_description: 'Art der Übergabestation',
         devices_client: 'Kunde',
         devices_project: 'Projekt',
         devices_status: 'Status',
         device_is_online: 'Gerät ist „online“',
         device_is_offline: 'Gerät ist „offline“',
         devices_last_viewed: 'Letzter Beitrag',
         devices_action: 'Maßnahme',
         devices_search: 'Suchen',
         devices_row_per_page: 'Ergebnisse pro Seite',
         device_rssi_title: 'RSSI',
         device_status_reg_105_no_error_255: 'Kein Alarm',
         device_status_menu_list: JSON.stringify([
           {
             key: 'operating_state',
             value: 'Betriebszustand',
             permission: 'operating-state-tab-read',
           },
           {
             key: 'settings_hot_water',
             value: 'Einstellungen Warmwasser',
             permission: 'settings-hot-water-tab-read',
           },
           {
             key: 'settings_heating_and_cooling',
             value: 'Einstellungen heizen und kühlen',
             permission: 'settings-heating-and-cooling-tab-read',
           },
           {
             key: 'manual_control',
             value: 'Handbedienung',
             permission: 'manual-control-tab-read',
           },
           {
             key: 'm_bus_meters',
             value: 'Energiezähler (M-Bus)',
             permission: 'm-bus-meters-tab-read',
           },
           {
             key: 'live_hot_water',
             value: 'Liveprotokoll Warmwasser',
             permission: 'livelog-hot-water-tab-read',
           },
           {
             key: 'live_heating',
             value: 'Liveprotokoll Heizen',
             permission: 'livelog-heating-tab-read',
           },
           {
             key: 'live_cooling',
             value: 'Liveprotokoll kühlen',
             permission: 'livelog-cooling-tab-read',
           },
         ]),
         device_status_menu_keys_list: JSON.stringify([
           'operating_state',
           'settings_hot_water',
           'settings_heating_and_cooling',
           'manual_control',
           'm_bus_meters',
           'live_hot_water',
           'live_heating',
           'live_cooling',
         ]),
         devices_filter_search: 'Gerät suchen',
         devices_sort_by_filter_label: 'Sortieren nach',
         devices_sort_by_value_1: 'Datum (aktuelles)',
         devices_sort_by_value_2: 'Datum (ältestes)',
         devices_sort_by_value_3: 'Alphabetisch',
         devices_country_filter_label: 'Land',
         devices_filter_reset_button: 'Zurücksetzen',
         devices_filter_delete_button: 'Entfernen',
         devices_table_name: 'Name',
         devices_table_firmware: 'Firmware',
         devices_table_device_type: 'Devicestyp',
         devices_table_batch_number: 'Batchnummer',
         devices_table_client: 'Kunden',
         devices_table_devices: 'Devices',
         devices_table_added: 'Hinzugefügt',
         devices_table_status: 'Status',
         devices_table_row_per_page: 'Ergebnisse pro Seite',
         devices_bread_crumb_main_title: 'Devices',
         add_devices_bread_crumb_first_link: 'Gerät hinzufügen',
         add_devices_form_header: 'Gerät hinzufügen',
         edit_devices_bread_crumb_first_link: 'Gerät ändern',
         edit_devices_form_header: 'Gerät ändern',
         devices_form_device_information_tab: 'Devicesinfo',
         devices_form_name_field: 'Devicesname',
         devices_form_type_field: 'Devicestyp',
         devices_form_ip_address_field: 'IP-Adresse',
         devices_form_preset_field: 'Voreinstellungen',
         devices_form_project_field: 'Projekt',
         devices_form_client_field: 'Kunde',
         devices_form_batch_number_field: 'Batchnummer',
         devices_form_serial_number_field: 'Seriennummer Mercurius',
         devices_form_box_serial_number_field: 'Seriennummer Übergabestation',
         devices_form_firmware_field: 'Firmwareversion',
         devices_form_description_field: 'Art der Übergabestation',
         devices_form_device_address_tab: 'Installationsadresse',
         devices_form_street_number_field: 'Straße',
         devices_form_house_number_field: 'Hausnummer',
         devices_form_postal_code_field: 'Postleitzahl',
         devices_form_neighbourhood_field: 'Zusätzliche Informationen',
         devices_form_city_field: 'Stadt',
         devices_form_country_field: 'Land',
         devices_form_floor_number_field: 'Obergeschoss',
         devices_form_building_number_field: 'Baunummer',
         devices_form_additional_information_tab: 'Zusätzliche Informationen',
         devices_form_sn_heat_unit_field: 'Seriennummer Übergabestation',
         devices_form_article_number_field: 'Artikelnummer Übergabestation',
         devices_form_prod_order_nr_week_field: 'Fertigungsauftragsnummer',
         devices_form_serial_number_mercurius_field: 'Seriennummer Mercurius',
         devices_form_sim_card_number_field: 'Sim-Kartennummer',
         devices_form_customer_ref_field: 'Kundenreferenz',
         devices_form_production_date_field: 'Produktionsdatum',
         devices_form_order_number_field: 'Bestellnummer',
         devices_form_product_number_field: 'Artikelnummer',
         devices_form_model_number_field: 'Modellnummer',
         devices_form_note_field: 'Anmerkung (optional)',
         devices_form_settings_tab: 'Einstellungen',
         devices_form_device_interval_field: 'Intervall',
         devices_form_is_device_enabled_sensor_field: 'Sensordaten einschalten',
         devices_form_is_device_activated_field: 'Gerät deaktivieren',
         devices_form_mbus_tab: 'M-Bus',
         devices_form_is_device_enabled_heat_meter_field:
           'Wärmezähler einschalten',
         devices_form_is_device_enabled_cool_meter_field:
           'Kältezähler einschalten',
         devices_form_is_device_enabled_electric_meter_field:
           'Stromzähler einschalten',
         devices_form_is_device_enabled_bi_directional_meter_field:
           'Wärme-/Kältezähler einschalten',
         devices_form_is_device_enabled_water_meter_field:
           'Wasserzähler einschalten',
         devices_form_is_device_enabled_mbus_search_meter_button:
           'M-Bus suchen',
         devices_form_add_device_button: 'Gerät hinzufügen',
         devices_form_edit_device_button: 'Änderungen speichern',
         devices_form_cancel_button: 'Abbrechen',
         devices_form_submitting_service_client_button: 'Verarbeiten...',
         devices_name_required_form_error: 'Name ist erforderlich',
         devices_batch_number_required_form_error:
           'Batchnummer ist erforderlich',
         devices_serial_number_required_form_error:
           'Seriennummer ist erforderlich',
         devices_sn_heat_number_numeric_form_error:
           'Seriennummer der Übergabestation muss eine Zahl sein',
         devices_article_number_form_error: 'Artikelnummer ist erforderlich',
         devices_prod_order_nr_week_form_error:
           'Fertigungsauftragsnummer ist erforderlich',
         devices_serial_number_mercurius_form_error:
           'Seriennummer Mercurius ist erforderlich',
         devices_sim_card_number_form_error:
           'Sim-Kartennummer ist erforderlich',
         devices_device_interval_numeric_form_error:
           'Intervall muss eine Zahl sein',
         devices_client_required_form_error: 'Kunde ist erforderlich',
         devices_project_required_form_error: 'Projekt ist erforderlich',
         devices_preset_required_form_error: 'Voreinstellung ist erforderlich',
         devices_description_required_form_error:
           'Beschreibung ist erforderlich',
         devices_add_form_success: 'Gerät wurde erfolgreich erstellt!',
         devices_edit_form_success: 'Gerät wurde erfolgreich geändert!',
         devices_form_unique_error:
           'Seriennummer Übergabestation und Seriennummer Mercurius müssen eindeutig sein',
         devices_form_api_error: 'Interne Devices – API-Serverfehler',
         devices_no_record_found: 'Keine Devices gefunden',
         devices_deactivated_message: 'Gerät wurde erfolgreich deaktiviert',
         devices_activated_message: 'Gerät wurde erfolgreich aktiviert',
         devices_search_mbus_registers_success:
           'Die Meldung wurde erfolgreich an das Gerät gesendet.',
         devices_search_mbus_registers_missing_serial_number_error:
           'Tragen Sie die Seriennummer ein, um fortzufahren.',
         devices_bulk_no_user_is_selected_error_message:
           'Wählen Sie zum Aktivieren Benutzer aus',
         devices_bulk_deactivated_message:
           '{{ USERS_COUNT }} Devices wurden erfolgreich deaktiviert',
         // Devices Single
         device_single_page_title: 'Devicesdetails',
         devices_single_bread_crumb_main_title: 'Devicesübersicht',
         devices_single_bread_crumb_first_link: 'Devicesseite',
         devices_single_basic_details_client: 'KUNDE',
         devices_single_basic_details_serial_number: 'SERIENNUMMER MERCURIUS',
         devices_single_basic_details_firmware: 'FIRMWARE',
         devices_single_basic_details_type: 'GERÄTETYP',
         devices_single_edit_device_button: 'Gerät ändern',
         device_single_error_status: 'Fehler',
         device_single_online_status: 'Online',
         device_single_offline_status: 'Offline',
         device_single_rss_device_status: 'Devicesstatus',
         device_single_rss_signal_strength: 'Signalstärke',
         device_single_temperature_tab: 'Historie',
         device_single_temperature_title:
           'Die historischen Daten der Übergabestation',
         device_single_temperature_hr: 'Stunden',
         device_single_registers_tab: 'Register',
         device_single_event_tab: 'Ereignisse',
         device_single_device_details_tab: 'Info',
         device_single_device_api_timer_seconds_label: 'Sekunden',
         device_single_device_api_timer_minute_label: 'Minute',
         device_single_device_api_timer_minutes_label: 'Minuten',
         device_single_recall_device_status_api_timer_field: 'API-Intervall',
         devices_single_last_seen: 'Letzter Beitrag',
         devices_single_fetch_registers: 'DATEN ERNEUERN',
         fetch_registers_success:
           'Die Anfrage wurde erfolgreich an das Gerät gesendet.',
         write_registers_success:
           'Die Schreibmeldung wurde erfolgreich an das Gerät gesendet.',
         write_registers_error: 'Ändern Sie den Wert, um fortzufahren.',
         fetch_registers_m_bus_meters_not_supported:
           'M-Bus-Zähler werden derzeit nicht erkannt. Kontrollieren Sie den Anschluss und/oder das M-Bus-Modul des Energiezählers.',
         // Operating Status
         business_situation_operating_status_title:
           'Betriebsstatus Übergabestation',
         device_status_nr: 'NR',
         device_status_name: 'Name',
         device_status_value: 'Wert',
         business_situation_operating_status_100: 'Heizstatus',
         business_situation_operating_status_100_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Anfrage',
             2: 'Frage',
             3: 'Nach dem Umwälzen',
             4: 'Aus',
             5: 'Vor dem Umwälzen',
           },
         }),
         business_situation_operating_status_101: 'Warmwasserstatus',
         business_situation_operating_status_101_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Anfrage (zeitweilig unterbrochen)',
             2: 'Frage',
             3: 'Nach dem Umwälzen',
             4: 'Aus',
           },
         }),
         business_situation_operating_status_102: 'Warmhaltestatus',
         business_situation_operating_status_102_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Aktiv',
           },
         }),
         business_situation_operating_status_103: 'Anti-Legionellen-Status',
         business_situation_operating_status_103_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Anfrage (zeitweilig unterbrochen)',
             2: 'Frage',
             3: 'Nach dem Umwälzen',
             4: 'Aus',
           },
         }),
         business_situation_operating_status_104: 'Kühlstatus',
         business_situation_operating_status_104_list: JSON.stringify({
           list: {
             0: 'Stand-by',
             1: 'Anfrage (zeitweilig unterbrochen)',
             2: 'Initialisieren',
             3: 'Frage',
             4: 'Nach Umwälzen',
             5: 'Vor Umwälzen',
           },
         }),
         business_situation_operating_status_1802: 'Elektrisches Element',
         business_situation_operating_status_1802_list: JSON.stringify({
           list: {
             0: 'Aus',
             1: 'Ein',
           },
         }),
         business_situation_operating_status_105: 'Alarm',
         business_situation_operating_status_106: 'Softwareversion',
         business_situation_operating_status_107: 'Warmwasserregelventil (M1)',
         business_situation_operating_status_108:
           'Heizen/Kühlen Regelventil (M2)',
         business_situation_operating_status_109: 'Umwälzpumpe',
         business_situation_operating_status_109_list: JSON.stringify({
           list: {
             0: 'Aus',
             1: 'Umwälzen',
           },
         }),
         business_situation_operating_status_110: '6-Wege-Kugelhahn',
         business_situation_operating_status_110_list: JSON.stringify({
           list: {
             0: 'Stand: Heizen',
             1: 'Stand: Kühlen',
           },
         }),
         business_situation_operating_status_112_list: JSON.stringify({
           list: {
             0: 'Heizen oder Bedarf',
             1: 'Kühlen',
           },
         }),
         business_situation_operating_status_113: 'Raumthermostat',
         business_situation_operating_status_113_list: JSON.stringify({
           list: {
             1: 'Kein Wärmebedarf',
             2: 'Wärmebedarf',
           },
         }),
         business_situation_operating_status_114: 'OpenTherm-Anzeige',
         business_situation_operating_status_114_list: JSON.stringify({
           list: {
             0: 'Inaktive Verbindung',
             1: 'Aktive Verbindung',
           },
         }),
         business_situation_operating_status_115: 'Devicestyp',
         // Business situation
         business_situation_measured_values_title: 'Messwerte',
         business_situation_measured_values_200: 'Volumenstrom Warmwasser',
         business_situation_measured_values_201: 'T-Warmwasser',
         business_situation_measured_values_202: 'T-Primar Vorlauf',
         business_situation_measured_values_203: 'T-Primar Rücklauf',
         business_situation_measured_values_205: 'T-Sekundär Vorlauf',
         business_situation_measured_values_206: 'T-Sekundär Rücklauf',
         business_situation_measured_values_204:
           'Berechnete Vorlauftemperatur (Sollwert)',
         business_situation_measured_values_207: 'Raumtemperatur (OpenTherm)',
         business_situation_measured_values_208: 'Raum-Sollwert (OpenTherm)',
         business_situation_measured_values_210:
           'Wasserdruck in der Inneninstallation (Sekundär)',
         // Operating time
         business_situation_operating_time_title: 'Betriebsdauer',
         business_situation_operating_time_800:
           'Gesamtbetriebsdauer Übergabestation',
         business_situation_operating_time_801:
           'Gesamtbetriebsdauer Wärmebedarf',
         business_situation_operating_time_802:
           'Gesamtbetriebsdauer Kältebedarf',
         business_situation_operating_time_803:
           'Gesamtbetriebsdauer Warmwasserbedarf',
         business_situation_operating_time_804: 'Anzahl der Zapfstellen',
         business_situation_operating_time_805:
           'Gesamtvolumen Warmwasser (Hinweis: dies ist ein Richtwert)',
         business_situation_operating_time_806: 'Stromunterbrechungen',
         // Warmtapwater
         settings_dhw_warm_tap_water_title: 'Einstellungen Warmwasser',
         settings_dhw_warm_tap_water_300: 'Warmwasser Sollwert',
         settings_dhw_warm_tap_water_301: 'Warmhaltemodus',
         settings_dhw_warm_tap_water_301_list: JSON.stringify({
           list: {
             0: 'Aus',
             1: 'Frostschutz-Programm',
             2: 'Öko-Programm',
             3: 'Komfort-Programm',
             4: 'Dynamisch (empfohlen)',
           },
         }),
         settings_dhw_warm_tap_water_302:
           'Warmhaltesollwert (Komfort-Einstellung)',
         settings_dhw_warm_tap_water_303: 'Leitungsschwelle',
         settings_dhw_warm_tap_water_304: 'Warmwasser einschalten',
         settings_dhw_warm_tap_water_304_list: JSON.stringify({
           list: {
             0: 'Aus',
             1: 'Ein',
           },
         }),
         // Thermal disinfection
         settings_thermal_disinfection_title: 'Einstellungen Anti-Legionellen',
         settings_thermal_disinfection_400: 'Anti-Legionellen-Einschaltung',
         settings_thermal_disinfection_400_list: JSON.stringify({
           list: {
             0: 'Aus',
             1: 'Ein',
           },
         }),
         settings_thermal_disinfection_401: 'Anti-Legionellen Sollwert',
         settings_thermal_disinfection_402: 'Anti-Legionellen Brenndauer',
         settings_thermal_disinfection_403: 'Anti-Legionellen Wartezeit',
         // TE-Booster
         settings_dhw_te_booster_title: 'Einstellungen TE-Booster',
         settings_dhw_te_booster_1800: 'TE-Booster',
         settings_dhw_te_booster_1800_list: JSON.stringify({
           list: {
             0: 'Ausgeschaltet',
             1: 'Eingeschaltet',
           },
         }),
         settings_dhw_te_booster_1804: 'TE-Booster Sollwert',
         settings_dhw_te_booster_1805: 'TE-Booster Sollwert Hysterese',
         settings_dhw_te_booster_1812: 'TE-Booster Sollwert Zeitüberschreitung',
         settings_dhw_te_booster_1813: 'TE-Booster Sollwert Timer',
         settings_dhw_te_booster_1815: 'TE-Booster Aktivierungsschwelle',
         settings_dhw_te_booster_1816: 'TE-Booster angezapftes Volumen',
         // Heating
         heating_and_cooling_settings_heating_title: 'Einstellungen heizen',
         heating_and_cooling_settings_heating_500: 'Maximaler Sollwert heizen',
         heating_and_cooling_settings_heating_501: 'Minimaler Sollwert heizen',
         heating_and_cooling_settings_heating_506:
           'Maximale Rücklauftemperatur heizen',
         heating_and_cooling_settings_heating_507:
           'Kompensationsfaktor für Primar Rücklauf',
         heating_and_cooling_settings_heating_508:
           'Delta primärer und sekundärer Sollwert',
         heating_and_cooling_settings_heating_510: 'Maximaler Schutz heizen',
         heating_and_cooling_settings_heating_509:
           'Minimum primäres und sekundäres Delta',
         heating_and_cooling_settings_heating_514: 'Heizen Sollwert',
         heating_and_cooling_settings_heating_515: 'Heizen einschalten',
         heating_and_cooling_settings_heating_515_list: JSON.stringify({
           list: {
             0: 'Aus',
             1: 'ein',
           },
         }),
         // Cooling
         heating_and_cooling_settings_cooling_title: 'Einstellungen kühlen',
         heating_and_cooling_settings_cooling_600: 'Kühlen Sollwert',
         heating_and_cooling_settings_cooling_601: 'Minimaler Primar Rücklauf',
         heating_and_cooling_settings_cooling_602:
           'Kompensationsfaktor für Primar Rücklauf',
         heating_and_cooling_settings_cooling_605: 'Maximaler Schutz kühlen',
         heating_and_cooling_settings_cooling_606: 'Minimaler Sollwert kühlen',
         heating_and_cooling_settings_cooling_607: 'Maximaler Sollwert kühlen',
         heating_and_cooling_settings_cooling_611: 'Kühlen einschalten',
         heating_and_cooling_settings_cooling_611_list: JSON.stringify({
           list: {
             0: 'Aus',
             1: 'Ein',
           },
         }),
         // Secondary General
         heating_and_cooling_settings_secondary_general_title:
           'Allgemeine Einstellungen',
         heating_and_cooling_settings_secondary_general_512: 'Minimaler Druck',
         heating_and_cooling_settings_secondary_general_513: 'Maximaler Druck',
         heating_and_cooling_settings_secondary_general_603:
           'Wartezeit Umschaltung Heizen-Kühlen',
         heating_and_cooling_settings_secondary_general_604:
           '6-Wege-Kugelhahn Wartezeit',
         // Changeover WKW
         heating_and_cooling_settings_changeover_wkw_title:
           'Einstellungen 3,5-Rohrsystem',
         heating_and_cooling_settings_changeover_wkw_1850:
           '3,5-Rohrkonfiguration',
         heating_and_cooling_settings_changeover_wkw_1850_list: JSON.stringify({
           list: {
             0: 'Heizen',
             1: 'Kühlen',
           },
         }),
         heating_and_cooling_settings_changeover_wkw_1851:
           'Erkennungsschwelle für Kühlen',
         heating_and_cooling_settings_changeover_wkw_1852:
           'Erkennungsschwelle für Heizen',
         heating_and_cooling_settings_changeover_wkw_1853:
           'Heizen abgesenkter Sollwert',
         heating_and_cooling_settings_changeover_wkw_1854:
           'Abgesenkter Sollwert verwendet',
         heating_and_cooling_settings_changeover_wkw_1854_list: JSON.stringify({
           list: {
             0: 'Aus',
             1: 'Ein',
           },
         }),
         heating_and_cooling_settings_changeover_wkw_1855:
           'Vorerkennung M2-Position',
         heating_and_cooling_settings_changeover_wkw_1856:
           'Erkennungsintervall',
         heating_and_cooling_settings_changeover_wkw_1857:
           'Erkennungsintervall Timer',
         heating_and_cooling_settings_changeover_wkw_1858:
           'Vorerkennung Zeitüberschreitung',
         heating_and_cooling_settings_changeover_wkw_1859: 'Vorerkennung Timer',
         heating_and_cooling_settings_changeover_wkw_1860:
           'Konfigurationsschalter Timer',
         heating_and_cooling_settings_changeover_wkw_1861:
           'Schalter Zeitüberschreitung',
         heating_and_cooling_settings_changeover_wkw_1862:
           'Konfiguration Schaltschwelle',
         heating_and_cooling_settings_changeover_wkw_1863:
           'Boost-Funktionsmodus',
         heating_and_cooling_settings_changeover_wkw_1863_list: JSON.stringify({
           list: {
             0: 'Ausgeschaltet',
             1: 'Eingeschaltet',
           },
         }),
         heating_and_cooling_settings_changeover_wkw_1864:
           'Boost-Erkennung Timer',
         heating_and_cooling_settings_changeover_wkw_1865:
           'Boost-Erkennung Zeitüberschreitung',
         heating_and_cooling_settings_changeover_wkw_1866:
           'Maximale Boost-Position',
         // Manual Operation
         settings_manual_operation_title: 'Handbedienung',
         settings_manual_operation_700: 'Handbedienung einschalten',
         settings_manual_operation_700_list: JSON.stringify({
           list: {
             0: 'Aus',
             1: 'Ein',
           },
         }),
         settings_manual_operation_701: 'Warmwasserregelventil bedienen (M1)',
         settings_manual_operation_702:
           'Heizen/Kühlen Regelventil bedienen (M2)',
         settings_manual_operation_703: 'Pumpe',
         settings_manual_operation_703_list: JSON.stringify({
           list: {
             0: 'Aus',
             1: 'Ein',
           },
         }),
         settings_manual_operation_704: '6-Wege-Kugelhahn',
         settings_manual_operation_704_list: JSON.stringify({
           list: {
             0: 'Stand: Heizen',
             1: 'Stand: Kühlen',
           },
         }),
         settings_manual_operation_706: 'Ausgabe Wärmebedarfsstatus',
         settings_manual_operation_706_list: JSON.stringify({
           list: {
             0: 'Heizen oder Bedarf',
             1: 'Kühlen',
           },
         }),
         settings_manual_operation_707:
           'Warmwasserregelventil bedienen (M1)[%]',
         settings_manual_operation_708:
           'Heizen/Kühlen Regelventil bedienen (M2)[%]',
         settings_manual_operation_1801: 'Elektrisches Element TE-Booster',
         settings_manual_operation_1801_list: JSON.stringify({
           list: {
             0: 'Ausgeschaltet',
             1: 'Eingeschaltet',
           },
         }),
         settings_manual_operation_709: 'Handbedienung ausschalten',
         // Live Tap Water
         live_tap_water_title: 'Liveprotokoll Warmwasser',
         live_tap_water_101: 'Warmwasserstatus',
         live_tap_water_107: 'Warmwasserregelventil (M1)',
         live_tap_water_200: 'Warmwasser Volumenstrom',
         live_tap_water_201: 'T-Warmwasser',
         live_tap_water_202: 'T-Primar Vorlauf',
         live_tap_water_203: 'T-Primar Rücklauf',
         live_tap_water_1007: 'Primärer Durchflussenergiezähler',
         live_tap_water_1008: 'Aktuelle Leistung',
         // Live CV
         live_cv_title: 'Liveprotokoll Heizen',
         live_cv_100: 'Heizen Status',
         live_cv_108: 'Heizen/Kühlen Regelventil (M2)',
         live_cv_202: 'T-Primar Vorlauf',
         live_cv_203: 'T-Primar Rücklauf',
         live_cv_204: 'Berechnete Vorlauftemperatur (Sollwert)',
         live_cv_205: 'T-Sekundär Vorlauf',
         live_cv_206: 'T-Sekundär Rücklauf',
         live_cv_113: 'Raumthermostat Kontakt',
         live_cv_210: 'Wasserdruck in der Inneninstallation',
         live_cv_1007: 'Primärer Durchfluss M-Bus-Zähler',
         live_cv_1008: 'Aktuelle Leistung',
         // Live Cooling
         live_cooling_title: 'Liveprotokoll kühlen',
         live_cooling_104: 'Kühlstatus',
         live_cooling_109: 'Pumpe',
         live_cooling_110: '6-Wege-Kugelhahn',
         live_cooling_113: 'Raumthermostat Kontakt',
         live_cooling_108: 'Heizen/Kühlen Regelventil (M2)',
         live_cooling_202: 'T-Primar Vorlauf',
         live_cooling_204: 'Berechnete Vorlauftemperatur (Sollwert)',
         live_cooling_205: 'T-Sekundär Vorlauf',
         live_cooling_206: 'T-Sekundär Rücklauf',
         // M Bus Limit
         m_bus_limit_title: 'M-Bus-Grenze',
         m_bus_limit_1500: 'Begrenzung einschalten',
         m_bus_limit_1501: 'Seriennummer Begrenzungszähler',
         m_bus_limit_1503: 'Begrenzungsstatus',
         m_bus_limit_1504: 'Begrenzungskonfiguration DHW (h) und CH (l)',
         m_bus_limit_1505: 'Begrenzungsleistung',
         m_bus_limit_1506: 'Begrenzungsdurchfluss',
         m_bus_limit_1507: 'Begrenzung Rücklauftemperatur (nur CH)',
         // Meter 1
         m_bus_meter_1_title: 'Zähler 1 (Wärme)',
         m_bus_meter_1_0000: 'Seriennummer',
         m_bus_meter_1_0002: 'Hersteller',
         m_bus_meter_1_0003: 'Zählertyp',
         m_bus_meter_1_0004: 'Status',
         m_bus_meter_1_0005: 'Volumenstrom',
         m_bus_meter_1_0006: 'Leistung',
         m_bus_meter_1_0007: 'Vorlauftemperatur',
         m_bus_meter_1_0008: 'Rücklauftemperatur',
         m_bus_meter_1_0010: 'Gesamtenergieverbrauch',
         m_bus_meter_1_0012: 'Gesamtvolumen',
         // Meter 2
         m_bus_meter_2_title: 'Zähler 2 (Kälte)',
         m_bus_meter_2_0100: 'Seriennummer',
         m_bus_meter_2_0102: 'Hersteller',
         m_bus_meter_2_0103: 'Zählertyp',
         m_bus_meter_2_0104: 'Status',
         m_bus_meter_2_0105: 'Volumenstrom',
         m_bus_meter_2_0106: 'Leistung',
         m_bus_meter_2_0107: 'Vorlauftemperatur',
         m_bus_meter_2_0108: 'Rücklauftemperatur',
         m_bus_meter_2_0110: 'Gesamtenergieverbrauch',
         m_bus_meter_2_0112: 'Gesamtvolumen',
         // Meter 3
         m_bus_meter_3_title: 'Zähler 3 (Strom)',
         m_bus_meter_3_0200: 'Seriennummer',
         m_bus_meter_3_0202: 'Hersteller',
         m_bus_meter_3_0203: 'Zählertyp',
         m_bus_meter_3_0204: 'Status',
         m_bus_meter_3_0206: 'Strom',
         m_bus_meter_3_0210: 'Energie',
         m_bus_meter_3_0214: 'Spannung',
         // Meter 4
         m_bus_meter_4_title: 'Zähler 4 (Wasser)',
         m_bus_meter_4_0300: 'Seriennummer',
         m_bus_meter_4_0302: 'Hersteller',
         m_bus_meter_4_0303: 'Zählertyp',
         m_bus_meter_4_0304: 'Status',
         m_bus_meter_4_0305: 'Volumenstrom',
         m_bus_meter_4_0307: 'Vorlauftemperatur',
         m_bus_meter_4_0312: 'Gesamtvolumen',
         // Meter 5
         m_bus_meter_5_title: 'Zähler 5 (Wärme/Kälte)',
         m_bus_meter_5_0400: 'Seriennummer',
         m_bus_meter_5_0402: 'Hersteller',
         m_bus_meter_5_0403: 'Zählertyp',
         m_bus_meter_5_0404: 'Status',
         m_bus_meter_5_0405: 'Volumenstrom',
         m_bus_meter_5_0406: 'Leistung',
         m_bus_meter_5_0407: 'Vorlauftemperatur',
         m_bus_meter_5_0408: 'Rücklauftemperatur',
         m_bus_meter_5_0410: 'Gesamtenergieverbrauch (Wärme)',
         m_bus_meter_5_0412: 'Menge',
         m_bus_meter_5_0414: 'Gesamtenergieverbrauch (Kühlung)',
         // History Block Errors
         history_block_errors_title: 'Historie von Blockfehlern',
         history_block_errors_860: 'Blockfehler 1',
         history_block_errors_861: 'Blockfehler 2',
         history_block_errors_862: 'Blockfehler 3',
         history_block_errors_863: 'Blockfehler 4',
         history_block_errors_864: 'Blockfehler 5',
         history_block_errors_865: 'Blockfehler 6',
         history_block_errors_866: 'Blockfehler 7',
         history_block_errors_867: 'Blockfehler 8',
         history_block_errors_868: 'Blockfehler 9',
         history_block_errors_869: 'Blockfehler 10',
         history_block_errors_870: 'Blockfehler 11',
         history_block_errors_871: 'Blockfehler 12',
         history_block_errors_872: 'Blockfehler 13',
         history_block_errors_873: 'Blockfehler 14',
         history_block_errors_874: 'Blockfehler 15',
         // Events
         device_events_type_filter_label: 'Ereignistyp',
         device_events_type_filter_value_1: 'Audit',
         device_events_type_filter_value_2: 'Fehler',
         // Presets
         presets: 'Voreinstellungen',
         presets_all: 'ALLE',
         presets_active: 'AKTIV',
         presets_inactive: 'INAKTIV',
         presets_add_new: 'Hinzufügen',
         presets_filter_search: 'Suchen',
         presets_filter_reset_button: 'Zurücksetzen',
         presets_filter_delete_button: 'Entfernen',
         presets_table_name: 'Name',
         presets_table_status: 'Status',
         presets_updated_date: 'Geändert am',
         presets_action: 'Aktionen',
         presets_table_row_per_page: 'Ergebnisse pro Seite',
         presets_status_menu_list: JSON.stringify([
           {
             key: 'settings_hot_water',
             value: 'Einstellungen Warmwasser',
             permission: 'settings-hot-water-tab-read',
           },
           {
             key: 'settings_heating_and_cooling',
             value: 'Einstellungen heizen und kühlen',
             permission: 'settings-heating-and-cooling-tab-read',
           },
           {
             key: 'manual_control',
             value: 'Handbedienung',
             permission: 'manual-control-tab-read',
           },
         ]),
         add_presets_title: 'Voreinstellung hinzufügen',
         edit_presets_title: 'Voreinstellung ändern',
         presets_form_name_required: 'Name der Voreinstellung ist erforderlich',
         presets_form_register_required: 'Registerwert ist erforderlich',
         presets_form_add_preset_button: 'Änderungen speichern',
         presets_form_edit_preset_button: 'Änderungen speichern',
         presets_form_cancel_button: 'Abbrechen',
         presets_add_form_success: 'Voreinstellung wurde erfolgreich angelegt!',
         presets_edit_form_success:
           'Voreinstellung wurde erfolgreich geändert!',
         presets_form_api_error: 'Interner Serverfehler',
         presets_no_record_found: 'Keine Voreinstellungen gefunden',
         presets_deactivated_message:
           'Voreinstellung wurde erfolgreich deaktiviert',
         presets_activated_message:
           'Voreinstellung wurde erfolgreich aktiviert',
         presets_bulk_no_user_is_selected_error_message:
           'Wählen Sie Datensätze zum Deaktivieren aus',
         presets_bulk_deactivated_message:
           '{{ USERS_COUNT }} Voreinstellung(n) wurde(n) erfolgreich deaktiviert',
         // Audits
         audits: 'Logbuchaktionen',
         audits_all: 'Alle',
         audits_active: 'Audit',
         audits_inactive: 'Störungen',
         audits_filter_search: 'Suchen',
         audits_client_filter_label: 'Kunde',
         audits_error_code_filter_label: 'Störung',
         audits_project_filter_label: 'Projekt',
         audits_order_number_filter_label: 'Bestellnummer',
         audits_events_type_filter_label: 'Ereignistyp',
         audits_date_range_filter_label: 'Reichweite',
         audits_events_type_filter_value_1: 'Audit',
         audits_events_type_filter_value_2: 'Alarm',
         audits_reset_filters_button: 'Zurücksetzen',
         audits_date: 'Datum',
         audits_device: 'Gerät',
         audits_client: 'Kunde',
         audits_message: 'Nachricht',
         audits_table_row_per_page: 'Ergebnisse pro Seite',
         audits_table_no_record_found: 'Keine Devices gefunden',
         // Settings
         settings_update_profile_title: 'Profil bearbeiten',
         settings_notification_title: 'Benachrichtigungen',
         settings_notification_window_exceptions_title:
           'Benachrichtigung beim Auftreten von Ausnahmen im Betriebsfenster',
         settings_notification_device_error_title:
           'Meldung, wenn sich ein Gerät im Störungsmodus befindet',
         settings_notification_channels_title: 'Meldungsmöglichkeiten',
         settings_notification_use_email_title: 'E-Mail',
         /* Admins */
         admins: 'Admins',
         admins_first_bread_crumb: 'Fortes Administratoren',
         admins_filter_search: 'Administrator suchen',
         admins_add_new: 'Administrator hinzufügen',
         admins_filter_reset_button: 'Zurücksetzen',
         admins_filter_delete_button: 'Deaktivieren',
         admins_search: 'Suchen',
         admins_all: 'ALLE',
         admins_active: 'AKTIV',
         admins_inactive: 'INAKTIV',
         admins_table_name: 'Name',
         admins_table_address: 'Adresse',
         admins_table_role_id: 'Rolle',
         admins_table_status: 'Status',
         admins_table_added: 'Hinzugefügt',
         admins_table_no_record_found: 'Kein Gerät gefunden',
         admins_table_row_per_page: 'Ergebnisse pro Seite',
         admins_no_user_selected_for_de_activation_message:
           'Wählen Sie Datensätze zum Deaktivieren aus',
         admins_bread_crumb_main_title: 'Admins',
         add_admins_bread_crumb_first_link: 'Admin hinzufügen',
         add_admins_form_header: 'Admin hinzufügen',
         edit_admins_bread_crumb_first_link: 'Admin ändern',
         edit_admins_form_header: 'Admin ändern',
         admins_form_first_name_field: 'Vorname',
         admins_form_last_name_field: 'Nachname',
         admins_form_email_field: 'E-Mail-Adresse',
         admins_form_official_email_field: 'E-Mail-Adresse',
         admins_form_phone_field: 'Telefonnummer',
         admins_form_official_phone_number_field: 'Telefonnummer',
         admins_form_password_field: 'Passwort',
         admins_form_role_id_field: 'Rolle',
         admins_form_select_role_id_field: 'Rolle auswählen',
         admins_form_street_number_field: 'Straße',
         admins_form_house_number_field: 'Hausnummer',
         admins_form_neighbourhood_field: 'Bezirk',
         admins_form_postal_code_field: 'Postleitzahl',
         admins_form_city_field: 'Stadt',
         admins_form_country_field: 'Land',
         admins_form_email_verification_field: 'E-Mail-Bestätigung',
         admins_form_email_verification_sub_title_field:
           'Wenn Sie diese Funktion ausschalten, wird automatisch eine Bestätigungs-E-Mail an den Administrator gesendet',
         admins_form_add_admin_button: 'Änderungen speichern',
         admins_form_edit_admin_button: 'Änderungen speichern',
         admins_form_cancel_button: 'Abbrechen',
         admins_form_submitting_admin_button: 'Verarbeiten...',
         admins_first_name_required_form_error: 'Vorname ist erforderlich',
         admins_last_name_required_form_error: 'Nachname ist erforderlich',
         admins_email_required_form_error: 'E-Mail- ist erforderlich',
         admins_email_valid_form_error: '',
         admins_password_required_form_error: 'Passwort ist erforderlich',
         admins_country_required_form_error: 'Land ist erforderlich',
         admins_role_id_required_form_error: 'Rolle ist erforderlich',
         admins_password_form_error:
           'Das Passwort muss mindestens 8 Zeichen enthalten, davon mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen.',
         admins_add_form_success: 'Administrator wurde erfolgreich erstellt!',
         admins_edit_form_success: 'Administrator wurde erfolgreich geändert!',
         admins_form_api_error: 'Interner Serverfehler',
         admins_form_email_error: 'Die E-Mail-Adresse ist bereits vorhanden',
         admins_no_record_found: 'Kein Administrator gefunden',
         admins_deactivated_message: 'Admin wurde erfolgreich deaktiviert',
         admins_activated_message: 'Administrator wurde erfolgreich aktiviert',
         admins_email_verified_title: 'E-Mail bestätigt',
         admins_email_verified_sub_title:
           'Wenn Sie diese Funktion ausschalten, wird automatisch eine Bestätigungs-E-Mail an den Administrator gesendet',
         admins_bulk_no_admin_is_selected_error_message:
           'Wählen Sie Datensätze zum Deaktivieren aus',
         admins_bulk_deactivated_message:
           '{{ USERS_COUNT }} Administrator(en) wurde(n) erfolgreich deaktiviert',
         // Zoeken Results
         search_result_title: 'Suchergebnisse: ',
         search_users: 'Benutzer',
         search_clients: 'Kunden',
         search_service_clients: 'Servicekunden',
         search_devices: 'Devices',
         search_presets: 'Voreinstellungen',
         search_projects: 'Projekte',
         // New Locales
         no_error_devices_found: 'Keine Devices mit Störungen gefunden!',
         project_single_first_bread_crumb: 'Projekte',
         project_single_second_bread_crumb: 'Projekt anzeigen',
         project_single_client_label: 'Kunde',
         project_single_service_client_label: 'Servicekunde',
         project_single_devices_label: 'Devices',
         project_single_edit_button: 'Ändern',
         project_single_devices_tab: 'Devices',
         client_single_view_more_errors_button: 'Mehr anzeigen',
         client_error_single_devices_tab: 'Alarm-Devices',
         client_error_single_device_name: 'Gerät',
         client_error_single_device_error_code: 'Alarmcode',
         client_error_single_device_error_date_time: 'Datum',
         devices_bulk_edit_registers_button: 'Einstellung(en) ändern',
         devices_bulk_edit_ota_button: 'Gerät(e) aktualisieren',
         client_error_single_device_errors_table_row_per_page:
           'Zeilen pro Seite',
         client_error_single_device_error_no_record_found:
           'Keine Devices gefunden',
         devices_bulk_edit_registers_title: 'Devicesregister Gruppenänderung',
         devices_bulk_edit_registers_form_submit_button: 'Massenaktualisierung',
         devices_bulk_edit_registers_form_cancel_button: 'Abbrechen',
         devices_bulk_edit_registers_form_success:
           'Nachricht wird an ausgewählte(s) Gerät(e) weitergeleitet',
         devices_bulk_edit_registers_form_error:
           'Bitte mindestens einen Registerwert eingeben',
         devices_bulk_edit_registers_form_api_error: 'Interner Serverfehler',
         devices_bulk_edit_ota_title: 'Gerät OTA-Gruppenaktualisierung',
         devices_bulk_edit_ota_form_firmware_field: 'Firmware',
         devices_bulk_edit_ota_form_submit_button: 'Gruppenaktualisierung',
         devices_bulk_edit_ota_form_cancel_button: 'Abbrechen',
         devices_bulk_edit_ota_form_success:
           'Nachricht wird an ausgewählte(s) Gerät(e) weitergeleitet',
         devices_bulk_edit_ota_form_firmware_required_error:
           'Firmware ist obligatorisch',
         devices_bulk_edit_ota_form_api_error: 'Interner Serverfehler',
         devices_bulk_edit_registers_selected_devices:
           'Devices für Gruppenbearbeitung ausgewählt',
         // Device Status Table Pop Over
         custom_popover_min_value: 'Min. Wert',
         custom_popover_max_value: 'Max. Wert',
         custom_popover_list_options: 'Mögliche Werte',
         custom_popover_short_description: 'Beschreibung',
         custom_popover_close_modal_button: 'Schließen',
         // Warmtapwater PopOver Short Omschrijving
         custom_popover_300_short_description: 'DHW-Sollwert',
         custom_popover_301_short_description: 'Vorheizmodus',
         custom_popover_302_short_description: 'Komfort-Sollwert',
         custom_popover_303_short_description: 'Durchflussrate Startschwelle',
         custom_popover_304_short_description: 'DHW aktivieren',
         // Thermal disinfection PopOver Short Omschrijving
         custom_popover_400_short_description: 'Aleg eingeschaltet',
         custom_popover_401_short_description: 'Aleg Sollwert',
         custom_popover_402_short_description: 'Aleg Brenndauer',
         custom_popover_403_short_description: 'Aleg Wartezeit',
         // TE-Booster PopOver Short Omschrijving
         custom_popover_1800_short_description: 'TE-Booster eingeschaltet',
         custom_popover_1804_short_description: 'TE-Booster Sollwert',
         custom_popover_1805_short_description: 'TE-Booster Sollwert Hysterese',
         custom_popover_1812_short_description: 'TE-Booster Sollwert Timeout',
         custom_popover_1813_short_description: 'TE-Booster Sollwert Timer',
         custom_popover_1815_short_description: 'TE-Booster Schwelle',
         custom_popover_1816_short_description:
           'TE-Booster angezapftes Volumen',
         // Heating PopOver Short Omschrijving
         custom_popover_500_short_description: 'CH Max. Sollwert',
         custom_popover_501_short_description: 'CH Min. Sollwert',
         custom_popover_506_short_description: 'Max. Primar Rücklauf',
         custom_popover_507_short_description:
           'Max. Primar Rücklaufprozentsatz',
         custom_popover_508_short_description: 'Delta Prim CH Sollwert',
         custom_popover_510_short_description: 'Max. Heiztemperatur',
         custom_popover_509_short_description: 'Delta Prim Min.',
         custom_popover_514_short_description: 'CH-Sollwert',
         custom_popover_515_short_description: 'CH aktivieren',
         // Cooling PopOver Short Omschrijving
         custom_popover_600_short_description: 'KühlTempSollwert',
         custom_popover_601_short_description: 'Kühl MaxHaupt Rücklauf',
         custom_popover_602_short_description:
           'Kühl % von Max. Sollwertkompensation',
         custom_popover_605_short_description: 'Max. Kühltemp',
         custom_popover_606_short_description: 'Kühlsollwert Min.',
         custom_popover_607_short_description: 'Kühlsollwert Max.',
         custom_popover_611_short_description: 'Kühlung aktivieren',
         // Secondary General PopOver Short Omschrijving
         custom_popover_512_short_description: 'CH Min. Druck',
         custom_popover_513_short_description: 'CH Max. Druck',
         custom_popover_603_short_description: 'KühlHeizWarteZeit',
         custom_popover_604_short_description: '6-Wege-Ventil Wartezeit',
         // Changeover WKW PopOver Short Omschrijving
         custom_popover_1850_short_description: '3,5-Rohrkonfiguration',
         custom_popover_1851_short_description: 'Erkennungsschwelle für Kühlen',
         custom_popover_1852_short_description: 'Erkennungsschwelle für Heizen',
         custom_popover_1853_short_description: 'Heizen abgesenkter Sollwert',
         custom_popover_1854_short_description:
           'Abgesenkter Sollwert verwendet',
         custom_popover_1855_short_description: 'Vorerkennung MCV2-Position',
         custom_popover_1856_short_description: 'Erkennungsintervall',
         custom_popover_1857_short_description: 'Erkennungsintervall Timer',
         custom_popover_1858_short_description:
           'Vorerkennung Zeitüberschreitung',
         custom_popover_1859_short_description: 'Vorerkennung Timer',
         custom_popover_1860_short_description: 'Konfigurationsschalter Timer',
         custom_popover_1861_short_description: 'Schalter Zeitüberschreitung',
         custom_popover_1862_short_description: 'Konfiguration Schaltschwelle',
         custom_popover_1863_short_description: 'Boost-Funktionsmodus',
         custom_popover_1864_short_description: 'Boost-Erkennung Timer',
         custom_popover_1865_short_description:
           'Boost-Erkennung Zeitüberschreitung',
         custom_popover_1866_short_description: 'Maximale Boost-Position',
         // Manual Operation PopOver Short Omschrijving
         custom_popover_700_short_description: 'Handbedienung aktiviert',
         custom_popover_701_short_description: 'MCV-1 Handbedienung',
         custom_popover_702_short_description: 'MCV-2 Handbedienung',
         custom_popover_703_short_description: 'Pumpe (P1) Handbedienung',
         custom_popover_704_short_description: 'Ventil1',
         custom_popover_706_short_description: 'Ausgabe Wärmebedarfsstatus',
         custom_popover_707_short_description: 'MCV-1 Handbedienung[%]',
         custom_popover_708_short_description: 'MCV-1 Handbedienung[%]',
         custom_popover_1801_short_description:
           'Elektrisches Element TE-Booster',
         custom_popover_709_short_description:
           'Handbedienung Zeitüberschreitung',
         // Warmtapwater PopOver Long Omschrijving
         custom_popover_300_long_description: 'DHW-Sollwert',
         custom_popover_301_long_description: 'Vorheizmodus',
         custom_popover_302_long_description: 'Komfort-Sollwert',
         custom_popover_303_long_description: 'Durchflussrate Startschwelle',
         custom_popover_304_long_description: 'DHW aktivieren',
         // Thermal disinfection PopOver Long Omschrijving
         custom_popover_400_long_description: 'Aleg eingeschaltet',
         custom_popover_401_long_description: 'Aleg Sollwert',
         custom_popover_402_long_description: 'Aleg Brenndauer',
         custom_popover_403_long_description: 'Aleg Wartezeit',
         // TE-Booster PopOver Long Omschrijving
         custom_popover_1800_long_description: 'TE-Booster eingeschaltet',
         custom_popover_1804_long_description: 'TE-Booster Sollwert',
         custom_popover_1805_long_description: 'TE-Booster Sollwert Hysterese',
         custom_popover_1812_long_description: 'TE-Booster Sollwert Timeout',
         custom_popover_1813_long_description: 'TE-Booster Sollwert Timer',
         custom_popover_1815_long_description: 'TE-Booster Schwelle',
         custom_popover_1816_long_description: 'TE-Booster angezapftes Volumen',
         // Heating PopOver Long Omschrijving
         custom_popover_500_long_description: 'CH Max. Sollwert',
         custom_popover_501_long_description: 'CH Min. Sollwert',
         custom_popover_506_long_description: 'Max. Primar Rücklauf',
         custom_popover_507_long_description: 'Max. Primar Rücklaufprozentsatz',
         custom_popover_508_long_description: 'Delta Prim CH Sollwert',
         custom_popover_510_long_description: 'Max. Heiztemperatur',
         custom_popover_509_long_description: 'Delta Prim Min.',
         custom_popover_514_long_description: 'CH-Sollwert',
         custom_popover_515_long_description: 'CH aktivieren',
         // Cooling PopOver Long Omschrijving
         custom_popover_600_long_description: 'KühlTempSollwert',
         custom_popover_601_long_description: 'Kühl MaxHaupt Rücklauf',
         custom_popover_602_long_description:
           'Kühl % von Max. Sollwertkompensation',
         custom_popover_605_long_description: 'Max. Kühltemp',
         custom_popover_606_long_description: 'Kühlsollwert Min.',
         custom_popover_607_long_description: 'Kühlsollwert Max.',
         custom_popover_611_long_description: 'Kühlung aktivieren',
         // Secondary General PopOver Long Omschrijving
         custom_popover_512_long_description: 'CH Min. Druck',
         custom_popover_513_long_description: 'CH Max. Druck',
         custom_popover_603_long_description: 'KühlHeizWarteZeit',
         custom_popover_604_long_description: '6-Wege-Ventil Wartezeit',
         // Changeover WKW PopOver Long Omschrijving
         custom_popover_1850_long_description: '3,5-Rohrkonfiguration',
         custom_popover_1851_long_description: 'Erkennungsschwelle für Kühlen',
         custom_popover_1852_long_description: 'Erkennungsschwelle für Heizen',
         custom_popover_1853_long_description: 'Heizen abgesenkter Sollwert',
         custom_popover_1854_long_description: 'Abgesenkter Sollwert verwendet',
         custom_popover_1855_long_description: 'Vorerkennung MCV2-Position',
         custom_popover_1856_long_description: 'Erkennungsintervall',
         custom_popover_1857_long_description: 'Erkennungsintervall Timer',
         custom_popover_1858_long_description:
           'Vorerkennung Zeitüberschreitung',
         custom_popover_1859_long_description: 'Vorerkennung Timer',
         custom_popover_1860_long_description: 'Konfigurationsschalter Timer',
         custom_popover_1861_long_description: 'Schalter Zeitüberschreitung',
         custom_popover_1862_long_description: 'Konfiguration Schaltschwelle',
         custom_popover_1863_long_description: 'Boost-Funktionsmodus',
         custom_popover_1864_long_description: 'Boost-Erkennung Timer',
         custom_popover_1865_long_description:
           'Boost-Erkennung Zeitüberschreitung',
         custom_popover_1866_long_description: 'Maximale Boost-Position',
         // Manual Operation PopOver Long Omschrijving
         custom_popover_700_long_description: 'Handbedienung aktiviert',
         custom_popover_701_long_description: 'MCV-1 Handbedienung',
         custom_popover_702_long_description: 'MCV-2 Handbedienung',
         custom_popover_703_long_description: 'Pumpe (P1) Handbedienung',
         custom_popover_704_long_description: 'Ventil1',
         custom_popover_706_long_description: 'Ausgabe Wärmebedarfsstatus',
         custom_popover_707_long_description: 'MCV-1 Handbedienung[%]',
         custom_popover_708_long_description: 'MCV-1 Handbedienung[%]',
         custom_popover_1801_long_description:
           'Elektrisches Element TE-Booster',
         custom_popover_709_long_description:
           'Handbedienung Zeitüberschreitung',
         // API keys
         client_single_api_key_approve_api_key_label: 'API-Keys',
         client_single_api_key_app_id_label: 'APP-ID',
         client_single_api_key_approve_api_key_button: 'Benutzerkonto zulassen',
         client_single_api_key_suspend_api_key_button:
           'Aussetzen des API-Zugangs',
         client_single_api_key_unblock_application_button: 'API entsperren',
         client_single_api_key_generate_api_key_button:
           'API-Schlüssel erzeugen',
         client_single_api_key_generate_api_key_success:
           'API-Schlüssel wurde erfolgreich erzeugt',
         client_single_api_key_generate_api_limit_error:
           'Leider können wir keinen neuen API-Schlüssel erzeugen, da das Limit für die Neugenerierung erreicht wurde.',
         client_single_api_key_generate_api_3scale_error:
           '3Scale-API-Fehler Wenden Sie sich an den Administrator.',
         client_single_api_application_suspend_success:
           '3Scale-Anwendung wurde erfolgreich gesperrt',
         client_single_api_application_unblock_success:
           '3Scale-Anwendung wurde erfolgreich entsperrt',
         client_single_api_application_suspend_error:
           'Anwendung wurde bereits gesperrt',
         client_single_api_application_unblock_errpr:
           'Anwendung wurde bereits entsperrt',
         client_single_api_key_account_pending_approval:
           'Ihr Benutzerkonto wurde noch nicht genehmigt. Wenden Sie sich an den Administrator.',
         client_single_api_key_account_suspended:
           'Ihr Benutzerkonto wurde gesperrt. Wenden Sie sich für weitere Informationen an den Administrator.',
         // Clients Form
         three_scale_temporary_password_subject:
           'Fortes Data Management – 3Scale-Benutzerkonto erstellt',
         clients_form_password_field: 'Passwort',
         clients_password_required_form_error:
           'Das Passwort muss mindestens Großbuchstaben, Zahlen und Sonderzeichen enthalten.',
         // Devices Form
         devices_form_mbus_meters_are_not_connected:
           'Energie-Zähler sind nicht angeschlossen.',
         MBUS_METER_HEATING: 'Wärmezähler',
         MBUS_METER_COOLING: 'Kältezähler',
         MBUS_METER_ELECTRICITY: 'Stromzähler',
         MBUS_METER_WATER: 'Wasserzähler',
         MBUS_METER_BI_DIRECTIONAL: 'Wärme-/Kältezähler',
         meter_enabled: 'Eingeschaltet',
         meter_disabled: 'Ausgeschaltet',
         devices_mbus_registers_toggle_register_status_success:
           'Die Nachricht wurde erfolgreich an das Gerät gesendet.',
         MBUS_METER_SERIAL_NUMBER: 'Seriennummer',
         MBUS_METER_MANUFACTURER: 'Hersteller',
         MBUS_METER_METER_TYPE: 'Zählertyp',
         MBUS_METER_STATUS: 'Status',
         MBUS_METER_FLOW: 'Volumenstrom',
         MBUS_METER_POWER: 'Leistung',
         MBUS_METER_SUPPLY_TEMPERATURE: 'Vorlauftemperatur',
         MBUS_METER_RETURN_TEMPERATURE: 'Rücklauftemperatur',
         MBUS_METER_HEATING_ENERGY: 'Gesamtenergieverbrauch (Wärme)',
         MBUS_METER_ELECTRICITY_ENERGY: 'Gesamtenergieverbrauch (Strom)',
         MBUS_METER_VOLUME: 'Gesamtvolumen',
         MBUS_METER_COOLING_ENERGY: 'Gesamtenergieverbrauch (Kälte)',
         MBUS_METER_CURRENT: 'Spannung',
         custom_popover_MBUS_METER_SERIAL_NUMBER_short_description:
           'Seriennummer',
         custom_popover_MBUS_METER_MANUFACTURER_short_description: 'Hersteller',
         custom_popover_MBUS_METER_METER_TYPE_short_description: 'Zählertyp',
         custom_popover_MBUS_METER_STATUS_short_description: 'Status',
         custom_popover_MBUS_METER_FLOW_short_description: 'Volumenstrom',
         custom_popover_MBUS_METER_POWER_short_description: 'Leistung',
         custom_popover_MBUS_METER_SUPPLY_TEMPERATURE_short_description:
           'Vorlauftemperatur',
         custom_popover_MBUS_METER_RETURN_TEMPERATURE_short_description:
           'Rücklauftemperatur',
         custom_popover_MBUS_METER_HEATING_ENERGY_short_description:
           'Gesamtenergieverbrauch (Wärme)',
         custom_popover_MBUS_METER_ELECTRICITY_ENERGY_short_description:
           'Gesamtenergieverbrauch (Strom)',
         custom_popover_MBUS_METER_VOLUME_short_description: 'Gesamtvolumen',
         custom_popover_MBUS_METER_COOLING_ENERGY_short_description:
           'Gesamtenergieverbrauch (Kälte)',
         custom_popover_MBUS_METER_CURRENT_short_description: 'Spannung',
         // Device Set Logs Per Device
         device_single_device_registers_tab: 'Protokoll einstellen',
         device_single_device_registers_menu_list: JSON.stringify([
           {
             key: 'operating_state',
             value: 'Betriebszustand',
             permission: 'operating-state-tab-read',
           },
         ]),
         device_status_interval: 'Intervall',
         device_status_interval_in_seconds: 'Intervall in Sekunden',
         device_single_set_registers_error:
           'Wählen Sie mindestens ein Register, um fortzufahren.',
         device_single_set_registers_success:
           'Die Nachricht wurde erfolgreich an das Gerät gesendet.',
         device_single_set_register_form_interval_required_error:
           'Intervall (Sekunden) ist erforderlich',
         device_single_set_register_form_interval_numeric_error:
           'Intervall (Sekunden) muss eine Zahl sein',
         device_single_set_register_form_send_button: 'Einstellen',
         device_single_set_register_form_cancel_button: 'Abbrechen',
         clients_3scale_email_error: 'E-Mail existiert bereits in 3Scale',
         page_title_appending_title: ' | Fortes Data Management',
         project_single_device_registers_tab: 'Protokoll einstellen',
         audits_user: 'Benutzer',
         audits_system_generated: 'Anwendung',
         device_single_project_set_registers_error:
           'Nachricht wird nicht weitergeleitet, weil keine Devices mit dem Projekt verknüpft sind',
         audits_user_filter_label: 'Benutzer',
         audits_event_type_field: 'Ereignistyp',
         audits_select_event_type_field: 'Ereignistyp auswählen',
         audits_select_event_type_incoming_option: 'Eingehend',
         audits_select_event_type_outgoing_option: 'Ausgehend',
         audits_select_event_type_system_generated_option: 'Anwendung',
         audits_table_loading: 'Ereignis laden',
         appliance_type_reference_table_appliance_name: 'Name',
         appliance_type_reference_table_appliance_type: 'Devicestyp',
         appliance_type_reference_table_updated_at: 'Geändert am',
         sidebar_appliance_type_reference_menu: 'Devicestyp',
         appliance_types_register_form_send_button: 'Speichern',
         appliance_types_register_form_cancel_button: 'Abbrechen',
         appliance_types: 'Devicestypen',
         appliance_types_table_no_record_found: 'Kein Gerät gefunden',
         appliance_types_table_row_per_page: 'Ergebnisse pro Seite',
         add_appliance_type: 'Devicestyp hinzufügen',
         add_appliance_types_form_header: 'Devices Typ hinzufügen',
         edit_appliance_types_form_header: 'Devicestyp ändern',
         appliance_types_add_new: 'Hinzufügen',
         appliance_type_form_name_label: 'Name',
         appliance_type_form_type_label: 'Devicestyp',
         appliance_type_form_select_type_label: 'Devicestyp auswählen',
         appliance_name_form_required_error: 'Name ist erforderlich',
         appliance_type_form_required_error: 'Devicestyp ist erforderlich',
         add_appliance_type_form_success:
           'Devicestypdatensatz wurde erfolgreich erstellt',
         add_appliance_type_form_api_error:
           'Interner Server Devicestyp hinzufügen – API-Fehler',
         edit_appliance_type_form_success:
           'Devicestypdatensatz wurde erfolgreich geändert',
         edit_appliance_type_form_api_error:
           'Interner Server Devicestyp ändern – API-Fehler',
         appliance_type_reference_table_filter_reset_button: 'Zurücksetzen',
         appliance_type_reference_table_search: 'Suchen',
         appliance_type_reference_status_menu_list: JSON.stringify([
           {
             key: 'operating_state',
             value: 'Betriebszustand',
             permission: 'operating-state-tab-read',
           },
           {
             key: 'settings_hot_water',
             value: 'Einstellungen Warmwasser',
             permission: 'settings-hot-water-tab-read',
           },
           {
             key: 'settings_heating_and_cooling',
             value: 'Einstellungen heizen und kühlen',
             permission: 'settings-heating-and-cooling-tab-read',
           },
           {
             key: 'manual_control',
             value: 'Handbedienung',
             permission: 'manual-control-tab-read',
           },
         ]),
         audits_filter_button: 'Filter',
         device_single_no_registers_are_available:
           'Register sind nicht verfügbar',
         device_single_device_diagnostics_tab: 'Diagnose',
         device_single_dp_measurement_back_button: 'Vorherige',
         device_single_diagnostic_next_button: 'Nächste',
         device_single_dp_measurement_start_button: 'Start',
         device_single_diagnostict_finish_button: 'Ende',
         device_single_diagnostic_stop_button: 'Stopp',
         device_single_dp_measurement_refresh_button: 'Aktualisieren',
         device_single_dp_measurement_event_logs_title: 'Ergebnisse',
         device_single_dp_measurement_select_mbus_meter_button:
           'M-Bus-Zähler auswählen',
         device_single_dp_measurement_step_1_title:
           'Druckdifferenzberechnung – Durchfluss wählen',
         device_single_dp_measurement_pressure_difference_stepper_1_title:
           'Durchfluss wählen',
         device_single_dp_measurement_step_1_flow_1_label:
           'Druckdifferenz berechnen',
         device_single_dp_measurement_step_1_flow_2_label: 'Warmwasser',
         device_single_dp_measurement_pressure_difference_step_2_title:
           'Druckdifferenzberechnung – Erläuterung des Assistenten',
         device_single_dp_measurement_pressure_difference_stepper_2_title:
           'Erläuterung des Assistenten',
         device_single_dp_measurement_pressure_difference_step_2_description:
           'Die vorhandene Druckdifferenz ist bei kollektiven Netzen immer ein Problem. Mit der leistungsstarken Kombination von AcquaHeat Übergabestationen und der Fortes Data Management Plattform können Sie über ein benutzerfreundliches Modul einfach eine Druckdifferenzberechnung an jeder Stelle in ihrem kollektiven Netzwerk durchführen. Dies gibt Ihnen einen hervorragenden ersten Anhaltspunkt für eine mögliche Ursache. Mit dieser Diagnose ermöglichen wir Ihnen, einen ersten Hinweis auf die vorhandene Druckdifferenz berechnen zu lassen. <br /><br /><strong>Erforderlich: Wärmezähler muss an das Mercurius Kommunikationsmodul angeschlossen sein</strong>',
         device_single_dp_measurement_pressure_difference_step_3_title:
           'Druckdifferenzberechnung – Ansteuern der richtigen Komponenten',
         device_single_dp_measurement_pressure_difference_stepper_3_title:
           'Handbedienung Register',
         device_single_dp_measurement_step_3_pressure_difference_output_1:
           'Feststellung 1 – Einschalten der Handbedienung',
         device_single_dp_measurement_pressure_difference_output_2:
           'Feststellung 2 – Öffnen des Regelventils',
         device_single_dp_measurement_pressure_difference_step_3_mbus_meter_title:
           'Wählen Sie den M-Bus-Zähler (bei mehreren Zählern handelt es sich um den Energiezähler, der das Warmwasser registriert).',
         device_single_dp_measurement_pressure_difference_stepper_4_title:
           'M-Bus-Zähler wählen',
         device_single_dp_measurement_pressure_difference_step_4_flow_label:
           'Volumenstrom',
         device_single_dp_measurement_pressure_difference_stepper_5_title:
           'Berechnung der Druckdifferenz',
         device_single_dp_measurement_pressure_difference_output_3:
           'Feststellung 3 – Zähler wählen',
         device_single_dp_measurement_pressure_difference_output_4:
           'Feststellung 4 – Berechnung des Differenzdrucks',
         device_single_dp_measurement_pressure_difference_stepper_6_title:
           'Ergebnis',
         device_single_dp_measurement_pressure_difference_step_6_description:
           'Ihre Druckdifferenz ist in Ordnung! Es sind keine weiteren Maßnahmen erforderlich. ',
         device_single_dp_measurement_download_button:
           'Ergebnisse herunterladen',
         device_single_dp_measurement_pressure_difference_step_7_description:
           'Die Druckdifferenzberechnung wurde erfolgreich abgeschlossen.',
         device_single_general_title: 'Allgemeines',
         device_single_hotwater_title: 'Warmwasser',
         device_single_dp_measurement_hot_water_flow_1_stepper_1_title:
           'Durchfluss wählen',
         device_single_dp_measurement_hot_water_flow_1_stepper_2_title:
           'Erläuterung des Assistenten',
         device_single_dp_measurement_hot_water_flow_1_stepper_3_title:
           'Wasserhahn öffnen',
         device_single_dp_measurement_hot_water_flow_1_stepper_4_title:
           'Wasserhahn und Warmwasserventil prüfen',
         device_single_dp_measurement_hot_water_flow_1_stepper_5_title:
           'Energie Zähler',
         device_single_dp_measurement_hot_water_flow_1_stepper_6_title:
           'Berechnung des Warmwasserdurchflusses',
         device_single_dp_measurement_hot_water_flow_1_stepper_7_title:
           'Ergebnisse',
         device_single_dp_measurement_hot_water_flow_1_step_2_title:
           'DP-Messung – Erklärung des Durchflusses',
         device_single_dp_measurement_hot_water_flow_1_step_2_description:
           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu proin amet vitae etiam. In mauris habitasse turpis quis tortor, integer magna dictum condimentum. In pellentesque proin eu ut viverra quam. Consequat pharetra proin lacus, ultrices enim. Tincidunt porta tortor lacinia varius mattis pellentesque. In augue sit vitae leo sed. Nibh sed mi bibendum convallis viverra vestibulum pretium commodo. Nibh quam vulputate malesuada sodales. Curabitur nisl leo quis sed vitae cras. Tempor risus, a volutpat consequat eget faucibus nulla fermentum. Ut dignissim proin nulla et congue mattis. Euismod et ipsum quis habitant in enim viverra. Mattis varius quis arcu cras vestibulum nunc mattis consectetur in. Tempus mauris egestas elit malesuada ullamcorper aliquet cras adipiscing ',
         device_single_dp_measurement_hot_water_flow_1_step_3_title:
           'Wasserhahn öffnen',
         device_single_dp_measurement_hot_water_flow_1_step_3_faucet_error:
           'Durchflusssensor defekt',
         device_single_dp_measurement_hot_water_flow_1_step_3_faucet_error_one_try_left:
           'Versuchen Sie es noch einmal',
         diagnostic_flows_form_open_faucet_title:
           'Wasserhahn wurde vom Kunden geöffnet',
         diagnostic_flows_form_open_faucet_off_option_description:
           'Wasserhahn wurde vom Kunden nicht geöffnet',
         device_single_dp_measurement_hot_water_flow_1_output_1:
           'Ausgang 1 – Warmwasserzustand',
         device_single_dp_measurement_hot_water_flow_1_output_2:
           'Ausgang 2 – Warmwasserventil (M1)',
         device_single_dp_measurement_hot_water_flow_1_step_3_mbus_meter_title:
           'Energie-Zähler wählen',
         device_single_dp_measurement_hot_water_flow_1_step_4_flow_label:
           'Durchfluss',
         device_single_dp_measurement_step_4_hot_water_flow_1_output_3:
           'Ausgang 3 – Zähler auswählen',
         device_single_dp_measurement_hot_water_flow_1_output_4:
           'Ausgang 4 – MBus-Durchfluss',
         device_single_dp_measurement_hot_water_flow_1_output_5:
           'Ausgang 5 – Berechnung des Warmwasserdurchflusses',
         device_single_dp_measurement_hot_water_flow_1_step_7_description:
           'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Eu proin amet vitae etiam. In mauris habitasse turpis quis tortor, integer magna dictum condimentum. In pellentesque proin eu ut viverra quam. Consequat pharetra proin lacus, ultrices enim. Tincidunt porta tortor lacinia varius mattis pellentesque. In augue sit vitae leo sed. Nibh sed mi bibendum convallis viverra vestibulum pretium commodo. Nibh quam vulputate malesuada sodales. Curabitur nisl leo quis sed vitae cras. Tempor risus, a volutpat consequat eget faucibus nulla fermentum. Ut dignissim proin nulla et congue mattis. Euismod et ipsum quis habitant in enim viverra. Mattis varius quis arcu cras vestibulum nunc mattis consectetur in. Tempus mauris egestas elit malesuada ullamcorper aliquet cras adipiscing ',
         device_single_dp_measurement_hot_water_flow_1_step_8_description:
           'Warmwasser – Durchfluss 1 Diagnose wurde abgeschlossen',
         device_single_heating_title: 'Heizung',
         device_single_dp_measurement_step_1_flow_3_label: 'Durchfluss 1',
         device_single_cooling_title: 'Kühlung',
         device_single_dp_measurement_step_1_flow_4_label: 'Durchfluss 1',
         device_single_temperature_graph_loading_data: 'Grafik wird geladen',
         device_single_temperature_no_graph_data_error:
           'Die Grafik ist nicht verfügbar',
         device_graph_week_filter: 'W',
         device_graph_weeks_filter: 'W',
         device_graph_day_filter: 'D',
         device_graph_days_filter: 'D',
         device_graph_hours_filter: 'H',
         device_single_registers_table_numeric_error:
           'Alle numerischen Werte sind zulässig',
         device_single_registers_table_min_max_error:
           'Wert muss zwischen Mindest- und Höchstwert liegen',
         MBUS_METER_TYPE_WATER: 'Wasser',
         MBUS_METER_TYPE_ELECTRICITY: 'Elektrizität',
         MBUS_METER_TYPE_HEATING: 'Wärme',
         MBUS_METER_TYPE_BIDIRECTIONAL: 'Wärme/Kälte',
         MBUS_METER_TYPE_COOLING: 'Kälte',
         UNKNOWN_MANUFACTURER: 'Unbekannter Hersteller',
         commissoning_form_header: 'Inbetriebnahme',
         commissoning_form_serial_number_scan_button: 'Scan mit Kamera',
         commissoning_form_heat_interface_unit_scan_button:
           'Scan Wärme-Interface-Einheit',
         commissoning_device_submit_button: 'Bereit',
         commissoning_form_search_field: 'Seriennummer Mercurius',
         sidebar_commissioning_overview: 'Inbetriebnahme',
         sidebar_commissioning: 'Inbetriebnahme',
         commissoning_form_success: 'Die Inbetriebnahme war erfolgreich!',
         commissoning_error_message: 'Device wurde nicht gefunden',
         swapping_form_header: 'Device austauschen',
         swapping_old_device_title: 'Altes Device (Seriennummer)',
         swapping_new_device_title: 'Neues Device (Seriennummer)',
         swapping_form_scan_button: 'Scan',
         swapping_form_search_field: 'Seriennummer Mercurius',
         sidebar_swapping_overview: 'Inbetriebnahme',
         sidebar_swapping: 'Austausch',
         swapping_device_submit_button: 'Bereit',
         swapping_form_success: 'Device wurde erfolgreich angepasst!',
         swapping_old_device_error_message: 'Altes Device wurde nicht gefunden',
         swapping_new_device_error_message: 'Neues Device wurde nicht gefunden',
         email_device_swapped_by: 'Device ausgetauscht durch:',
         swapping_device_unique_error: 'Device ist bereits vorhanden',
         clear_device_form_header: 'Löschen',
         clear_device_form_scan_button: 'Scan',
         clear_device_form_search_field: 'Seriennummer Mercurius',
         sidebar_clear_device_overview: 'Device löschen',
         sidebar_clear_device: 'Löschen',
         clear_device_form_success: 'Device wurde erfolgreich aktualisiert!',
         clear_device_error_message: 'Device wurde nicht gefunden',
         clear_device_submit_button: 'Device löschen',
         clear_device_audit_message: 'Device wurde gelöscht',
         device_single_swapped_devices_tab: 'Ausgetauschte Devices',
         devices_single_swapped_old_device_name: 'Swapped From (Old)',
         devices_single_swapped_device_old_firmware: 'Old Firmware',
         devices_single_swapped_device_new_firmware: 'New Firmware',
         devices_single_swapped_old_device_type: 'Old Device type',
         devices_single_swapped_new_device_type: 'New Device Type',
         devices_single_swapped_device_old_box_serial_number:
           'Old Serial number HIU',
          devices_single_swapped_device_new_box_serial_number:
           'Serial Number HIU',
         devices_single_swapped_device_old_sim_card_number: 'Old Sim Number',
         devices_single_swapped_device_new_sim_card_number: 'New Sim Number',
         devices_single_swapped_device_created_at: 'Swapped Date',
         devices_single_swapped_device_name_search: 'Search',
         devices_single_swapped_new_device_name: 'Swapped To (New)',
         devices_single_swapped_device_table_no_record_found: 'No record found',
         devices_single_swapped_device_table_loading: 'Laden',
         devices_single_swapped_device_table_row_per_page:
           'Ergebnisse pro Seite',
         sidebar_swapped_devices_overview: 'Ausgetauschte Devices',
         sidebar_swapped_devices: 'Ausgetauschte Devices',
         swapped_devices_form_header: 'Ausgetauschte Devices',
         devices_bulk_edit_registers_form_no_devices_error:
           'Wählen Sie Devices aus, um fortzufahren.',
         devices_bulk_edit_firmware_modal_title:
           'Sind Sie sicher, dass Sie eine Firmware-Aktualisierung durchführen wollen?',
         devices_bulk_edit_firmware_modal_subtitle:
           'Wollen Sie die Firmwareversion ##firmware_value## zusammen für ##total_devices## Devices durchführen?',
         devices_bulk_edit_registers_modal_title:
           'Sind Sie sicher, dass Sie die Gruppenaktion durchführen wollen?',
         devices_bulk_edit_registers_modal_subtitle:
           'Wollen Sie die Register von ##total_devices## Devices auf einmal aktualisieren?',
         devices_bulk_edit_registers_modal_cancel_button: 'Abbrechen',
         devices_bulk_edit_registers_modal_submit_button: 'Bestätigen',
         devices_bulk_edit_firmware_modal_cancel_button: 'Abbrechen',
         devices_bulk_edit_firmware_modal_submit_button: 'Bestätigen',
         devices_bulk_export_button: 'Devices exportieren',
         projects_bulk_export_button: 'Projekte exportieren',
         project_devices_bulk_export_button: 'Projekte exportieren',
         devices_bulk_export_file_name: 'Devices Massenexport',
         projects_bulk_export_file_name: 'Projekt Massenexport',
         project_devices_bulk_export_file_name: 'Devices Massenexport',
         sidebar_reporting_overview: 'Berichte',
         sidebar_bulk_edit_ota_reporting: 'Aktualisierungen',
         sidebar_bulk_reporting_registers_table_title: 'Gruppenänderungen',
         edit_ota_reporting: 'Berichte aktualisieren',
         reporting_ota_all: 'Alle',
         reporting_ota_success: 'Erfolgreich',
         reporting_ota_failure: 'Fehlgeschlagen',
         reporting_ota_bulk_export_button: 'Exportieren',
         reporting_ota_filter_reset_button: 'Zurücksetzen',
         reporting_ota_table_filter_search: 'Suchen',
         reporting_ota_table_updated_at: 'Datum',
         reporting_ota_table_total_devices: 'Gesamtzahl Devices',
         reporting_ota_table_user: 'Benutzer',
         reporting_ota_table_message: 'Bemerkung',
         reporting_registers_table_user: 'Benutzer',
         reporting_registers_table_message: 'Bemerkung',
         reporting_ota_table_no_record_found: 'Keine Suchergebnisse gefunden',
         reporting_ota_table_row_per_page: 'Ergebnisse pro Seite',
         edit_ota_reporting_details: 'OTA Audit-Details',
         reporting_ota_details_all_tab: 'Alle',
         reporting_ota_details_pending_tab: 'Beschäftigt',
         reporting_ota_details_success_tab: 'Erfolgreich',
         reporting_ota_details_failure_tab: 'Fehlgeschlagen',
         reporting_ota_details_bulk_export_button: 'Exportieren',
         reporting_ota_details_filter_reset_button: 'Zurücksetzen',
         reporting_ota_details_table_filter_search: 'Suchen',
         reporting_ota_details_message: 'Firmwareversion aktualisiert auf',
         reporting_ota_details_table_created_at: 'Datum',
         reporting_ota_details_table_device: 'Gerät',
         reporting_ota_details_table_user: 'Benutzer',
         reporting_ota_details_table_old_firmware_version:
           'Alte Firmwareversion',
         reporting_ota_details_table_current_firmware_version:
           'Aktuelle Firmwareversion',
         reporting_ota_details_table_current_status: 'Status',
         reporting_ota_details_table_message: 'Nachricht',
         reporting_ota_details_table_no_record_found:
           'Keine Suchergebnisse gefunden',
         reporting_ota_details_table_row_per_page: 'Ergebnisse pro Seite',
         reporting_ota_bulk_export_file_name: 'OTA-Bericht',
         reporting_ota_details_bulk_export_file_name:
           'Bericht Massen-OTA-Details',
         devices_single_swapped_bulk_export_button_file_name:
           'Bericht ausgetauschte Devices',
         devices_single_swapped_bulk_export_button: 'Exportieren',
         reporting_registers_all: 'Alle',
         reporting_registers_success: 'Erfolgreich',
         reporting_registers_failure: 'Fehlgeschlagen',
         reporting_registers_table_created_at: 'Datum',
         reporting_registers_table_total_devices: 'Gesamtzahl Devices',
         reporting_registers_bulk_export_button: 'Exportieren',
         reporting_registers_filter_reset_button: 'Zurücksetzen',
         reporting_registers_table_filter_search: 'Suchen',
         reporting_registers_table_no_record_found:
           'Keine Suchergebnisse gefunden',
         reporting_registers_table_row_per_page: 'Ergebnisse pro Seite',
         reporting_registers_table_title: 'Berichte Gruppenänderungen',
         reporting_registers_table_bulk_export_file_name:
           'Bericht Massenregister',
         registers_reporting_details_title: 'Details Gruppenänderungen',
         reporting_registers_details_bulk_export_button: 'Exportieren',
         reporting_registers_details_filter_reset_button: 'Zurücksetzen',
         reporting_registers_details_table_filter_search: 'Suchen',
         reporting_registers_details_table_created_at: 'Datum',
         reporting_registers_details_table_device: 'Gerät',
         reporting_registers_details_table_user: 'Benutzer',
         reporting_registers_details_table_message: 'Nachricht',
         reporting_registers_details_table_failed_message:
           'Firmwareversion wurde nicht aktualisiert auf',
         reporting_registers_details_table_pending_message:
           'Firmwareversion aktualisieren auf',
         reporting_registers_details_table_successful_message:
           'Firmwareversion wurde aktualisiert auf',
         reporting_registers_details_table_no_record_found:
           'Keine Suchergebnisse gefunden',
         reporting_registers_details_table_row_per_page: 'Ergebnisse pro Seite',
         reporting_registers_details_bulk_export_file_name:
           'Bericht Massen-Registerdetails',
         devices_bulk_edit_ota_form_firmware_field_select_label:
           'Firmware auswählen',
         dashboard_users_overview: 'Benutzer Übersicht',
         dashboard_users_title: 'Benutzer',
         dashboard_devices_overview: 'Device Übersicht',
         dashboard_devices_title: 'Devices',
         dashboard_commissioning_overview: 'Inbetriebnahme',
         dashboard_commissioning_title: 'Inbetriebnahme',
         devices_filters: 'Filter:',
         devices_submit_button: 'Bereit',
         device_single_device_documentation_tab: 'Dokumente',
         device_single_documentation_brochure_button: 'Broschüre',
         device_single_documentation_installation_manual_button:
           'Benutzer-/Montagehandbuch',
         device_single_documentation_dimensional_drawing_button: 'Maßzeichnung',
         device_single_documentation_parts_drawing_button: 'Teilezeichnung',
         device_single_documentation_flow_direction_button: 'Strömungsrichtung',
         device_single_documentation_principle_diagram_button:
           'Grundsatzschema',
         device_single_documentation_information_end_user_button:
           'Information Endbenutzer',
         device_single_documentation_malfunction_key_button:
           'Störungsschlüssel',
         reporting_ota_failed_message:
           'Firmwareversion wurde nicht aktualisiert auf',
         reporting_ota_pending_message: 'Firmwareversion aktualisieren auf',
         reporting_ota_successful_message: 'Firmwareversion aktualisiert auf',
         ota_pending: 'Ausstehend',
         ota_success: 'Erfolgreich',
         ota_failed: 'Fehlgeschlagen',
         clear_devices_modal_title: 'Gerät löschen',
         clear_devices_modal_subtitle:
           'Sind Sie sicher, dass Sie das Gerät mit der Seriennummer <strong>##serial_number##</strong> löschen möchten?',
         clear_devices_modal_cancel_button: 'Abbrechen',
         clear_devices_modal_submit_button: 'Gerät löschen',
         sidebar_fortes_roles: 'Rollen',
         sidebar_fortes_permissions: 'Berechtigung',
         roles_all: 'Alle',
         roles_active: 'Aktiv',
         roles_inactive: 'INAKTIV',
         roles_filter_delete_button: 'Entfernen',
         roles_table_identifier: 'Kennung',
         roles_table_name_en: 'Name (EN)',
         roles_table_name_nl: 'Name (NL)',
         roles_table_description_en: 'Beschreibung (EN)',
         roles_table_description_nl: 'Beschreibung (NL)',
         roles_table_status: 'Status',
         roles_table_updated_at: 'Aktualisiert am',
         roles_filter_search: 'Suchen',
         roles_no_record_found: 'Keine Ergebnisse gefunden',
         roles_table_row_per_page: 'Ergebnisse pro Seite',
         roles_bulk_no_record_is_selected_error_message:
           'Wählen Sie Devices zur Gruppendeaktivierung aus.',
         roles_deactivated_message: 'Rolle wurde erfolgreich deaktiviert',
         roles_activated_message: 'Rolle wurde erfolgreich aktiviert',
         roles_bulk_deactivated_message:
           '##ROLES_COUNT## Rollen wurden erfolgreich deaktiviert',
         roles_title: 'Rollen',
         roles_add_new: 'Rolle hinzufügen',
         roles_bread_crumb_main_title: 'Rollen',
         add_roles_bread_crumb_first_link: 'Hinzufügen',
         edit_roles_bread_crumb_first_link: 'Rolle ändern',
         add_roles_form_header: 'Rolle hinzufügen',
         edit_roles_form_header: 'Rolle ändern',
         roles_form_role_identifier_field: 'Kennung',
         roles_identifier_required_form_error: 'Kennung ist erforderlich',
         roles_form_api_error: 'Interner Serverfehler',
         roles_form_unqiue_role_name_error: 'Kennung muss eindeutig sein',
         roles_form_api_forbidden:
           'Benutzer hat keine Berechtigung zum Erstellen einer Rolle',
         roles_add_form_success: 'Rolle wurde erfolgreich erstellt',
         roles_edit_form_success: 'Rolle wurde erfolgreich angepasst',
         roles_form_add_role_button: 'Rolle hinzufügen',
         roles_form_edit_role_button: 'Rolle ändern',
         roles_form_cancel_button: 'Abbrechen',
         roles_form_permissions_title: 'Berechtigungen',
         sidebar_fortes_modules: 'Module',
         modules_all: 'Alle',
         modules_active: 'Aktiv',
         modules_inactive: 'INAKTIV',
         modules_filter_delete_button: 'Entfernen',
         modules_table_identifier: 'Kennung',
         modules_table_name_en: 'Name (EN)',
         modules_table_name_nl: 'Name (NL)',
         modules_table_description_en: 'Beschreibung (EN)',
         modules_table_description_nl: 'Beschreibung (NL)',
         modules_table_status: 'Status',
         modules_table_updated_at: 'Aktualisiert am',
         modules_filter_search: 'Suchen',
         modules_no_record_found: 'Keine Devices gefunden',
         modules_table_row_per_page: 'Ergebnisse pro Seite',
         modules_bulk_no_record_is_selected_error_message:
           'Zeilen für die Massendeaktivierung auswählen.',
         modules_deactivated_message: 'Modul wurde erfolgreich deaktiviert',
         modules_activated_message: 'Modul wurde erfolgreich aktiviert',
         modules_bulk_deactivated_message:
           '##MODULES_COUNT## Module wurden erfolgreich deaktiviert',
         modules_title: 'Module',
         modules_add_new: 'Hinzufügen',
         modules_bread_crumb_main_title: 'Module',
         add_modules_bread_crumb_first_link: 'Modul hinzufügen',
         edit_modules_bread_crumb_first_link: 'Modul ändern',
         add_modules_form_header: 'Modul hinzufügen',
         edit_modules_form_header: 'Modul ändern',
         modules_form_module_identifier_field: 'Kennung',
         modules_identifier_required_form_error: 'Kennung ist erforderlich',
         modules_form_module_name_en_field: 'Name (EN)',
         modules_form_module_name_nl_field: 'Name (NL)',
         modules_name_en_required_form_error: 'Name (EN) ist erforderlich',
         modules_name_nl_required_form_error: 'Name (NL) ist erforderlich',
         modules_form_description_en_field: 'Beschreibung (EN)',
         modules_form_description_nl_field: 'Beschreibung (NL)',
         modules_form_api_error: 'Interner Serverfehler',
         modules_form_unqiue_module_name_error: 'Kennung muss eindeutig sein',
         modules_form_api_forbidden:
           'Benutzer hat keine Berechtigung zum Erstellen eines Moduls',
         modules_add_form_success: 'Modul wurde erfolgreich erstellt',
         modules_edit_form_success: 'Modul wurde erfolgreich angepasst',
         modules_form_add_module_button: 'Modul hinzufügen',
         modules_form_edit_module_button: 'Modul ändern',
         modules_form_cancel_button: 'Abbrechen',
         permissions_all: 'Alle',
         permissions_active: 'Aktiv',
         permissions_inactive: 'INAKTIV',
         permissions_filter_delete_button: 'Entfernen',
         permissions_table_identifier: 'Kennung',
         permissions_table_module_name: 'Modul',
         permissions_table_name_en: 'Name (EN)',
         permissions_table_name_nl: 'Name (NL)',
         permissions_table_description_en: 'Beschreibung (EN)',
         permissions_table_description_nl: 'Beschreibung (NL)',
         permissions_table_status: 'Status',
         permissions_filter_search: 'Suchen',
         permissions_no_record_found: 'Kein Gerät gefunden',
         permissions_table_row_per_page: 'Ergebnisse pro Seite',
         permissions_bulk_no_record_is_selected_error_message:
           'Zeilen für die Massendeaktivierung auswählen.',
         permissions_deactivated_message:
           'Berechtigung wurde erfolgreich deaktiviert',
         permissions_activated_message:
           'Berechtigung wurde erfolgreich aktiviert',
         permissions_bulk_deactivated_message:
           '##PERMISSIONS_COUNT## Berechtigungen wurden erfolgreich deaktiviert',
         permissions_title: 'Berechtigungen',
         permissions_add_new: 'Berechtigung hinzufügen',
         permissions_bread_crumb_main_title: 'Berechtigung',
         add_permissions_bread_crumb_first_link: 'Berechtigung hinzufügen',
         edit_permissions_bread_crumb_first_link: 'Berechtigung ändern',
         edit_permissions_form_header: 'Berechtigung ändern',
         add_permissions_form_header: 'Berechtigung hinzufügen',
         permissions_form_permission_identifier_field: 'Kennung',
         permissions_form_permission_module_field: 'Modul',
         permissions_form_permission_select_module_field: 'Modul auswählen',
         permissions_module_required_form_error: 'Modul ist erforderlich',
         permissions_identifier_required_form_error: 'Kennung ist erforderlich',
         permissions_form_permission_name_en_field: 'Name (EN)',
         permissions_form_permission_name_nl_field: 'Name (NL)',
         permissions_form_permission_sub_module_field: 'Untermodul',
         permissions_form_permission_select_sub_module_field:
           'Untermodul auswählen',
         permissions_sub_module_required_form_error:
           'Untermodul ist erforderlich',
         permissions_name_required_form_error: 'Name ist erforderlich',
         permissions_form_description_en_field: 'Beschreibung (EN)',
         permissions_form_description_nl_field: 'Beschreibung (NL)',
         permissions_form_api_error: 'Interner Serverfehler',
         permissions_form_unqiue_permission_name_error:
           'Kennung muss eindeutig sein',
         permissions_edit_form_success: 'Kunde wurde erfolgreich angepasst!',
         permissions_form_api_forbidden:
           'Benutzer hat keine Berechtigung zum Erstellen von Berechtigungen',
         permissions_add_form_success:
           'Berechtigung wurde erfolgreich erstellt',
         permissions_form_add_permission_button: 'Berechtigung hinzufügen',
         permissions_form_edit_permission_button: 'Berechtigung ändern',
         permissions_form_cancel_button: 'Abbrechen',
         permissions_read: 'Lesen',
         permissions_create: 'Hinzufügen',
         permissions_update: 'Anpassen',
         permissions_delete: 'Entfernen',
         permissions_status: 'Status umschalten',
         permissions_get_all: 'Alle abrufen',
         permissions_get_by_id: 'Nach ID abrufen',
         permissions_bulk_deactivate: 'Massendeaktivierung',
         permissions_form_title: 'Berechtigungen',
         duplicate_roles_form_header: 'Rolle duplizieren',
         duplicate_roles_bread_crumb_first_link: 'Rolle duplizieren',
         roles_duplicate_form_success: 'Rolle wurde erfolgreich dupliziert',
         roles_filter_duplicate_button: 'Rolle duplizieren',
         roles_pre_identifier_required_form_error:
           'Vorkennung ist erforderlich',
         roles_form_pre_identifier_field: 'Vorkennung',
         roles_form_pre_identifier_select: 'Vorkennung auswählen',
         duplicate_roles_identifier_message:
           '<ul style="margin: "0", padding-left: 15px"> <li>Bei einer neuen Kundenrolle, beginnen Sie die Kennung immer mit kunde_</li> <li>Bei einer neuen Unterkundenrolle, beginnen Sie die Kennung immer mit service_kunde_</li> <li>Für eine neue Fortes-Rolle, beginnen Sie die Kennung immer mit fortes_</li> </ul>',
         devices_single_device_counters_title: 'Deviceszähler',
         devices_single_device_counters_offline_counter: 'Offline-Zähler',
         devices_single_device_counters_online_counter: 'Online-Zähler',
         devices_single_device_counters_ota_fail_counter:
           'OTA fehlgeschlagen Zähler',
         devices_single_device_counters_ota_update_counter:
           'OTA erfolgreich Zähler',
         devices_single_device_counters_mbus_error_counter:
           'Energie-Fehlerzähler',
         devices_single_device_counters_modbus_error_counter:
           'Modbus-Fehlerzähler',
         devices_single_device_docs_directory: 'Verzeichnisse',
         device_single_documentation_no_documents_found:
           'Keine Dokumente gefunden',
         reporting_registers_details_table_registers_pending:
           'Wartende Register',
         reporting_registers_details_table_registers_updated_fail:
           'Fehlgeschlagene Register',
         reporting_registers_details_table_registers_updated_successful:
           'Erfolgreiche Register',
         reporting_registers_details_table_status: 'Status',
         reporting_ota_message: 'Aktualisieren Sie die Firmware auf Version',
         devices_bulk_edit_registers_form_fix_register_values_error:
           'Korrigieren Sie die Fehler in allen Registerkarten',
         login_permission_error:
           'Holen Sie sich die entsprechenden Genehmigungen vom Fortes-Administrator',
         monitoring_title: 'Überwachung',
         monitoring_vm_title: 'Virtuelle Maschine',
         monitoring_ram_title: 'RAM',
         monitoring_cpu_title: 'CPU',
         monitoring_disk_title: 'Festplatte',
         monitoring_restarts_title: 'Neustarts',
         monitoring_uptime_title: 'Betriebszeit',
         monitoring_services_title: 'Services',
         monitoring_service_table_service_name: 'Name',
         monitoring_service_table_service_status: 'Status',
         be_user_service: 'Benutzer',
         be_project_service: 'Projekte',
         be_export_service: 'Export',
         be_preset_service: 'Voreinstellungen',
         be_status_updater_service: 'Status-Updater',
         be_email_service: 'E-Mail',
         be_permissions_rbac_service: 'Berechtigungen',
         be_upload_images_service: 'Bilder hochladen',
         be_db_archive_service: 'DB-Archiv',
         be_diagnostic_service: 'Diagnose',
         be_external_service: 'Externe APIs',
         be_mqtt_updater_service: 'MQTT',
         fortes_document_service: 'Fortes-Dokument',
         external_client_service: 'Externer Kunde',
         devices_form_service_client_field: 'Servicekunden',
         sidebar_3scale_users: '3Scale-Kunde Benutzer',
         three_scale_users_title: '3Scale-Kunde Benutzer',
         three_scale_users_table_name: 'Name',
         three_scale_users_table_email: 'E-Mail',
         three_scale_users_table_client: 'Kunde',
         three_scale_users_table_updated_at: 'Aktualisiert am',
         three_scale_users_bread_crumb_main_title: '3Scale-Kunde Benutzer',
         add_three_scale_users_bread_crumb_first_link:
           '3Scale-Kunde Benutzer hinzufügen',
         add_three_scale_users_form_header: '3Scale-Kunde Benutzer hinzufügen',
         three_scale_users_form_name_field: 'Name',
         three_scale_users_form_email_field: 'E-Mail-Adresse',
         three_scale_users_form_client_field: 'Kunde',
         three_scale_users_form_password_field: 'Passwort',
         three_scale_users_name_required_form_error: 'Name ist erforderlich',
         three_scale_users_email_valid_form_error:
           'Es muss eine gültige E-Mail sein',
         three_scale_users_email_required_form_error: 'E-Mail ist erforderlich',
         three_scale_users_client_required_form_error: 'Kunde ist erforderlich',
         three_scale_users_password_required_form_error:
           'Das Passwort muss mindestens 8 Zeichen enthalten, davon mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen.',
         three_scale_users_no_record_found: 'Keine Datensätze gefunden',
         three_scale_users_table_row_per_page: 'Zeilen pro Seite',
         three_scale_users_add_new: '3Scale-Kunde Benutzer hinzufügen',
         three_scale_users_filter_search: 'Suche',
         three_scale_user_single_first_bread_crumb: '3Scale-Kunde Benutzer',
         three_scale_user_single_second_bread_crumb:
           'Ansicht 3Scale-Kunde Benutzer',
         three_scale_user_single_apis_tab: 'API-Keys',
         three_scale_user_single_edit_button_label: 'Bearbeiten',
         three_scale_user_single_user_label: 'Benutzer',
         three_scale_user_single_email_label: 'E-Mail',
         three_scale_user_single_client_label: 'Kunde',
         edit_three_scale_users_form_header: '3Scale-Kunde Benutzer bearbeiten',
         edit_three_scale_users_bread_crumb_first_link:
           '3Scale-Kunde Benutzer bearbeiten',
         device_single_temperature_start_date: 'Startdatum',
         device_single_temperature_end_date: 'Enddatum',
         device_single_temperature_start_date_error:
           'Das Startdatum muss kleiner oder gleich dem Enddatum sein',
         sidebar_3scale_admins: '3Scale-Admin Benutzer',
         three_scale_user_admins_title: '3Scale-Admin Benutzer',
         three_scale_user_admins_table_name: 'Name',
         three_scale_user_admins_table_email: 'E-Mail',
         three_scale_user_admins_table_updated_at: 'Aktualisiert am',
         three_scale_user_admins_bread_crumb_main_title:
           '3Scale-Admin Benutzer',
         add_three_scale_user_admins_bread_crumb_first_link:
           '3Scale-Admin Benutzer hinzufügen',
         add_three_scale_user_admins_form_header:
           '3Scale-Admin Benutzer hinzufügen',
         three_scale_user_admins_form_name_field: 'Name',
         three_scale_user_admins_form_email_field: 'E-Mail-Adresse',
         three_scale_user_admins_form_client_field: 'Admin',
         three_scale_user_admins_form_password_field: 'Passwort',
         three_scale_user_admins_name_required_form_error:
           'Name ist erforderlich',
         three_scale_user_admins_email_valid_form_error:
           'Es muss eine gültige E-Mail sein',
         three_scale_user_admins_email_required_form_error:
           'E-Mail ist erforderlich',
         three_scale_user_admins_password_required_form_error:
           'Das Passwort muss mindestens 8 Zeichen enthalten, davon mindestens einen Großbuchstaben, eine Zahl und ein Sonderzeichen.',
         three_scale_user_admins_no_record_found: 'Keine Datensätze gefunden',
         three_scale_user_admins_table_row_per_page: 'Zeilen pro Seite',
         three_scale_user_admins_add_new: '3Scale-Admin Benutzer hinzufügen',
         three_scale_user_admins_filter_search: 'Suche',
         three_scale_user_admin_single_first_bread_crumb:
           '3Scale-Admin Benutzer',
         three_scale_user_admin_single_second_bread_crumb:
           'Ansicht 3Scale-Admin Benutzer',
         three_scale_user_admin_single_apis_tab: 'API-Keys',
         three_scale_user_admin_single_edit_button_label: 'Bearbeiten',
         three_scale_user_admin_single_user_label: 'Benutzer',
         three_scale_user_admin_single_email_label: 'E-Mail',
         edit_three_scale_user_admins_form_header:
           'Bearbeiten 3Scale-Admin Benutzer',
         edit_three_scale_user_admins_bread_crumb_first_link:
           'Bearbeiten 3Scale-Admin Benutzer',
         projects_project_order_number_required_form_error:
           'Projektauftragsnummer ist erforderlich',
         projects_form_project_order_number_field: 'Projektauftragsnummer',
         client_error_single_device_address: 'Installationsadresse',
         navbar_notification_mark_all_as_read: 'Alle als gelesen markieren',
         navbar_notification_view_all: 'Alle anzeigen',
         notifications_title: 'Meldungen',
         notifications_all: 'Alle',
         notifications_unread: 'Ungelesen',
         notifications_read: 'Gelesen',
         notifications_table_title: 'Titel (EN)',
         notifications_table_description: 'Beschreibung (EN)',
         notifications_table_notification_type: 'Benachrichtigungstyp',
         notifications_table_notification_category:
           'Benachrichtigungskategorie',
         notifications_table_date: 'Datum',
         notifications_filter_search: 'Suche',
         notifications_no_record_found: 'Keine Datensätze gefunden',
         notifications_table_row_per_page: 'Zeilen pro Seite',
         add_notifications_form_header: 'Benachrichtigung hinzufügen',
         notifications_title_en_required_form_error:
           'Titel (EN) ist erforderlich',
         notifications_title_nl_required_form_error:
           'Titel (NL) ist erforderlich',
         notifications_description_en_required_form_error:
           'Beschreibung (EN) ist erforderlich',
         notifications_description_nl_required_form_error:
           'Beschreibung (NL) ist erforderlich',
         notifications_form_notification_type_field: 'Benachrichtigungstyp',
         notifications_notification_type_required_form_error:
           'Benachrichtigungstyp ist erforderlich',
         notifications_form_notification_category_field:
           'Benachrichtigungskategorie',
         notifications_notification_category_required_form_error:
           'Benachrichtigungskategorie ist erforderlich',
         notifications_form_title_en_field: 'Titel (EN)',
         notifications_form_title_nl_field: 'Titel (NL)',
         notifications_form_start_date_field: 'Startdatum',
         notifications_form_end_date_field: 'Enddatum',
         notifications_form_description_en_field: 'Beschreibung (EN)',
         notifications_form_description_nl_field: 'Beschreibung (NL)',
         notifications_form_add_button: 'Benachrichtigung hinzufügen',
         notifications_form_edit_button: 'Benachrichtigung bearbeiten',
         notifications_form_cancel_button: 'Abbrechen',
         notifications_add_form_success:
           'Benachrichtigung wurde erfolgreich hinzugefügt',
         notifications_form_api_error: 'Interner Serverfehler',
         notification_type_information: 'Information',
         notification_type_warning: 'Warnung',
         notification_category_simple: 'Einfach',
         notification_category_timebased: 'Zeitbasiert',
         edit_notifications_form_header: 'Benachrichtigung bearbeiten',
         sidebar_notification_menu: 'Benachrichtigungen',
         pop_over_close_button: 'Schließen',
         docs_user_type_end_user: 'Endbenutzer',
         docs_user_type_installer: 'Installateur',
         devices_single_device_docs_no_directory_found:
           'Keine Verzeichnisse gefunden',
         device_single_status_tab: 'Bildmaterial',
         device_single_status_picture_tab: 'Bild',
         device_single_status_principle_tab: 'Prinzip',
         device_single_status_table_loading: 'Wird geladen ...',
         device_single_status_mbus_export_button: 'Export',
         device_single_status_mbus_date_range_filter_label: 'Datumsbereich',
         device_single_status_mbus_date_export_label: 'Datum',
         device_single_status_mbus_time_export_label: 'Zeit',
         device_single_status_mbus_port_export_label: 'Port',
         device_single_status_mbus_meter_connected_at_export_label:
           'Zähler angeschlossen bei',
         device_single_status_mbus_meter_status_export_label: 'Zählerstatus',
         device_single_status_mbus_meter_type_export_label: 'Zählertyp',
         device_single_status_mbus_select_meter_type_export_label:
           'Zählertyp auswählen',
         device_single_status_mbus_select_meter_type_error: 'Zählertyp fehlt',
         device_single_status_mbus_export_internal_server_error:
           'Interner Serverfehler',
         device_single_status_mbus_export_no_data_found_error:
           'Kein Datensatz gefunden',
         sidebar_diagnostic_formulas: 'Diagnoseformeln',
         diagnostic_formulas_all: 'Alle',
         diagnostic_formulas_active: 'Aktiv',
         diagnostic_formulas_inactive: 'InAktiv',
         diagnostic_formulas_filter_delete_button: 'Löschen',
         diagnostic_formulas_table_identifier: 'Kennung',
         diagnostic_formulas_table_name_en: 'Name (EN)',
         diagnostic_formulas_table_name_nl: 'Name (NL)',
         diagnostic_formulas_table_description_en: 'Beschreibung (EN)',
         diagnostic_formulas_table_description_nl: 'Beschreibung (NL)',
         diagnostic_formulas_table_formula: 'Formel',
         diagnostic_formulas_table_status: 'Status',
         diagnostic_formulas_table_updated_at: 'Aktualisiert am',
         diagnostic_formulas_filter_search: 'Suche',
         diagnostic_formulas_no_record_found: 'Kein Datensatz gefunden',
         diagnostic_formulas_table_row_per_page: 'Zeilen pro Seite',
         diagnostic_formulas_bulk_no_record_is_selected_error_message:
           'Wählen Sie Datensätze für die Massendeaktivierung aus.',
         diagnostic_formulas_deactivated_message:
           'Diagnose wurde erfolgreich deaktiviert',
         diagnostic_formulas_activated_message:
           'Diagnose wurde erfolgreich aktiviert',
         diagnostic_formulas_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Diagnosen wurden erfolgreich deaktiviert',
         diagnostic_formulas_title: 'Diagnoseformeln',
         diagnostic_formulas_add_new: 'Neue hinzufügen',
         diagnostic_formulas_bread_crumb_main_title: 'Diagnoseformeln',
         add_diagnostic_formulas_bread_crumb_first_link:
           'Diagnoseformeln hinzufügen',
         edit_diagnostic_formulas_bread_crumb_first_link:
           'Diagnoseformeln bearbeiten',
         add_diagnostic_formulas_form_header: 'Diagnoseformeln hinzufügen',
         edit_diagnostic_formulas_form_header: 'Diagnoseformeln bearbeiten',
         diagnostic_formulas_form_diagnostic_formulas_identifier_field:
           'Kennung',
         diagnostic_formulas_identifier_required_form_error:
           'Kennung ist erforderlich',
         diagnostic_formulas_form_diagnostic_formulas_name_en_field:
           'Name (EN)',
         diagnostic_formulas_form_diagnostic_formulas_name_nl_field:
           'Name (NL)',
         diagnostic_formulas_name_en_required_form_error:
           'Name (EN) ist erforderlich',
         diagnostic_formulas_name_nl_required_form_error:
           'Name (NL) ist erforderlich',
         diagnostic_formulas_formula_required_form_error:
           'Formel ist erforderlich',
         diagnostic_formulas_form_description_en_field: 'Beschreibung (EN)',
         diagnostic_formulas_form_description_nl_field: 'Beschreibung (NL)',
         diagnostic_formulas_form_forumla_field: 'Formel',
         diagnostic_formulas_form_api_error: 'Interner Serverfehler',
         diagnostic_formulas_form_unqiue_diagnostic_formulas_name_error:
           'Kennung muss eindeutig sein',
         diagnostic_formulas_form_api_forbidden:
           'Benutzer hat keine Berechtigung zum Erstellen von diagnose_formeln',
         diagnostic_formulas_add_form_success:
           'Diagnose wurde erfolgreich erstellt',
         diagnostic_formulas_edit_form_success:
           'Diagnose wurde erfolgreich aktualisiert',
         diagnostic_formulas_form_add_diagnostic_formulas_button:
           'Diagnose hinzufügen',
         diagnostic_formulas_form_edit_diagnostic_formulas_button:
           'Diagnose bearbeiten',
         diagnostic_formulas_form_cancel_button: 'Abbrechen',
         sidebar_diagnostic_categories: 'Diagnosekategorien',
         diagnostic_categories_all: 'Alle',
         diagnostic_categories_active: 'Aktiv',
         diagnostic_categories_inactive: 'InAktiv',
         diagnostic_categories_filter_delete_button: 'Löschen',
         diagnostic_categories_table_identifier: 'Kennung',
         diagnostic_categories_table_name_en: 'Name (EN)',
         diagnostic_categories_table_name_nl: 'Name (NL)',
         diagnostic_categories_table_description_en: 'Beschreibung (EN)',
         diagnostic_categories_table_description_nl: 'Beschreibung (NL)',
         diagnostic_categories_table_status: 'Status',
         diagnostic_categories_table_updated_at: 'Aktualisiert am',
         diagnostic_categories_filter_search: 'Suche',
         diagnostic_categories_no_record_found: 'Kein Datensatz gefunden',
         diagnostic_categories_table_row_per_page: 'Zeilen pro Seite',
         diagnostic_categories_bulk_no_record_is_selected_error_message:
           'Wählen Sie Datensätze für die Massendeaktivierung aus.',
         diagnostic_categories_deactivated_message:
           'Kategorie wurde erfolgreich deaktiviert',
         diagnostic_categories_activated_message:
           'Kategorie wurde erfolgreich aktiviert',
         diagnostic_categories_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Kategorien wurden erfolgreich deaktiviert',
         diagnostic_categories_title: 'Diagnosekategorien',
         diagnostic_categories_add_new: 'Neue hinzufügen',
         diagnostic_categories_bread_crumb_main_title: 'Diagnosekategorien',
         add_diagnostic_categories_bread_crumb_first_link:
           'Diagnosekategorie hinzufügen',
         edit_diagnostic_categories_bread_crumb_first_link:
           'Diagnosekategorie bearbeiten',
         add_diagnostic_categories_form_header: 'Diagnosekategorie hinzufügen',
         edit_diagnostic_categories_form_header: 'Diagnosekategorie bearbeiten',
         diagnostic_categories_form_diagnostic_categories_identifier_field:
           'Kennung',
         diagnostic_categories_identifier_required_form_error:
           'Kennung ist erforderlich',
         diagnostic_categories_form_diagnostic_categories_name_en_field:
           'Name (EN)',
         diagnostic_categories_form_diagnostic_categories_name_nl_field:
           'Name (NL)',
         diagnostic_categories_name_en_required_form_error:
           'Name (EN) ist erforderlich',
         diagnostic_categories_name_nl_required_form_error:
           'Name (NL) ist erforderlich',
         diagnostic_categories_form_description_en_field: 'Beschreibung (EN)',
         diagnostic_categories_form_description_nl_field: 'Beschreibung (NL)',
         diagnostic_categories_form_api_error: 'Interner Serverfehler',
         diagnostic_categories_form_unqiue_diagnostic_categories_name_error:
           'Kennung muss eindeutig sein',
         diagnostic_categories_form_api_forbidden:
           'Benutzer hat keine Berechtigung zum Erstellen von diagnose_kategorien',
         diagnostic_categories_add_form_success:
           'Kategorie wurde erfolgreich erstellt',
         diagnostic_categories_edit_form_success:
           'Kategorie wurde erfolgreich aktualisiert',
         diagnostic_categories_form_add_diagnostic_categories_button:
           'Kategorie hinzufügen',
         diagnostic_categories_form_edit_diagnostic_categories_button:
           'Kategorie bearbeiten',
         diagnostic_categories_form_cancel_button: 'Abbrechen',
         sidebar_diagnostics_overview: 'Diagnose',
         sidebar_firmware_menu: 'Firmware',
         firmwares_all: 'Alle',
         firmwares_active: 'Aktiv',
         firmwares_inactive: 'InAktiv',
         firmwares_table_identifier: 'Kennung',
         firmwares_table_name_en: 'Name (EN)',
         firmwares_table_name_nl: 'Name (NL)',
         firmwares_table_firmware: 'Firmware',
         firmwares_table_status: 'Status',
         firmwares_table_updated_at: 'Aktualisiert am',
         firmwares_filter_search: 'Suche',
         firmwares_no_record_found: 'Kein Datensatz gefunden',
         firmwares_table_row_per_page: 'Zeilen pro Seite',
         firmwares_bulk_no_record_is_selected_error_message:
           'Wählen Sie Datensätze für die Massendeaktivierung aus.',
         firmwares_deactivated_message:
           'Firmware wurde erfolgreich deaktiviert',
         firmwares_activated_message: 'Firmware wurde erfolgreich aktiviert',
         firmwares_title: 'Firmware',
         firmwares_add_new: 'Neue hinzufügen',
         firmwares_bread_crumb_main_title: 'Firmware',
         add_firmwares_bread_crumb_first_link: 'Firmware hinzufügen',
         edit_firmwares_bread_crumb_first_link: 'Firmware bearbeiten',
         add_firmwares_form_header: 'Firmware hinzufügen',
         edit_firmwares_form_header: 'Firmware bearbeiten',
         firmwares_form_firmware_identifier_field: 'Kennung',
         firmwares_identifier_required_form_error: 'Kennung ist erforderlich',
         firmwares_form_firmware_name_en_field: 'Name (EN)',
         firmwares_form_firmware_name_nl_field: 'Name (NL)',
         firmwares_form_firmware_firmware_field: 'Firmware',
         firmwares_name_en_required_form_error: 'Name (EN) ist erforderlich',
         firmwares_name_nl_required_form_error: 'Name (NL) ist erforderlich',
         firmwares_form_description_en_field: 'Beschreibung (EN)',
         firmwares_form_description_nl_field: 'Beschreibung (NL)',
         firmwares_form_api_error: 'Interner Serverfehler',
         firmwares_form_unqiue_firmware_name_error:
           'Kennung muss eindeutig sein',
         firmwares_form_api_forbidden:
           'Benutzer hat keine Berechtigung zum Erstellen von Firmware',
         firmwares_add_form_success: 'Firmware wurde erfolgreich erstellt',
         firmwares_edit_form_success: 'Firmware wurde erfolgreich aktualisiert',
         firmwares_form_edit_firmware_button: 'Firmware bearbeiten',
         firmwares_form_cancel_button: 'Abbrechen',
         diagnostic_flows_all: 'Alle',
         diagnostic_flows_active: 'Aktiv',
         diagnostic_flows_inactive: 'InAktiv',
         diagnostic_flows_filter_delete_button: 'Löschen',
         diagnostic_flows_table_identifier: 'Kennung',
         diagnostic_flows_table_category: 'Kategorie',
         diagnostic_flows_table_name_en: 'Name (EN)',
         diagnostic_flows_table_name_nl: 'Name (NL)',
         diagnostic_flows_table_description_en: 'Beschreibung (EN)',
         diagnostic_flows_table_description_nl: 'Beschreibung (NL)',
         diagnostic_flows_table_status: 'Status',
         diagnostic_flows_table_updated_at: 'Aktualisiert am',
         diagnostic_flows_filter_search: 'Suche',
         diagnostic_flows_no_record_found: 'Kein Datensatz gefunden',
         diagnostic_flows_table_row_per_page: 'Zeilen pro Seite',
         diagnostic_flows_bulk_no_record_is_selected_error_message:
           'Wählen Sie Datensätze für die Massendeaktivierung aus.',
         diagnostic_flows_deactivated_message:
           'Durchfluss wurde erfolgreich deaktiviert',
         diagnostic_flows_activated_message:
           'Durchfluss wurde erfolgreich aktiviert',
         diagnostic_flows_bulk_deactivated_message:
           '##DIAGNOSTICS_COUNT## Durchflüsse wurden erfolgreich deaktiviert',
         diagnostic_flows_title: 'Diagnosedurchflüsse',
         diagnostic_flows_add_new: 'Neue hinzufügen',
         diagnostic_flows_bread_crumb_main_title: 'Diagnosedurchflüsse',
         add_diagnostic_flows_bread_crumb_first_link:
           'Diagnosedurchfluss hinzufügen',
         edit_diagnostic_flows_bread_crumb_first_link:
           'Diagnosedurchfluss bearbeiten',
         add_diagnostic_flows_form_header: 'Diagnosedurchfluss hinzufügen',
         edit_diagnostic_flows_form_header: 'Diagnosedurchfluss bearbeiten',
         diagnostic_flows_form_diagnostic_flows_identifier_field: 'Kennung',
         diagnostic_flows_identifier_required_form_error:
           'Kennung ist erforderlich',
         diagnostic_flows_form_diagnostic_flows_name_en_field: 'Name (EN)',
         diagnostic_flows_form_diagnostic_flows_name_nl_field: 'Name (NL)',
         diagnostic_flows_name_en_required_form_error:
           'Name (EN) ist erforderlich',
         diagnostic_flows_name_nl_required_form_error:
           'Name (NL) ist erforderlich',
         diagnostic_flows_category_required_form_error:
           'Kategorie ist erforderlich',
         diagnostic_flows_form_description_en_field: 'Beschreibung (EN)',
         diagnostic_flows_form_description_nl_field: 'Beschreibung (NL)',
         diagnostic_flows_form_flow_condition_error_field:
           'Bedingungen müssen wahr/falsch-Regeln haben',
         diagnostic_flows_form_flow_modal_title_field: 'Sind Sie sicher?',
         diagnostic_flows_form_flow_modal_subtitle_field:
           'Möchten Sie die Durchflussänderungen im Browser speichern?',
         diagnostic_flows_form_flow_modal_cancel_button_field: 'Abbrechen',
         diagnostic_flows_form_flow_modal_save_button_field:
           'Im Browser speichern',
         diagnostic_flows_form_flow_modal_save_success_message:
           'Durchfluss wurde erfolgreich im Browser gespeichert',
         diagnostic_flows_form_featured_image_field: 'Beitragsbild',
         diagnostic_flows_form_upload_featured_image_label:
           'Beitragsbild hochladen',
         diagnostic_flows_form_thumbnail_field: 'Vorschaubild',
         diagnostic_flows_form_upload_thumbnail_image_label:
           'Vorschaubild hochladen',
         diagnostic_flows_form_api_error: 'Interner Serverfehler',
         diagnostic_flows_form_unqiue_diagnostic_flows_name_error:
           'Kennung muss eindeutig sein',
         diagnostic_flows_form_api_forbidden:
           'Benutzer hat keine Berechtigung zum Erstellen von diagnose_durchflüssen',
         diagnostic_flows_add_form_success:
           'Diagnosedurchfluss wurde erfolgreich erstellt',
         diagnostic_flows_edit_form_success:
           'Diagnosedurchfluss wurde erfolgreich aktualisiert',
         diagnostic_flows_form_add_diagnostic_flows_button:
           'Durchfluss hinzufügen',
         diagnostic_flows_form_edit_diagnostic_flows_button:
           'Durchfluss bearbeiten',
         diagnostic_flows_form_cancel_button: 'Abbrechen',
         audits_select_event_type_error_option: 'Fehler',
         diagnostic_flows_form_flow_field: 'Durchfluss',
         diagnostic_flows_form_flow_condition_button: 'Zustand',
         diagnostic_flows_form_flow_timer_button: 'Timer',
         diagnostic_flows_form_flow_normal_node_button: 'Knoten',
         diagnostic_flows_form_flow_download_image_button: 'Bild herunterladen',
         diagnostic_flows_form_flow_start_button: 'Start',
         diagnostic_flows_form_flow_end_button: 'Ende',
         devices_bulk_export_mbus_historic_data_button:
           'Mbus-Daten herunterladen',
         devices_bulk_export_mbus_historic_data_file_name:
           'Mbus-Historiendaten',
         devices_bulk_mbus_download_title: 'Mbus-Historiendaten herunterladen',
         devices_bulk_bulk_mbus_download_form_date_range: 'Datumsbereich:',
         devices_bulk_bulk_mbus_download_form_submit_button:
           'Mbus-Daten herunterladen',
         devices_bulk_bulk_mbus_download_form_cancel_button: 'Abbrechen',
         devices_bulk_mbus_download_form_success:
           'Die Mbus-Historiendaten wurden erfolgreich an ##EMAIL## gesendet!',
         devices_bulk_mbus_download_form_api_error: 'Interner Serverfehler',
         devices_bulk_mbus_download_no_devices_error: '',
         devices_bulk_mbus_download_form_no_devices_error:
           'Bitte wählen Sie Devices aus, um fortzufahren.',
         devices_bulk_bulk_mbus_download_modal_title: 'Sind Sie sicher?',
         devices_bulk_mbus_download_modal_subtitle:
           'Wollen Sie einen Massendownload von Mbus-Historiendaten durchführen?',
         devices_bulk_mbus_download_modal_cancel_button: 'Abbrechen',
         devices_bulk_mbus_download_modal_submit_button: 'Bestätigen',
         projects_bulk_export_mbus_historic_data_button:
           'Mbus-Daten herunterladen',
         projects_bulk_export_mbus_historic_data_file_name:
           'Mbus-Historiendaten',
         projects_bulk_mbus_download_title: 'Mbus-Historiendaten herunterladen',
         projects_bulk_bulk_mbus_download_form_date_range: 'Datumsbereich:',
         projects_bulk_bulk_mbus_download_form_data_export_type:
           'Datumsexporttyp',
         projects_bulk_bulk_mbus_download_form_data_export_type_combined:
           'Kombiniert',
         projects_bulk_bulk_mbus_download_form_data_export_type_daily:
           'Täglich',
         projects_bulk_bulk_mbus_download_form_data_export_type_normal:
           'Normal',
         projects_bulk_bulk_mbus_download_form_submit_button:
           'Mbus-Daten herunterladen',
         projects_bulk_bulk_mbus_download_form_cancel_button: 'Abbrechen',
         projects_bulk_mbus_download_form_success:
           'Die Mbus-Historiendaten wurden erfolgreich an ##EMAIL## gesendet!',
         projects_bulk_mbus_download_form_api_error: 'Interner Serverfehler',
         projects_bulk_mbus_download_no_projects_error: '',
         projects_bulk_mbus_download_form_no_projects_error:
           'Bitte wählen Sie Projekte aus, um fortzufahren.',
         projects_bulk_bulk_mbus_download_modal_title: 'Sind Sie sicher?',
         projects_bulk_mbus_download_modal_subtitle:
           'Wollen Sie einen Massendownload von Mbus-Historiendaten durchführen?',
         projects_bulk_mbus_download_modal_cancel_button: 'Abbrechen',
         projects_bulk_mbus_download_modal_submit_button: 'Bestätigen',
         projects_bulk_mbus_selected_projects: 'Ausgewählte Projekte',
         sidebar_diagnostic_flows: 'Diagnosedurchflüsse',
         diagnostic_flows_form_diagnostic_flows_category_field:
           'Diagnosedurchflusskategorie',
         diagnostics_send_message_modal_registers_label: 'Register',
         diagnostics_send_message_modal_operator_label: 'Betreiber',
         diagnostics_send_message_modal_value_label: 'Wert',
         diagnostics_send_message_modal_registers_label_default_option:
           'Register auswählen',
         diagnostics_send_message_modal_save_button: 'Speichern',
         diagnostics_send_message_modal_cancel_button: 'Abbrechen',
         diagnostics_condition_modal_sensor_type_label: 'Sensortyp',
         diagnostics_condition_modal_modbus_label: 'Modbus-Sensor',
         diagnostics_condition_modal_mbus_label: 'Mbus-Sensor',
         diagnostics_condition_modal_formula_output_label: 'Formel Ausgabe',
         diagnostics_condition_modal_meter_type_label: 'Zählertyp',
         diagnostics_condition_modal_meter_type_label_default_option:
           'Zählertyp auswählen',
         diagnostics_condition_modal_operator_label: 'Betreiber',
         diagnostics_condition_modal_operator_option_1: 'kleiner',
         diagnostics_condition_modal_operator_option_2: 'kleiner gleich',
         diagnostics_condition_modal_operator_option_3: 'gleich',
         diagnostics_condition_modal_operator_option_4: 'größer',
         diagnostics_condition_modal_operator_option_5: 'größer gleich',
         diagnostics_condition_modal_operator_option_6: 'ungleich',
         diagnostics_condition_modal_register_label: 'Register',
         diagnostics_condition_modal_value_label: 'Wert',
         diagnostics_condition_modal_timer_label: 'Timer',
         diagnostics_condition_modal_retries_label: 'Wiederholungen',
         diagnostics_condition_modal_delay_label: 'Verzögerung',
         diagnostics_condition_modal_times_label: 'Male',
         diagnostics_condition_modal_second_label: 'Sek.',
         diagnostics_condition_modal_save_button: 'Speichern',
         diagnostics_condition_modal_cancel_button: 'Abbrechen',
         extra_white_space_form_error:
           'Zusätzlicher Weißraum ist nicht erlaubt',
         diagnostic_flows_form_incomplete_flow_error:
           'Bitte überprüfen Sie die Kanten und die Knoten in schwarzer Farbe und roter Animation',
         diagnostic_flows_menu_information: 'Information',
         diagnostic_flows_menu_modbus: 'Modbus',
         diagnostic_flows_menu_message: 'Nachricht',
         diagnostic_flows_menu_condition: 'Zustand',
         diagnostic_flows_menu_user_interaction: 'Benutzerinteraktion',
         diagnostic_flows_menu_steps: 'Seite',
         diagnostic_flows_menu_timer: 'Timer',
         diagnostic_flows_menu_formula: 'Formel',
         diagnostic_flows_form_user_interaction_operation_type: 'Vorgangstyp',
         diagnostic_flows_form_user_interaction_operation_type_default_option:
           'Vorgangstyp auswählen',
         diagnostic_flows_form_user_interaction_operation_type_option_choose_mbus_meter:
           'Energie Zähler wählen',
         diagnostic_flows_form_user_interaction_operation_type_option_choose_faucet:
           'Wasserhahnregister wählen',
         diagnostic_flows_form_user_interaction_field_type_option_select_field:
           'Feldtyp auswählen',
         diagnostic_flows_form_user_interaction_field_type: 'Feldtyp',
         diagnostic_flows_form_user_interaction_field_type_default_option:
           'Feldtyp auswählen',
         diagnostic_flows_form_user_interaction_field_type_option_checkbox:
           'Kontrollkästchen',
         diagnostic_flows_form_user_interaction_field_type_option_radio_button:
           'Optionsschaltfläche',
         diagnostic_flows_form_user_interaction_field_type_option_text_field:
           'Textfeld',
         diagnostic_flows_form_user_interaction_description_en:
           'Beschreibung (EN)',
         diagnostic_flows_form_user_interaction_description_nl:
           'Beschreibung (NL)',
         diagnostic_flows_form_steps_label_en: 'Beschreibung (EN)',
         diagnostic_flows_form_steps_label_nl: 'Beschreibung (NL)',
         diagnostic_formulas_form_diagnostic_formulas_retries_field:
           'Wiederholungen',
         diagnostic_formulas_form_diagnostic_formulas_delay_field:
           'Verzögerung',
         diagnostic_formulas_form_diagnostic_formulas_unit_field:
           'Formel Einheit',
         diagnostic_formulas_form_forumla_field_placeholder:
           'Bitte wählen Sie @, um Modbus- oder Mbus-Register auszuwählen',
         device_single_diagnostic_flow_missing_pages_error:
           '##SELECTED_FLOW## hat keine Seiten. Fügen Sie Seiten zum Durchfluss hinzu, um fortzufahren.',
         device_single_stepper_choose_flow_title: 'Durchfluss auswählen',
         device_single_stepper_choose_explanation_of_flow_wizard_title:
           'Erläuterung des Assistenten',
         device_single_stepper_result_title: 'Ergebnis',
         diagnostic_flows_form_delete_node_modal_title: 'Sind Sie sicher?',
         diagnostic_flows_form_delete_node_modal_subtitle:
           'Wollen Sie den Knoten ##NODE_LABEL## löschen?',
         diagnostic_flows_form_delete_node_modal_cancel_button: 'Abbrechen',
         diagnostic_flows_form_delete_node_modal_delete_button: 'Löschen',
         diagnostic_flows_form_loading_text: 'Wird geladen ...',
         diagnostic_flows_form_processing_text: 'Verarbeitung',
         diagnostic_flows_form_main_error_text:
           'Durchfluss wurde aufgrund eines Fehlers gestoppt',
         diagnostic_flows_form_main_success_text:
           'Durchfluss wurde erfolgreich abgeschlossen',
         devices_bulk_download_form_data_type: 'Datentyp',
         devices_bulk_download_form_export_type: 'Exporttyp',
         devices_bulk_download_form_data_type_daily: 'Täglich',
         devices_bulk_download_form_data_type_hourly: 'Stündlich',
         devices_bulk_download_form_export_type_combined: 'Kombiniert',
         devices_bulk_download_form_export_type_separated: 'Getrennt',
         diagnostic_flows_form_main_stopped_text:
           'Durchfluss wurde durch den Benutzer gestoppt',
         diagnostic_execution_title: 'Diagnosedurchführungen',
         diagnostic_execution_table_created_at: 'Erstellt am',
         diagnostic_execution_table_device: 'Gerät',
         diagnostic_execution_table_user: 'Benutzer',
         diagnostic_execution_table_flow: 'Durchfluss',
         diagnostic_execution_table_status: 'Status',
         diagnostic_execution_table_filter_search: 'Suche',
         diagnostic_executions_tab_all: 'Alle',
         diagnostic_executions_tab_completed: 'Abgeschlossen',
         diagnostic_executions_tab_in_progress: 'In Arbeit',
         diagnostic_executions_tab_stopped: 'Angehalten',
         diagnostic_executions_tab_error: 'Fehler',
         diagnostic_executions_table_no_record_found:
           'Keine Datensätze gefunden',
         diagnostic_executions_table_row_per_page: 'Zeilen pro Seite',
         diagnostic_flows_menu_result: 'Ergebnis',
         diagnostic_flows_menu_result_form_description_en: 'Beschreibung (EN)',
         diagnostic_flows_menu_result_form_description_nl: 'Beschreibung (NL)',
         diagnostic_flows_menu_result_form_cancel_button: 'Abbrechen',
         diagnostic_flows_menu_result_form_save_button: 'Speichern',
         diagnostic_flows_menu_page_form_cancel_button: 'Abbrechen',
         diagnostic_flows_menu_page_form_save_button: 'Speichern',
         diagnostic_flows_menu_information_form_description_en:
           'Beschreibung (EN)',
         diagnostic_flows_menu_information_form_description_nl:
           'Beschreibung (NL)',
         diagnostic_flows_menu_information_form_cancel_button: 'Abbrechen',
         diagnostic_flows_menu_information_form_save_button: 'Speichern',
         sidebar_diagnostic_execution_title: 'Diagnosedurchführungen',
         diagnostic_flow_execution_single_first_bread_crumb:
           'Diagnosedurchführungen',
         diagnostic_flow_execution_single_second_bread_crumb:
           'Diagnosedurchführung anzeigen',
         diagnostic_flow_execution_single_output_tab: 'Ausgabe',
         diagnostic_flow_execution_single_device_name: 'Gerät',
         diagnostic_flow_execution_single_flow: 'Durchfluss',
         diagnostic_flow_execution_single_user: 'Benutzer',
         diagnostic_flow_execution_single_status: 'Status',
         diagnostic_flow_execution_single_created_at: 'Erstellt am',
         diagnostic_flow_execution_single_bulk_export_file_name:
           'Massendiagnosedurchführungen',
         diagnostic_flow_execution_single_bulk_export_date: 'Datum',
         diagnostic_flow_execution_single_bulk_export_time: 'Zeit',
         diagnostic_flow_execution_single_bulk_export_device_name:
           'Devicesname',
         diagnostic_flow_execution_single_bulk_export_device_serial_number_mercurius:
           'Seriennummer Mercurius',
         diagnostic_flow_execution_single_bulk_export_device_user_full_name:
           'Benutzer',
         diagnostic_flow_execution_single_bulk_export_device_flow_name_en:
           'Durchfluss Name (EN)',
         diagnostic_flow_execution_single_bulk_export_device_flow_name_nl:
           'Durchfluss Name (NL)',
         diagnostic_flow_execution_single_bulk_export_device_status: 'Status',
         diagnostic_flows_form_main_flow_already_running_error_text:
           'Der Durchfluss läuft bereits auf diesem Gerät. Mehrfache Durchflussdurchführungen sind nicht erlaubt',
         diagnostic_flows_form_open_faucet_on_option: 'Ein',
         diagnostic_flows_form_open_faucet_off_option:
           'Aus (Durchfluss geht in den Fehlermodus)',
         diagnostic_flows_form_mbus_meters_error:
           'Gerät verfügt nicht über den für den Durchfluss erforderlichen Mbus-Zähler',
         diagnostic_flows_result_title: 'Ergebnis',
         duplicate_diagnostic_flows_form_header:
           'Diagnosedurchfluss duplizieren',
         duplicate_diagnostic_flows_bread_crumb_first_link:
           'Diagnosedurchfluss duplizieren',
         diagnostic_flows_form_duplicate_diagnostic_flows_button: 'Duplizieren',
         diagnostic_flows_duplicate_form_success:
           'Diagnosedurchfluss wurde erfolgreich dupliziert',
         diagnostic_flows_filter_duplicate_button: 'Durchfluss duplizieren',
         diagnostic_flows_menu_result_form_id: 'ID',
         audits_street_number_filter_label: 'Straße',
         audits_house_number_filter_label: 'Hausnummer',
         diagnostic_execution_counter_title: 'Diagnosedurchführungszähler',
         diagnostic_execution_counter_table_id: 'ID',
         diagnostic_execution_counter_table_counter: 'Zähler',
         diagnostic_execution_counter_table_flow: 'Durchfluss',
         diagnostic_execution_counter_table_result: 'Ergebnis',
         diagnostic_execution_counter_table_filter_search: 'Suche',
         diagnostic_execution_counter_table_no_record_found:
           'Keine Datensätze gefunden',
         diagnostic_execution_counter_table_row_per_page: 'Zeilen pro Seite',
         sidebar_diagnostic_flow_execution_counter: 'Diagnosedurchflusszähler',
         device_single_firmware_hiu_label: 'Firmware HIU',
         device_single_firmware_mercurius_label: 'Firmware Mercurius',
         custom_popover_105_long_description: 'Alarmregister Langbeschreibung',
         settings_menu_title: 'Einstellungen',
         settings_menu_clients_title: 'Kunden',
         settings_menu_admins_title: 'Admins',
         settings_menu_3scale_admins_title: '3Scale-Admins',
         settings_menu_3scale_clients_title: '3Scale-Kunden',
         settings_menu_diagnostics_title: 'Diagnose',
         settings_menu_formulas_title: 'Formeln',
         settings_menu_flows_title: 'Durchflüsse',
         settings_menu_categories_title: 'Kategorien',
         settings_menu_firmwares_title: 'Firmware',
         settings_menu_appliance_types_title: 'Anwendungstypen',
         settings_menu_notifications_title: 'Benachrichtigungen',
         settings_menu_roles_title: 'Rollen',
         settings_menu_modules_title: 'Module',
         settings_menu_permissions_title: 'Berechtigungen',
         settings_menu_go_back_button: 'Zurück',
         audits_device_name_filter_label: 'Devicesname',
         device_single_documentation_parts_button: 'Teile',
         device_single_documentation_lab_vision_button: 'Labvision',
         device_single_documentation_manuals_button: 'Handbuch',
         device_single_documentation_incident_button: 'Störungsschlüssel',
         navbar_avg_proof_switch: 'Datenschutzschalter',
         clients_filter_bulk_delete_button: 'Löschen',
         clients_bulk_delete_no_clients_with_zero_devices_is_selected_error_message:
           'Bitte wählen Sie mindestens einen Datensatz mit 0 Devicesn aus',
         clients_bulk_delete_clients_with_devices_count_is_selected_error_message:
           'Bitte wählen Sie nur Datensätze aus, die 0 Devices haben',
         projects_filter_bulk_delete_button: 'Löschen',
         projects_bulk_delete_no_projects_with_zero_devices_is_selected_error_message:
           'Bitte wählen Sie mindestens einen Datensatz mit 0 Devicesn aus',
         projects_bulk_delete_projects_with_devices_count_is_selected_error_message:
           'Bitte wählen Sie nur Datensätze aus, die 0 Devices haben',
         projects_bulk_deleted_message:
           '{{ PROJECTS_COUNT }} Projekte wurden erfolgreich gelöscht',
         projects_delete_modal_title: 'Sind Sie sicher?',
         projects_delete_modal_subtitle: 'Wollen Sie Datensätze löschen?',
         projects_delete_modal_cancel_button: 'Abbrechen',
         projects_delete_modal_delete_button: 'Löschen',
         users_delete_modal_title: 'Sind Sie sicher?',
         users_delete_modal_subtitle: 'Wollen Sie Datensätze löschen?',
         users_delete_modal_cancel_button: 'Abbrechen',
         users_delete_modal_delete_button: 'Löschen',
         users_bulk_delete_no_user_is_selected_error_message:
           'Bitte wählen Sie die zu löschenden Datensätze aus',
         users_bulk_deleted_message:
           '{{ USERS_COUNT }} Benutzer wurden erfolgreich gelöscht',
         service_client_single_filter_bulk_delete_button: 'Löschen',
         service_client_single_no_user_selected_for_deleted_message:
           'Bitte wählen Sie die zu löschenden Datensätze aus',
         admins_bulk_deleted_message:
           '{{ USERS_COUNT }} Admins wurden erfolgreich gelöscht',
         admins_no_user_selected_for_deleted_message:
           'Bitte wählen Sie die zu löschenden Datensätze aus',
         admins_filter_bulk_delete_button: 'Löschen',
         three_scale_admins_bulk_deleted_message:
           '{{ USERS_COUNT }} Drei Admins wurden erfolgreich gelöscht',
         audits_message_type_label: 'Nachrichtentyp',
         audit_bulk_export_file_name: 'Datei Audits',
         registers_reporting_details_all_tab: 'Alle',
         registers_reporting_details_pending_tab: 'Beschäftigt',
         registers_reporting_details_success_tab: 'Erfolgreich',
         registers_reporting_details_failure_tab: 'Fehlgeschlagen',
         devices_form_is_service_device_field: 'Servicegerät',
         service_portal_login_in_correct_qr_code:
           'Seriennummer des Geräts ist nicht korrekt',
         service_portal_login_in_correct_credentials:
           'Anmeldedaten des Geräts sind nicht korrekt. Bitte wenden Sie sich an den Fortes-Administrator.',
         service_portal_login_not_a_registered_service_device:
           'Gerät mit der Seriennummer {{ SERIAL_NUMBER }} ist nicht registriert.',
         devices_unprovisioned: 'nicht bereitgestellt',
         sidebar_unprovisioned_devices: 'Devices – nicht bereitgestellt',
         dashboard_unprovisioned: 'NICHT BEREITGESTELLT',
         device_single_documentation_warmwater_button: 'Fehler Heißwasser',
         device_single_documentation_verwarming_button: 'Fehler Zentralheizung',
         service_portal_login_in_serial_number_scan_button:
           'Seriennummer Scannen',
         service_portal_login_in_heat_interface_unit_scan_button:
           'Scan Wärme-Interface-Einheit',
         service_portal_login_in_serial_number_scanned_message:
           'Seriennummer wurde gescannt',
         service_portal_login_in_heat_interface_unit_scanned_message:
           'Wärme-Interface-Einheit wurde gescannt',
         swapping_device_missing_new_device_error:
           'Neues Gerät wurde nicht in die Mercurius-Datenbank importiert',
         device_single_dashboard_mbus_title: 'Energie-Zähler',
         device_single_dashboard_tab: 'Dashboard',
         device_single_offline_detailed_message: 'Gerät ist „offline“',
         device_single_online_detailed_message:
           'Gerät ist „online“, alles funktioniert einwandfrei',
         device_single_error_detailed_message:
           'Gerät befindet sich im „Fehler“-Modus',
         device_single_status_documents_link: 'Devicesdokumente',
         email_device_delete_subscription_title:
           'Fortes Data Management - Delete Subscription',
         email_device_delete_subscription_api_status_title:
           'Maxitel API Response:',
         email_device_delete_subscription_type_swapped_device_message:
           'Device subscription is deleted in Device Swapping module',
         email_device_delete_subscription_type_clear_device_message:
           'Device subscription is deleted in Device Clear module',
         devices_form_subscription_id_field: 'Subscription Id',
         device_single_rss_sim_status: 'SIM Status',
         device_monitoring_error_list_table_title: 'Error List',
         device_monitoring_error_list_table_device: 'Device',
         device_monitoring_error_list_table_alarm_code: 'Alarm Code',
         device_monitoring_error_list_table_start_date: 'Start Date',
         device_monitoring_error_list_table_end_date: 'End Date',
         device_monitoring_data_consumed_list_table_title: 'Data Consumed',
         device_monitoring_data_consumed_list_table_device: 'Device',
         device_monitoring_data_consumed_list_table_data_usage: 'Data Usage',
         device_monitoring_data_consumed_list_table_date: 'Date',
         device_monitoring_ota_history_list_table_title: 'OTA History',
         device_monitoring_ota_history_list_table_device: 'Device',
         device_monitoring_ota_history_list_table_status: 'Status',
         device_monitoring_ota_history_list_table_date: 'Date',
         device_monitoring_device_status_history_title: 'Device Status History',
         device_monitoring_device_status_history_hr: 'Hr',
         device_monitoring_device_status_history_no_graph_data_error:
           'The graph is unavailable',
         device_monitoring_device_status_history_graph_loading_data:
           'Loading graph',
         device_monitoring_firmware_history_title: 'Firmware History',
         device_monitoring_device_firmware_history_graph_loading_data:
           'Loading barchart',
         device_monitoring_device_firmware_history_no_graph_data_error:
           'The barchart is unavailable',
         sidebar_devices_monitoring: 'Devices Monitoring',
         device_monitoring_projects_counter_title: 'Projects Counter',
         device_monitoring_device_projects_counter_graph_loading_data:
           'Loading barchart',
         device_monitoring_device_projects_counter_no_graph_data_error:
           'The barchart is unavailable',
         device_monitoring_users_counter_title: 'Users Counter',
         device_monitoring_device_users_counter_graph_loading_data:
           'Loading barchart',
         device_monitoring_device_users_counter_no_graph_data_error:
           'The barchart is unavailable',
         device_monitoring_sim_counter_title: 'SIM Data Counter',
         device_monitoring_sim_counter_exceeded_data_title: 'Exceeded',
         device_monitoring_sim_counter_in_package_data_title:
           'In Package Limit',
         device_monitoring_device_sim_counter_graph_loading_data:
           'Loading barchart',
         device_monitoring_device_sim_counter_no_graph_data_error:
           'The barchart is unavailable',
         service_portal_login_in_dont_have_hiu_qr_code_checkbox_field:
           "Don't have HIU QR Code",
         service_portal_login_hiu_autocomplete: 'Heat Interface Unit',
         device_monitoring_device_error_counters_title: 'Error Counters',
         device_monitoring_device_error_counters_error: 'No data found',
         device_monitoring_device_error_counters_no_graph_data_error:
           'The piechart is unavailable',
         device_monitoring_date_range_field: 'Date Range',
         reporting_ota_details_retries_tab: 'Retries',
         device_monitoring_single_page_title: 'Device Monitoring',
         device_monitoring_ota_history_list_table_fail_counter: 'Retries',
         device_monitoring_ota_history_list_table_user: 'User',
         device_monitoring_ota_history_list_table_message: 'Message',
         device_monitoring_ota_history_list_table_old_firmware_version:
           'Old Firmware Version',
         device_monitoring_project_list_table_title: 'Projects',
         device_monitoring_firmware_list_table_title: 'Firmwares',
         device_monitoring_firmware_list_table_firmware_version: 'Firmware',
         device_monitoring_firmware_list_table_device: 'Device',
         device_monitoring_firmware_list_table_project: 'Project',
         device_monitoring_firmware_list_table_saved_at: 'Date',
         monitoring_users_list_table_title: 'Users',
         monitoring_3scale_users_list_table_title: 'Three Scale Users',
         monitoring_3scale_users_filter_3scale_admin: '3Scale Admin',
         monitoring_3scale_users_filter_3scale_client: '3Scale Client',
         client_single_users_table_role: 'Role',
         device_monitoring_device_status_list_table_title: 'Device Status',
         navbar_languages_english: 'English',
         navbar_languages_dutch: 'Nederlands',
         navbar_languages_german: 'Deutsch',
         device_single_device_settings_tab: 'Settings v2',
         device_single_device_settings_title: 'Device v2 Settings',
         device_single_device_settings_secondary_device_type_field:
           'Secondary Device Type',
         device_single_device_settings_base_topic_field: 'Base Topic',
         device_single_device_settings_baud_rate_uart_field: 'Baud Rate UART',
         device_single_device_settings_select_baud_rate_uart_field:
           'Select Baud Rate UART',
         device_single_device_settings_modbus_error_register_address_field:
           'Modbus error register address',
         device_single_device_settings_modbus_error_register_value_field:
           'Modbus expected error register value',
         device_single_device_settings_reboot_device_v2: 'Reboot',
         device_single_device_settings_send_button_v2: 'Send',
         device_single_device_settings_secondary_device_type_message_forwarded:
           'Device Secondary Device Type message is forwarded to device',
         device_single_device_settings_reboot_message_forwarded:
           'Device Reboot message is forwarded to device',
         device_single_device_settings_base_topic_message_forwarded:
           'Device Base Topic message is forwarded to device',
         device_single_device_settings_baud_rate_uart_message_forwarded:
           'Device Baud Rate UART message is forwarded to device',
         device_single_device_settings_modbus_error_register_address_message_forwarded:
           'Device Modbus error register address message is forwarded to device',
         device_single_device_settings_modbus_expected_error_register_value_message_forwarded:
           'Device Modbus expected error register value message is forwarded to device',
         device_single_device_settings_secondary_device_type_error:
           'Secondary Device Type must be between 1 and 254',
         device_single_device_settings_base_topic_error:
           'Base Topic length must be between 5 and 50',
         device_single_device_settings_baud_rate_uart_error:
           'Please select a option for Baud Rate UART',
         device_single_device_settings_modbus_error_register_address_error:
           'Modbus error register address must be between 0 and 65535',
         device_single_device_settings_modbus_error_register_value_error:
           'Modbus expected error register value must be between 0 and 65535',
         device_monitoring_device_error_counters_loading_data:
           'Loading piechart',
         diagnostic_formulas_form_diagnostic_formulas_name_de_field:
           'Name (DE)',
         diagnostic_formulas_name_de_required_form_error:
           'Name (DE) is required',
         diagnostic_formulas_form_description_de_field: 'Description (DE)',
         diagnostic_flows_form_diagnostic_flows_name_de_field: 'Name (DE)',
         diagnostic_flows_name_de_required_form_error: 'Name (DE) is required',
         diagnostic_flows_form_description_de_field: 'Description (DE)',
         diagnostic_categories_form_diagnostic_categories_name_de_field:
           'Name (DE)',
         diagnostic_categories_name_de_required_form_error:
           'Name (DE) is required',
         diagnostic_categories_form_description_de_field: 'Description (DE)',
         diagnostic_categories_table_name_de: 'Name (DE)',
         diagnostic_flows_table_name_de: 'Name (DE)',
         diagnostic_formulas_table_name_de: 'Name (DE)',
         modules_form_module_name_de_field: 'Name (DE)',
         modules_name_de_required_form_error: 'Name (DE) is required',
         modules_form_description_de_field: 'Description (DE)',
         modules_table_name_de: 'Name (DE)',
         modules_table_description_de: 'Description (DE)',
         roles_form_role_name_de_field: 'Name (DE)',
         roles_name_de_required_form_error: 'Name (DE) is required',
         roles_form_description_de_field: 'Description (DE)',
         roles_table_name_de: 'Name (DE)',
         roles_table_description_de: 'Description (DE)',
         permissions_form_permission_name_de_field: 'Name (DE)',
         permissions_form_description_de_field: 'Description (DE)',
         permissions_table_name_de: 'Name (DE)',
         permissions_table_description_de: 'Description (DE)',
         notifications_form_title_de_field: 'Title (DE)',
         notifications_form_description_de_field: 'Description (DE)',
         firmwares_name_de_required_form_error: 'Name (DE)',
         firmwares_form_firmware_name_de_field: 'Name (DE) is required',
         navbar_languages_danish: 'Danish',
         firmwares_name_dk_required_form_error: 'Name (DK)',
         firmwares_form_firmware_name_dk_field: 'Name (DK) is required',
         diagnostic_formulas_form_diagnostic_formulas_name_dk_field:
           'Name (DK)',
         diagnostic_formulas_name_dk_required_form_error:
           'Name (DK) is required',
         diagnostic_formulas_form_description_dk_field: 'Description (DK)',
         diagnostic_flows_form_diagnostic_flows_name_dk_field: 'Name (DK)',
         diagnostic_flows_name_dk_required_form_error: 'Name (DK) is required',
         diagnostic_flows_form_description_dk_field: 'Description (DK)',
         diagnostic_categories_form_diagnostic_categories_name_dk_field:
           'Name (DK)',
         diagnostic_categories_name_dk_required_form_error:
           'Name (DK) is required',
         diagnostic_categories_form_description_dk_field: 'Description (DK)',
         diagnostic_categories_table_name_dk: 'Name (DK)',
         diagnostic_flows_table_name_dk: 'Name (DK)',
         diagnostic_formulas_table_name_dk: 'Name (DK)',
         modules_form_module_name_dk_field: 'Name (DK)',
         modules_name_dk_required_form_error: 'Name (DK) is required',
         modules_form_description_dk_field: 'Description (DK)',
         modules_table_name_dk: 'Name (DK)',
         modules_table_description_dk: 'Description (DK)',
         roles_form_role_name_dk_field: 'Name (DK)',
         roles_name_dk_required_form_error: 'Name (DK) is required',
         roles_form_description_dk_field: 'Description (DK)',
         roles_table_name_dk: 'Name (DK)',
         roles_table_description_dk: 'Description (DK)',
         permissions_form_permission_name_dk_field: 'Name (DK)',
         permissions_form_description_dk_field: 'Description (DK)',
         permissions_table_name_dk: 'Name (DK)',
         permissions_table_description_dk: 'Description (DK)',
         notifications_form_title_dk_field: 'Title (DK)',
         notifications_form_description_dk_field: 'Description (DK)',
         device_monitoring_data_consumed_list_table_project: 'Project',
         project_single_device_list_search_mbus_registers_by_project:
           'Search Mbus Registers',
         project_single_fetch_mbus_registers_by_project_success_message:
           'Message is forwarded to Project ##PROJECT_NAME## Devices successfully',
         project_single_fetch_mbus_registers_by_project_error_message:
           'Interval Server Error',
         devices_list_diagnostic_filters_title: 'Registers Filter',
         devices_list_diagnostics_condition_modal_sensor_type_label:
           'Sensor Type',
         devices_list_diagnostics_condition_modal_modbus_label: 'Modbus Sensor',
         devices_list_diagnostics_condition_modal_mbus_label: 'Mbus Sensor',
         devices_list_diagnostics_condition_modal_formula_output_label:
           'Formula Output',
         devices_list_diagnostics_condition_modal_meter_type_label:
           'Meter Type',
         devices_list_diagnostics_condition_modal_meter_type_label_default_option:
           'Select Meter Type',
         devices_list_diagnostics_condition_modal_operator_label: 'Operator',
         devices_list_diagnostics_condition_modal_operator_option_1: 'Less',
         devices_list_diagnostics_condition_modal_operator_option_2:
           'Less than equal to',
         devices_list_diagnostics_condition_modal_operator_option_3: 'Equal to',
         devices_list_diagnostics_condition_modal_operator_option_4: 'Greater',
         devices_list_diagnostics_condition_modal_operator_option_5:
           'Greater than equal to',
         devices_list_diagnostics_condition_modal_operator_option_6:
           'Not Equal to',
         devices_list_diagnostics_condition_modal_register_label: 'Register',
         devices_list_diagnostics_condition_modal_register_required_error:
           'Register is required',
         devices_list_diagnostics_send_message_modal_registers_label_default_option:
           'Select Register',
         devices_list_diagnostics_condition_modal_value_label: 'Value',
         devices_list_diagnostics_condition_modal_value_is_required_error:
           'Value is required',
         devices_list_diagnostics_condition_modal_operator_is_required_error:
           'Operator is required',
         devices_list_diagnostics_condition_modal_meter_is_required_error:
           'Meter is required',
         devices_list_diagnostics_condition_modal_value_invalid_error:
           'Invalid Value, please check',
         devices_list_diagnostics_condition_modal_filter_button: 'Filter',
         devices_list_diagnostics_condition_modal_close_button: 'Close',
         devices_list_diagnostics_condition_modal_add_button:
           'Add more filters',
         error_check_table_title: 'Device Error Checks',
         error_check_table_filter_search: 'Search',
         error_check_table_no_record_found: 'No Records Found',
         error_check_table_row_per_page: 'Rows Per Page',
         error_check_table_created_at: 'Created At',
         error_check_table_total_devices: 'Total Devices',
         error_check_details_table_title: 'Device Error Check Details',
         error_check_details_table_filter_search: 'Search',
         error_check_details_table_no_record_found: 'No Records Found',
         error_check_details_table_row_per_page: 'Rows Per Page',
         error_check_details_table_created_at: 'Created At',
         error_check_details_table_device: 'Device',
         error_check_details_table_old_error: 'Old Error',
         error_check_details_table_new_error: 'New Error',
         error_check_details_table_status: 'Status',
         error_check_details_table_status_success: 'Success',
         error_check_details_table_status_failed: 'Failure',
         error_check_details_table_all_tab: 'All',
         error_check_details_table_success_tab: 'Success',
         error_check_details_table_failure_tab: 'Failure',
         error_check_details_table_device_name: 'Device Name',
         error_check_details_table_device_serial_number: 'Serial Number',
         sidebar_devices_error_check: 'Error Devices',
         error_check_details_table_bulk_export_file_name:
           'Error Devices Details List',
         error_check_table_bulk_export_file_name: 'Error Devices List',
         device_single_registers_table_min_error:
           'Value must be greater than or equal to min_value',
         device_single_registers_table_invalid_value_error:
           'Please enter a valid number',
         project_single_project_setting_tab: 'Project Setting',
         project_single_project_setting_mbus_registers_title: 'MBus Registers',
         project_single_project_setting_mbus_register_accumulated_heating_energy_title:
           'Accumulated heating energy Unit',
         project_single_project_setting_mbus_register_accumulated_heating_energy_success_message:
           'Accumulated heating energy unit is successfully updated',
         project_single_project_setting_mbus_register_accumulated_heating_energy_error_message:
           'Failed to update Accumulated heating energy unit',
         project_single_project_setting_select_mbus_register_accumulated_heating_energy_title:
           'Select Accumulated heating energy',
         project_single_project_setting_select_mbus_register_update_button:
           'Update',
         custom_popover_100_short_description: 'Heizstatus',
         custom_popover_101_short_description: 'Warmwasserstatus',
         custom_popover_102_short_description: 'Warmhaltestatus',
         custom_popover_103_short_description: 'Anti-Legionellen-Status',
         custom_popover_104_short_description: 'Kühlstatus',
         custom_popover_1802_short_description: 'Elektrisches Element',
         custom_popover_105_short_description: 'Alarm',
         custom_popover_106_short_description: 'Softwareversion',
         custom_popover_107_short_description: 'Warmwasserregelventil (M1)',
         custom_popover_108_short_description: 'Heizen/Kühlen Regelventil (M2)',
         custom_popover_109_short_description: 'Umwälzpumpe',
         custom_popover_110_short_description: '6-Wege-Kugelhahn',
         custom_popover_113_short_description: 'Raumthermostat',
         custom_popover_114_short_description: 'OpenTherm-Anzeige',
         custom_popover_115_short_description: 'Devicestyp',
         custom_popover_200_short_description: 'Volumenstrom Warmwasser',
         custom_popover_201_short_description: 'T-Warmwasser',
         custom_popover_202_short_description: 'T-Primar Vorlauf',
         custom_popover_203_short_description: 'T-Primar Rücklauf',
         custom_popover_205_short_description: 'T-Sekundär Vorlauf',
         custom_popover_206_short_description: 'T-Sekundär Rücklauf',
         custom_popover_204_short_description:
           'Berechnete Vorlauftemperatur (Sollwert)',
         custom_popover_207_short_description: 'Raumtemperatur (OpenTherm)',
         custom_popover_208_short_description: 'Raum-Sollwert (OpenTherm)',
         custom_popover_210_short_description:
           'Wasserdruck in der Inneninstallation (Sekundär)',
         custom_popover_800_short_description:
           'Gesamtbetriebsdauer Übergabestation',
         custom_popover_801_short_description:
           'Gesamtbetriebsdauer Wärmebedarf',
         custom_popover_802_short_description:
           'Gesamtbetriebsdauer Kältebedarf',
         custom_popover_803_short_description:
           'Gesamtbetriebsdauer Warmwasserbedarf',
         custom_popover_804_short_description: 'Anzahl der Zapfstellen',
         custom_popover_805_short_description:
           'Gesamtvolumen Warmwasser (Hinweis: dies ist ein Richtwert)',
         custom_popover_806_short_description: 'Stromunterbrechungen',
         create_role_stepper_information_title: 'Information',
         create_role_stepper_client_title: 'Client',
         create_role_stepper_service_client_title: 'Service Client',
         create_role_stepper_project_title: 'Project',
         create_role_stepper_device_management_title: 'Device Management',
         create_role_stepper_commissioning_title: 'Commissioning',
         create_role_stepper_reporting_title: 'Reporting',
         create_role_stepper_general_title: 'General',
         roles_form_role_name_field: 'Name',
         roles_name_required_form_error: 'Name is erforderlich',
         roles_form_description_field: 'Beschreibung',
         create_role_stepper_back_button: 'Back',
         create_role_stepper_next_button: 'Next',
         create_role_stepper_finish_button: 'Finish',
         create_role_stepper_yes_label: 'Yes',
         create_role_stepper_no_label: 'No',
         create_role_stepper_step_2_client_view_all_users: 'View all users?',
         create_role_stepper_step_2_client_create_new_users:
           'Create new users?',
         create_role_stepper_step_2_client_edit_new_users:
           'Modify existing users like the role?',
         create_role_stepper_step_3_service_client_create_new:
           "Create a new 'service client'?",
         create_role_stepper_step_3_service_client_view_users:
           "View existing 'service client' users?",
         create_role_stepper_step_3_service_client_create_users:
           "Create new 'service client' users?",
         create_role_stepper_step_3_service_client_modify_users:
           "Modify 'service client' users like the role?",
         create_role_stepper_step_4_project_view: 'View projects?',
         create_role_stepper_step_4_project_create_new: 'Create new projects?',
         create_role_stepper_step_4_project_export_mbus_data:
           'Export energy meter data from projects?',
         create_role_stepper_step_5_device_view_single_page: 'Open a device?',
         create_role_stepper_step_5_device_view_single_page_setting_registers:
           'View settings?',
         create_role_stepper_step_5_device_edit_single_page_setting_registers:
           'Modify settings?',
         create_role_stepper_step_5_device_edit_single_page_manual_control:
           'Use manual control?',
         create_role_stepper_step_5_device_single_page_view_history:
           'View history?',
         create_role_stepper_step_5_device_single_page_view_meter_data:
           'View meter data?',
         create_role_stepper_step_5_device_single_page_use_diagnostics_module:
           'Use the diagnostics module?',
         create_role_stepper_step_5_device_single_page_information_and_delivery_set_data:
           'View information such as device info, address details, and delivery set data?',
         create_role_stepper_step_6_commissioning_module_put_devices_into_operation:
           'Commissioning module to put devices into operation?',
         create_role_stepper_step_6_commissioning_module_swapping:
           'Exchange devices (transfer data from old device to new device)?',
         create_role_stepper_step_7_reporting_audit:
           'The logbook that contains all messages, errors, and actions on devices?',
         create_role_stepper_step_7_reporting_swapped_devices:
           'Logbook of exchanged devices?',
         create_role_stepper_step_7_reporting_error_devices:
           'Logbook of devices with faults?',
         create_role_stepper_step_7_reporting_bulk_registers_update:
           'Results of group changes?',
         create_role_stepper_step_8_view_dashboard:
           'Dashboard all access and sidebar device counters',
         create_role_stepper_step_8_general_create_role: 'Creating roles?',
         create_role_stepper_question_required_error:
           'One option is required to proceed',
         roles_form_role_name_en_field: 'Name (EN)',
         roles_form_role_name_nl_field: 'Name (NL)',
         roles_name_en_required_form_error: 'Name (EN) ist erforderlich',
         roles_name_nl_required_form_error: 'Name (NL) ist erforderlich',
         roles_form_description_en_field: 'Beschreibung (EN)',
         roles_form_description_nl_field: 'Beschreibung (NL)',
         project_single_project_setting_device_v2_settings_title:
           'Device V2 Settings',
         project_single_device_settings_secondary_device_type_field:
           'Secondary Device Type',
         project_single_project_setting_base_topic_field: 'Base Topic',
         project_single_device_settings_baud_rate_uart_field: 'Baud Rate UART',
         project_single_device_settings_select_baud_rate_uart_field:
           'Select Baud Rate UART',
         project_single_device_settings_modbus_error_register_address_field:
           'Modbus error register address',
         project_single_device_settings_modbus_error_register_value_field:
           'Modbus expected error register value',
         project_single_project_setting_device_v2_send_button: 'Send',
         project_single_project_setting_base_topic_success_message:
           'Base Topic is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         project_single_project_setting_secondary_device_type_success_message:
           'Secondary Device Type is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         project_single_project_setting_reboot_message:
           'Device Reboot Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         project_single_project_setting_baut_rate_uart_success_message:
           'Device Baut Rate UART Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         project_single_project_setting_modbus_expected_error_register_value_success_message:
           'Device Modbus Expected Error Register Value Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         project_single_project_setting_modbus_error_register_address_success_message:
           'Device Reboot Message is successfully forwarded to ##SENT_MESSAGE_TO_DEVICES_COUNTER## devices of Project',
         docs_third_tab_video_instruction: 'Video Instruction',
         docs_third_tab_video_instruction_title: 'Video Instruction',
         custom_popover_106_long_description: 'Softwareversion',
         EXPORT_MBUS_METER_SERIAL_NUMBER: 'Seriennummer meter',
         navbar_languages_french: 'French',
         diagnostic_formulas_form_diagnostic_formulas_name_fr_field:
           'Name (FR)',
         diagnostic_formulas_name_fr_required_form_error:
           'Name (FR) is required',
         diagnostic_formulas_form_description_fr_field: 'Description (FR)',
         diagnostic_flows_form_diagnostic_flows_name_fr_field: 'Name (FR)',
         diagnostic_flows_name_fr_required_form_error: 'Name (FR) is required',
         diagnostic_flows_form_description_fr_field: 'Description (FR)',
         diagnostic_categories_form_diagnostic_categories_name_fr_field:
           'Name (FR)',
         diagnostic_categories_name_fr_required_form_error:
           'Name (FR) is required',
         diagnostic_categories_form_description_fr_field: 'Description (FR)',
         modules_form_module_name_fr_field: 'Name (FR)',
         modules_name_fr_required_form_error: 'Name (FR) is required',
         modules_form_description_fr_field: 'Description (FR)',
         roles_form_role_name_fr_field: 'Name (FR)',
         roles_name_fr_required_form_error: 'Name (FR) is required',
         roles_form_description_fr_field: 'Description (FR)',
         permissions_form_permission_name_fr_field: 'Name (FR)',
         permissions_form_description_fr_field: 'Description (FR)',
         firmwares_form_firmware_name_fr_field: 'Name (FR)',
         firmwares_name_fr_required_form_error: 'Name (FR) is required',
         notifications_form_title_fr_field: 'Name (FR)',
         notifications_form_description_fr_field: 'Description (FR)',
         notifications_title_dk_required_form_error: 'Name (DK) is required',
         notifications_title_fr_required_form_error: 'Name (FR) is required',
         notifications_title_de_required_form_error: 'Name (DE) is required',
         notifications_description_dk_required_form_error:
           'Description (DK) is required',
         notifications_description_de_required_form_error:
           'Description (DE) is required',
         notifications_description_fr_required_form_error:
           'Description (FR) is required',
         client_single_error_devices_bread_crumb: 'Error Devices',
         service_client_single_error_devices_bread_crumb: 'Error Devices',
         devices_list_diagnostics_condition_modal_fetch_and_filter_button:
           'Fetch & Filter',
         diagnostic_register_drawer_selected_devices_title: 'Selected Devices',
         diagnostic_register_drawer_fetch_and_filter_message_1:
           'Fetching devices data',
         diagnostic_register_drawer_fetch_and_filter_message_2:
           'Database refresh',
         diagnostic_register_drawer_fetch_and_filter_message_3:
           'Filtering devices',
         devices_bulk_selected_devices_title: 'Devices Selected',
         custom_popover_100_long_description: 'Heizstatus',
         custom_popover_101_long_description: 'Warmwasserstatus',
         custom_popover_102_long_description: 'Warmhaltestatus',
         custom_popover_103_long_description: 'Anti-Legionellen-Status',
         custom_popover_104_long_description: 'Kühlstatus',
         custom_popover_1802_long_description: 'Elektrisches Element',
         custom_popover_107_long_description: 'Warmwasserregelventil (M1)',
         custom_popover_108_long_description: 'Heizen/Kühlen Regelventil (M2)',
         custom_popover_109_long_description: 'Umwälzpumpe',
         custom_popover_110_long_description: '6-Wege-Kugelhahn',
         custom_popover_113_long_description: 'Raumthermostat',
         custom_popover_114_long_description: 'OpenTherm-Anzeige',
         custom_popover_115_long_description: 'Devicestyp',
         custom_popover_200_long_description: 'Volumenstrom Warmwasser',
         custom_popover_201_long_description: 'T-Warmwasser',
         custom_popover_202_long_description: 'T-Primar Vorlauf',
         custom_popover_203_long_description: 'T-Primar Rücklauf',
         custom_popover_205_long_description: 'T-Sekundär Vorlauf',
         custom_popover_206_long_description: 'T-Sekundär Rücklauf',
         custom_popover_204_long_description:
           'Berechnete Vorlauftemperatur (Sollwert)',
         custom_popover_207_long_description: 'Raumtemperatur (OpenTherm)',
         custom_popover_208_long_description: 'Raum-Sollwert (OpenTherm)',
         custom_popover_210_long_description:
           'Wasserdruck in der Inneninstallation (Sekundär)',
         custom_popover_800_long_description:
           'Gesamtbetriebsdauer Übergabestation',
         custom_popover_801_long_description: 'Gesamtbetriebsdauer Wärmebedarf',
         custom_popover_802_long_description: 'Gesamtbetriebsdauer Kältebedarf',
         custom_popover_803_long_description:
           'Gesamtbetriebsdauer Warmwasserbedarf',
         custom_popover_804_long_description: 'Anzahl der Zapfstellen',
         custom_popover_805_long_description:
           'Gesamtvolumen Warmwasser (Hinweis: dies ist ein Richtwert)',
         custom_popover_806_long_description: 'Stromunterbrechungen',
         project_single_project_setting_mbus_hourly_logs_title:
           'Mbus Hourly Logs',
         project_single_project_setting_mbus_hourly_logs_field:
           'Fetch Mbus Hourly',
         project_single_project_setting_mbus_hourly_logs_update_button:
           'Update',
         project_single_project_setting_mbus_hourly_logs_success_set_message:
           'Mbus Hourly logs are successfully set for the project',
         project_single_project_setting_mbus_hourly_logs_error_message:
           'Failed to update Mbus Hourly logs for the project',
         sidebar_mbus_reporting: 'Mbus Reporting',
         mbus_reporting_table_device: 'Device',
         mbus_reporting_table_project: 'Project',
         mbus_reporting_table_created_at: 'Created At',
         mbus_reporting_table_viewed_by: 'Viewed By',
         mbus_reporting_table_viewed_at: 'Viewed At',
         mbus_reporting_table_firmware: 'Firmware',
         mbus_reporting_table_no_record_found: 'No Records Found',
         mbus_reporting_table_all_filter: 'All',
         mbus_reporting_table_read_filter: 'Read',
         mbus_reporting_table_unread_filter: 'Unread',
         mbus_reporting_table_row_per_page: 'Rows per page',
         mbus_reporting_table_title: 'Mbus Reporting',
         mbus_reporting_anomaly_table_title: 'Mbus Anomalies',
         mbus_reporting_anomaly_table_difference: 'Difference',
         mbus_reporting_anomaly_table_detection: 'Detection',
         mbus_reporting_anomaly_table_mbus_meter_type: 'Meter',
         mbus_reporting_anomaly_table_mbus_serial_number: 'Mbus Serial Number',
         mbus_reporting_anomaly_table_mbus_old_value: 'Old Value',
         mbus_reporting_anomaly_table_mbus_register: 'Register',
         mbus_reporting_anomaly_table_mbus_reason: 'Reason',
         mbus_reporting_anomaly_table_mbus_fail_reason: 'Greater than',
         mbus_reporting_anomaly_table_mbus_suspicious_reason: 'Lies in between',
         mbus_reporting_anomaly_table_fail: 'Fail',
         mbus_reporting_anomaly_table_suspicious: 'Suspicious',
         mbus_reporting_table_bulk_view_reports_success_message:
           'Mbus Reporting Messages are successfully viewed',
         mbus_reporting_table_bulk_view_reports_error_message:
           'Please select Mbus Reporting records to proceed',
         mbus_reporting_table_bulk_view_button: 'Bulk View',
         mbus_reporting_table_view_button: 'View',
         mbus_reporting_table_filters_project_field: 'Project',
         mbus_reporting_table_filters_device_name_field: 'Device',
         mbus_reporting_table_filters_start_date: 'Start Date',
         mbus_reporting_table_filters_end_date: 'End Date',
         mbus_reporting_table_filters_firmware: 'Firmware',
         mbus_reporting_table_filters_mbus_serial_number: 'Mbus Serial Number',
         mbus_reporting_table_filters_viewed_by: 'Viewed By',
         business_situation_measured_values_209: 'Outdoor Temperature',
         custom_popover_209_short_description: 'Outdoor Temperature',
         custom_popover_209_long_description: 'Outdoor Temperature',
         appliance_types_set_registers_table_dashboard: 'Dashboard',
         appliance_types_set_registers_table_dashboard_error_message:
           'Minimum 0 and Maximum 6 Dashboard Registers are allowed for device dashboard',
         sidebar_missing_hourly_reporting: 'Mbus Missing Data Report',
         missing_hourly_reporting_table_title:
           'Mbus Hourly Missing Data Reporting',
         mbus_hourly_missing_reporting_table_filters_project_field: 'Project',
         mbus_hourly_missing_reporting_table_filters_device_name_field:
           'Device',
         mbus_hourly_missing_reporting_table_filters_firmware_field: 'Firmware',
         mbus_hourly_missing_reporting_table_last_seen: 'Mbus Message At',
         mbus_hourly_missing_reporting_table_created_at: 'Created At',
         mbus_hourly_missing_reporting_table_device: 'Device',
         mbus_hourly_missing_reporting_table_project: 'Project',
         mbus_hourly_missing_reporting_table_firmware: 'Firmware',
         mbus_hourly_missing_reporting_table_no_record_found:
           'No Records Found',
         mbus_hourly_missing_reporting_table_row_per_page: 'Rows per page',
         device_monitoring_device_frequency_history_counters_title:
           'Device Anomalies Frequency',
         device_monitoring_device_frequency_history_counters_error:
           'No data found',
         device_monitoring_device_frequency_history_counters_no_graph_data_error:
           'The piechart is unavailable',
         device_monitoring_device_frequency_history_counters_loading_data:
           'Loading ...',
         device_monitoring_project_frequency_history_counters_title:
           'Project Anomalies Frequency',
         device_monitoring_project_frequency_history_counters_error:
           'No data found',
         device_monitoring_project_frequency_history_counters_no_graph_data_error:
           'The piechart is unavailable',
         device_monitoring_project_frequency_history_counters_loading_data:
           'Loading ...',
         device_monitoring_firmware_anomalies_history_title:
           'Firmware Anomalies Frequency',
         device_monitoring_device_firmware_anomalies_history_graph_loading_data:
           'The barchart is unavailable',
         device_monitoring_device_firmware_anomalies_history_no_graph_data_error:
           'Loading ...',
         devices_device_id: 'Device Id',
         device_monitoring_device_frequency_details_table_title:
           'Device Anomalies Frequency',
         device_monitoring_device_frequency_details_table_firmware: 'Firmware',
         device_monitoring_device_frequency_details_table_device: 'Device',
         device_monitoring_device_frequency_details_table_project: 'Project',
         device_monitoring_device_frequency_details_table_saved_at: 'Date',
         device_monitoring_device_frequency_details_table_startup_messages_count:
           'Start up messages count',
         device_monitoring_project_frequency_details_table_title:
           'Project Anomalies Frequency',
         device_monitoring_project_frequency_details_table_project: 'Project',
         device_monitoring_project_frequency_details_table_devices_count:
           'Devices Count',
         device_monitoring_project_frequency_details_table_startup_messages_count:
           'Start up messages count',
         device_monitoring_firmware_frequency_details_table_title:
           'Firmware Anomalies Frequency',
         device_monitoring_firmware_frequency_details_table_firmware:
           'Firmware',
         device_monitoring_firmware_frequency_details_table_startup_messages_count:
           'Start up messages count',
         client_single_users_list_reset_and_send_email_button: 'Reset Password',
         user_reset_password_form_success:
           'User password is updated successfully',
         users_reset_password_modal_title: 'Reset Password',
         users_reset_password_modal_subtitle:
           'Do you want to reset password for this user and send email?',
         users_reset_password_modal_cancel_button: 'Close',
         users_reset_password_modal_reset_password_button:
           'Reset and Send Email',
         reset_password_subject: 'Fortes Data Management - Reset Password',
         clients_form_is_2fa_enabled_field:
           'Enable Two-Factor Authentication (2FA)',
         service_clients_form_is_2fa_enabled_field:
           'Enable Two-Factor Authentication (2FA)',
         two_factor_authentication_qr_verified_title:
           'Two-Factor Authentication (2FA)',
         two_factor_authentication_qr_unverified_title:
           'Setup Two-Factor Authentication (2FA)',
         two_factor_authentication_permission_error: '',
         two_factor_authentication_description_step_1:
           'Install one of the following applications on your mobile:',
         two_factor_authentication_description_step_2:
           'Open the application and scan the QR Code:',
         two_factor_authentication_description_step_2_rescan:
           'Regenerate QR Code',
         two_factor_authentication_description_step_3:
           'Enter the one-time code provided by the application and click Submit to finish the setup.',
         two_factor_authentication_form_otp_field: 'OTP Code',
         two_factor_authentication_form_otp_characters_limit_error:
           'OTP must be 6 characters',
         two_factor_authentication_form_otp_required_error: 'OTP is required',
         two_factor_authentication_form_incorrect_otp_error:
           'Invalid OTP, please retry again with a new OTP',
         two_factor_authentication_form_success:
           'OTP is successfully authenticated',
         two_factor_authentication_submit_button: 'Submit',
         client_single_users_list_enable_2fa_qr_code_button:
           'Enable Two-Factor Authentication (2FA) QR Code',
         service_client_single_users_list_enable_2fa_qr_code_button:
           'Enable Two-Factor Authentication (2FA) QR Code',
         users_enable_2fa_qr_code_modal_title:
           'Enable Two-Factor Authentication (2FA) QR Code',
         users_enable_2fa_qr_code_modal_subtitle:
           'Are you sure you want to enable Two-Factor Authentication (2FA) QR Code for this user?',
         users_enable_2fa_qr_code_modal_cancel_button: 'Cancel',
         users_enable_2fa_qr_code_modal_enable_2fa_qr_code_button:
           'Enable Two-Factor Authentication (2FA) QR Code',
         client_single_users_list_enable_2fa_qr_code_success_message:
           'Two-Factor Authentication (2FA) QR Code is successfully enabled',
         client_single_2fa_label: 'Two-Factor Authentication (2FA)',
         enabled_2fa_label: 'Enabled',
         disabled_2fa_label: 'Disabled',
         service_client_single_2fa_label: 'Two-Factor Authentication (2FA)',
         client_single_users_table_last_access: 'Last Seen',
         service_client_single_users_table_last_access: 'Last Seen',
         projects_form_header: 'Project Information',
         projects_commissioning_form_header: 'Project Commissioning',
         projects_commissioning_form_export_button: 'Export Devices',
         projects_commissioning_form_export_file_name:
           'Project Commissioning Export',
         projects_commissioning_form_import_button: 'Import Devices',
         projects_commissioning_form_import_message:
           "<strong>Steps to Import Devices</strong> <ol> <li>Download the Project Commissioning File Template from green Export Devices Button.</li> <li>Update the file and don't change the header column names, in case they're changed. The file import will fail.</li> <li>Import the file and changes will be updated.</li> </ol>",
         projects_commissioning_form_import_success_message:
           'Devices data is successfully commissioned',
         commissoning_device_stepper_step_1_scan_qr_serial_number_title:
           'Scan Serial Number Mercurius ',
         commissoning_device_stepper_step_2_validate_project_setting_title:
           'Validate Modbus and M-Bus',
         commissoning_device_stepper_step_3_update_address_information_title:
           'Update Address',
         commissoning_device_stepper_step_4_hiu_commissioning_title:
           'HIU Commissioning',
         commissoning_device_stepper_step_5_preview_and_submit_title:
           'Preview and Submit',
         commissoning_form_dont_have_serial_number_checkbox:
           "Don't have Serial QR Code?",
         commissoning_form_back_button: 'Previous',
         commissoning_form_next_button: 'Next',
         commissoning_form_finish_button: 'Finish',
         commissoning_form_device_serial_is_missing_error:
           'Serial Number is required',
         commissoning_form_modbus_mbus_validation_error:
           'Validation failed, do you want to proceed anyway?',
         commissoning_form_stepper_step_2_validation_project_setting_title:
           'Project Settings',
         commissoning_form_stepper_step_2_validation_current_setting_title:
           'Device Settings',
         commissoning_form_stepper_step_2_validation_validate_to_view:
           'Validate to view details',
         commissoning_form_stepper_step_2_validation_modbus_title: 'Modbus',
         commissoning_form_stepper_step_2_validation_mbus_title: 'M-Bus',
         commissoning_form_stepper_step_2_validation_modbus_connected_title:
           'Connected',
         commissoning_form_stepper_step_2_validation_modbus_not_connected_title:
           'Not Connected',
         commissoning_form_stepper_step_2_validation_mbus_meters_title:
           'meter(s)',
         project_single_project_setting_modbus_and_mbus_validation_form_title:
           'Modbus and Mbus Setting',
         commissoning_form_stepper_step_2_validation_is_modbus_connected:
           'Is Modbus Connected',
         commissoning_form_stepper_step_2_validation_mbus_meters_select_field:
           'M-Bus Meters',
         commissoning_form_stepper_step_2_validation_mbus_meters_select_option_field:
           'Select M-Bus Meters',
         commissoning_form_stepper_step_2_validation_mbus_meters_validate_button:
           'Validate',
         commissoning_form_upload_file_type_error:
           'Only XLSX files are allowed.',
         commissoning_form_upload_file_invalid_column_names_error:
           "Invalid column ##COLUMNS##, please don't change the column names",
         commissoning_form_upload_file_size_error:
           'File size should be less than 5 MB.',
         commissoning_form_uploading_text: 'Uploading...',
         project_single_project_setting_mbus_reporting_form_title:
           'Mbus Reporting Setting',
         project_single_project_setting_mbus_hourly_missing_data_reporting_value_field:
           'Mbus Hourly Missing Data Reporting',
         project_single_project_setting_mbus_anomaly_reporting_value_field:
           'Mbus Anomaly Reporting',
         project_single_project_setting_mbus_reporting_form_button: 'Update',
         project_single_project_setting_mbus_reporting_success_message:
           'Mbus Reporting data is successfully set for the project',
         project_single_project_setting_mbus_reporting_error_message:
           'Failed to update Mbus Reporting data for the project',
         commissoning_form_upload_file_incorrect_serial_number_error:
           'Incorrect Serial Number(s) found for the project commissioning',
         commissoning_form_upload_file_incorrect_devices_data_error:
           'Incorrect Device(s) data found for the project commissioning',
         commissoning_form_upload_file_empty_sheet_error:
           'Project Commissioning file is empty',
         commissoning_form_upload_success_message:
           'Project Commissioning is successfully completed',
         commissioning_validation_failed_subject:
           'Fortes Energy - Device Commissioning Wizard Validation',
         commissoning_form_stepper_step_2_validation_mbus_meters_loading_title:
           'Mbus meters check takes upto 30 - 60 seconds',
         commissoning_form_stepper_step_2_validation_mbus_meters_retries_label:
           'Retries',
         commissoning_form_stepper_step_2_validation_mbus_meters_retries_button:
           'Retry',
         mbus_reporting_table_bulk_snooze_button: 'Bulk Snooze',
         mbus_reporting_table_bulk_snooze_devices_success_message:
           'Mbus Reporting Devices are successfully snoozed',
         mbus_reporting_table_bulk_snooze_modal_title: 'Are you sure?',
         mbus_reporting_table_bulk_snooze_modal_sub_title:
           'You want to snooze Mbus Reporting for the selected devices?',
         mbus_reporting_table_bulk_snooze_modal_cancel_button: 'Cancel',
         mbus_reporting_table_bulk_snooze_modal_snooze_button: 'Snooze',
         mbus_reporting_table_bulk_snooze_modal_snooze_duration:
           'Snooze Duration',
         mbus_reporting_table_bulk_snooze_modal_select_snooze_duration:
           'Select Snooze Duration',
         mbus_reporting_table_bulk_snooze_modal_snooze_duration_day: 'Day',
         mbus_reporting_table_bulk_snooze_modal_snooze_duration_week: 'Weeks',
         mbus_reporting_table_bulk_snooze_modal_snooze_duration_month: 'Months',
         mbus_reporting_table_bulk_snooze_modal_snooze_duration_year: 'Years',
         sidebar_mbus_reporting_snoozed_devices:
           'Mbus Reporting - Snoozed Devices',
         mbus_reporting_snoozed_devices_table_title: 'Snoozed Devices',
         mbus_reporting_table_bulk_un_snooze_devices_success_message:
           'Mbus Reporting Devices are successfully UnSnoozed',
         mbus_reporting_table_bulk_un_snooze_modal_title: 'Are you sure?',
         mbus_reporting_table_bulk_un_snooze_modal_sub_title:
           'You want to UnSnooze Mbus Reporting for the selected devices?',
         mbus_reporting_table_bulk_un_snooze_modal_cancel_button: 'Cancel',
         mbus_reporting_table_bulk_un_snooze_modal_snooze_button: 'UnSnooze',
         mbus_reporting_table_bulk_un_snooze_button: 'Bulk UnSnooze',
         mbus_reporting_table_snooze_request_at: 'Request At',
         mbus_reporting_table_snooze_end_at: 'End At',
         mbus_reporting_snoozed_devices_table_all_filter: 'All',
         mbus_reporting_snoozed_devices_table_snoozed_filter: 'Snoozed',
         mbus_reporting_snoozed_devices_table_unsnoozed_filter: 'UnSnoozed',
         devices_list_diagnostics_condition_modal_single_meter_label: 'Meter',
         devices_list_diagnostics_condition_modal_multiple_meters_label:
           'Meters',
         devices_list_diagnostics_condition_modal_mbus_meters_dropdown_label:
           'Connected Mbus Meters',
         devices_list_diagnostics_condition_modal_are_mbus_connected_value_connected_label:
           'Connected',
         devices_list_diagnostics_condition_modal_are_mbus_connected_value_not_connected_label:
           'Not Connected',
         devices_list_diagnostics_condition_modal_mbus_meters_dropdown_select_record_label:
           'Select Connected Mbus Meters',
         devices_list_diagnostics_condition_modal_are_mbus_connected_dropdown_label:
           'Mbus Meters Connection',
         devices_list_diagnostics_condition_modal_are_mbus_connected_select_field_label:
           'Select connection status',
         devices_search_mbus_registers_multiple_devices_success:
           'The message has been successfully sent to the selected device(s).',
         devices_testing_button: 'Device Testing',
         devices_testing_form_header: 'Device Testing',
         devices_testing_device_serial_field: 'Device Serial',
         devices_testing_modbus_registers_field: 'Modbus Registers',
         devices_testing_modbus_registers_button_field: 'Send Modbus Message',
         devices_testing_single_mbus_register_field: 'Single MBus Register',
         devices_testing_single_mbus_register_button_field: 'Send Message',
         devices_testing_send_fetch_mbus_message: 'Send Fetch MBus Message',
         devices_testing_send_scan_mbus_message: 'Send Scan MBus Message',
         project_single_project_setting_mbus_hourly_logs_success_unset_message:
           'Mbus Hourly logs are successfully unset for the project',
         mbus_reporting_table_export_button: 'Export',
         mbus_reporting_table_export_file: 'Mbus Reporting',
         mbus_hourly_missing_reporting_export_button: 'Export',
         mbus_hourly_missing_reporting_export_file:
           'Mbus Hourly Missing Data Reporting',
         snoozed_devices_export_button: 'Export',
         snoozed_devices_export_file: 'Snoozed Devices',
         device_single_documentation_instructie_pomp_button: 'Instructie Pomp',
         sidebar_inconsistent_mbus_devices_reporting:
           'Inconsistent Mbus Devices',
         inconsistent_mbus_devices_reporting_table_header:
           'Inconsistent Mbus Devices',
         inconsistent_mbus_devices_reporting_bulk_export_button: 'Export',
         inconsistent_mbus_devices_reporting_filter_reset_button: 'Reset',
         inconsistent_mbus_devices_reporting_table_filter_search: 'Search',
         inconsistent_mbus_devices_reporting_table_created_at: 'Created At',
         inconsistent_mbus_devices_reporting_table_total_devices:
           'Total Devices',
         inconsistent_mbus_devices_reporting_table_total_projects:
           'Total Projects',
         inconsistent_mbus_devices_reporting_table_no_record_found:
           'No Record Found',
         inconsistent_mbus_devices_reporting_table_row_per_page:
           'Rows per page',
         devices_mbus_meter_count: 'Mbus Meters',
         devices_bulk_modbus_download_form_data_type: 'Data Type',
         devices_bulk_modbus_download_form_export_type: 'Export Type',
         devices_bulk_modbus_download_form_data_type_daily: 'Daily',
         devices_bulk_modbus_download_form_data_type_hourly: 'Hourly',
         devices_bulk_modbus_download_form_export_type_combined: 'Combined',
         devices_bulk_modbus_download_form_export_type_separated: 'Separated',
         devices_bulk_export_modbus_historic_data_button:
           'Download Modbus Data',
         devices_bulk_export_modbus_historic_data_file_name:
           'Modbus Historic Data',
         devices_bulk_modbus_download_title: 'Download Modbus Historical Data',
         devices_bulk_modbus_download_form_date_range: 'Date Range:',
         devices_bulk_modbus_download_form_submit_button:
           'Download Modbus Data',
         devices_bulk_modbus_download_form_cancel_button: 'Cancel',
         devices_bulk_modbus_download_form_success:
           'Modbus Historical Data is emailed to ##EMAIL## successfully!',
         devices_bulk_modbus_download_form_api_error: 'Internal Server Error',
         devices_bulk_modbus_download_no_devices_error: '',
         devices_bulk_modbus_download_form_no_devices_error:
           'Please select devices to proceed.',
         devices_bulk_modbus_download_modal_title: 'Are you sure?',
         devices_bulk_modbus_download_modal_subtitle:
           'You want to run a bulk download historical Modbus data?',
         devices_bulk_modbus_download_modal_cancel_button: 'Cancel',
         devices_bulk_modbus_download_modal_submit_button: 'Confirm',
         projects_bulk_export_modbus_historic_data_file_name:
           'Modbus Historic Data',
         projects_bulk_modbus_download_title: 'Download Modbus Historical Data',
         projects_bulk_bulk_modbus_download_form_date_range: 'Date Range:',
         projects_bulk_bulk_modbus_download_form_data_export_type:
           'Date Export Type',
         projects_bulk_bulk_modbus_download_form_data_export_type_combined:
           'Combined',
         projects_bulk_bulk_modbus_download_form_data_export_type_daily:
           'Daily',
         projects_bulk_bulk_modbus_download_form_data_export_type_normal:
           'Normal',
         projects_bulk_bulk_modbus_download_form_submit_button:
           'Download Modbus Data',
         projects_bulk_bulk_modbus_download_form_cancel_button: 'Cancel',
         projects_bulk_modbus_download_form_success:
           'Modbus Historical Data is emailed to ##EMAIL## successfully!',
         projects_bulk_modbus_download_form_api_error: 'Internal Server Error',
         projects_bulk_modbus_download_no_projects_error: '',
         projects_bulk_modbus_download_form_no_projects_error:
           'Please select projects to proceed.',
         projects_bulk_bulk_modbus_download_modal_title: 'Are you sure?',
         projects_bulk_modbus_download_modal_subtitle:
           'You want to run a bulk download historical Modbus data?',
         projects_bulk_modbus_download_modal_cancel_button: 'Cancel',
         projects_bulk_modbus_download_modal_submit_button: 'Confirm',
         projects_bulk_modbus_selected_projects: 'Projects Selected',
         device_single_status_modbus_date_export_label: 'Date',
         device_single_download_modbus_data: 'Modbus Export',
         device_monitoring_maxitel_status_heading: 'Network Status',
         device_monitoring_maxitel_status_online: 'Online',
         device_monitoring_maxitel_status_offline: 'Offline',
         device_monitoring_maxitel_operational_status: 'Operational',
         device_monitoring_maxitel_non_operational_status: 'Not Operational',
         device_monitoring_maxitel_mobile_on_cloud_network_green:
           'MaxiTEL MobileOnCloud - Network Purple',
         device_monitoring_maxitel_mobile_on_cloud_network_purple:
           'MaxiTEL MobileOnCloud - Network Green',
         device_monitoring_maxitel_mobile_data_network_purple:
           'Mobile data - Network Purple',
         device_monitoring_maxitel_mobile_m2m_blend: 'M2M Blend',
         device_monitoring_maxitel_other_api: 'API',
         device_monitoring_maxitel_other_portal: 'Portal',
         device_monitoring_maxitel_updated_at: 'Updated',
         admins_last_seen: 'Last Seen',
         device_monitoring_send_grid_email_reached: 'Email Limit Reached',
         sidebar_email_reporting: 'Email Report',
         email_reporting_title: 'Email Reporting',
         email_reporting_tab_all: 'All',
         email_reporting_tab_passed: 'Passed',
         email_reporting_tab_failed: 'Failed',
         email_reporting_table_email_forwarded_at: 'Forwarded Date',
         email_reporting_table_to: 'To',
         email_reporting_table_recipient_name: 'Recipient Name',
         email_reporting_table_subject: 'Subject',
         email_reporting_table_status: 'Status',
         email_reporting_table_no_record_found: 'No Records Found',
         email_reporting_single_output_tab: 'Email Content',
         email_reporting_single_second_bread_crumb: 'Email Detail',
         email_reporting_single_email_logs_title: 'Email Logs',
         email_reporting_table_resend_failed_email_success_message:
           'Emails are forwarded again.',
         email_reporting_table_resend_failed_email_error_message:
           'Please select atleast records to resend email',
         email_reporting_table_resend_failed_email_button: 'Resend Email',
         email_reporting_table_filter_search: 'Search',
         email_reporting_table_row_per_page: 'Rows per page',
         settings_menu_modbus_global_configuration_title:
           'Modbus Global Configuration',
         settings_menu_modbus_global_configuration_title_form_header:
           'Modbus Global Configuration',
         settings_menu_modbus_global_configuration_TR03_tab: 'TR03 Settings',
         settings_menu_modbus_global_configuration_TR04_tab: 'TR04 Settings',
         settings_menu_modbus_global_configuration_form_success:
           'Modbus Global Configurations are saved successully',
         settings_menu_modbus_global_configuration_form_error:
           'Failed to update Modbus Global Configurations',
         settings_menu_modbus_global_configuration_form_save_button:
           'Save Modbus Settings',
         device_single_unprovisioned_detailed_message:
           'Device is in "Unprovisioned" mode',
         commissoning_form_stepper_step_2_validation_modbus_loading_title:
           'Modbus check takes upto 10 - 60 seconds',
         settings_menu_modbus_global_configuration_title_form_header_add_new:
           'Add New',
         settings_menu_modbus_global_configuration_title_add_form_header:
           'Add Article Number',
         settings_menu_modbus_global_configuration_article_number_field:
           'Article Number',
         settings_menu_modbus_global_configuration_article_number_required_form_error:
           'Article Number is required',
         settings_menu_modbus_global_configuration_article_number_unique_form_error:
           'Article Number already exists. Please provide a unique Article Number',
         settings_menu_modbus_global_configuration_type_field: 'Type',
         settings_menu_modbus_global_configuration_type_required_form_error:
           'Type is required',
         settings_menu_modbus_global_configuration_type_default_option:
           'Select a Type',
         settings_menu_modbus_global_configuration_add_success_message:
           'Article Number is successfully added',
         settings_menu_modbus_global_configuration_cancel_button: 'Cancel',
         settings_menu_modbus_global_configuration_save_button: 'Save',
         sidebar_terms_and_conditions_title: 'Terms and Conditions',
         sidebar_support_text: 'Questions or comments? Email',
         sidebar_support_text_copied: 'Email Copied!',
         device_single_device_settings_fetch_latest_settings_button_v2:
           'Data Collect',
       };
